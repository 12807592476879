import { useEffect, useState } from "react";
import {
  addFavoriteArtist,
  getArtist,
  getFavoriteArtists,
  removeFavoriteArtist,
} from "../../../AxiosFunctions/Axiosfunctionality";
import crossSvg from "../../../assets/svgs/crossButton.svg"; // Import your SVG file
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import loading from "../../../assets/loading.gif";
import { ArtistDataAPI } from "../../../redux/artistDataSlice";
import { ArtistImageSliceData } from "../../../redux/artistImageDataSlice";
import { updateMessage, updateOpen } from "../../../redux/message";
import { AgentDataAPI } from "../../../redux/agentSlice";

const AgentHome = ({ agentID, haveEditAccess }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [favoriteArtist, setFavoriteArtist] = useState([]);
  const { artistImageDataSlice, AddToCart } = useSelector((state) => state);
  const [allArtists, setAllArtists] = useState([]);
  const [filterHighlighted, setFilterHighlighted] = useState(null);
  const [tempArtist, setTempArtist] = useState([]);
  const [filterCond, setFilterCond] = useState(true);

  useEffect(() => {
    dispatch(ArtistImageSliceData());
    fetchFavoriteArtists();
  }, []);

  useEffect(() => {
    if (
      artistImageDataSlice.artistImages.length !== 0 &&
      favoriteArtist.length !== 0
    ) {
      const activeArtist = artistImageDataSlice.artistImages.filter(
        (artist) => artist.artistId.status === 1
      );
      const notFavorite = activeArtist.filter((artist) => {
        let match = true;
        favoriteArtist.forEach((favA) => {
          if (favA._id === artist.artistId._id) {
            match = false;
          }
        });
        return match;
      });
      setAllArtists(notFavorite);
    }
  }, [artistImageDataSlice.artistImages, favoriteArtist]);

  const fetchFavoriteArtists = async () => {
    try {
      const response = await getFavoriteArtists({ _id: agentID });
      setFavoriteArtist(response.favoriteArtists || []);
    } catch (error) {
      console.error("Failed to fetch favorite artists:", error);
    }
  };

  const filterChange = (filter) => {
    if (filter === "A-Z") {
      let temp = [];
      setFilterCond(false);
      let tempImage = [...artistImageDataSlice.artistImages];
      temp = tempImage.sort((a, b) =>
        a.artistId.lastname
          .normalize()
          .localeCompare(b.artistId.lastname.normalize())
      );
      setFilterHighlighted(2);
      setTempArtist(temp);
    } else {
      setFilterHighlighted(1);
      setFilterCond(true);
    }
  };

  const handleAddToFavorite = async (e, val) => {
    e.preventDefault();
    const { artistId } = val;
    try {
      dispatch(updateOpen(true));

      dispatch(updateMessage("Added to favorites!"));

      await addFavoriteArtist({ _id: agentID, artistId: artistId._id });
      const response = await getFavoriteArtists({ _id: agentID });
      setFavoriteArtist(response.favoriteArtists || []);
    } catch (error) {
      console.error("Error adding to favorites:", error);
    }
  };

  const handleDeleteFavorite = async (artist) => {
    try {
      await removeFavoriteArtist({ _id: agentID, artistId: artist._id });
      dispatch(updateOpen(true));
      dispatch(updateMessage("Successfully Unfavorited the Artist"));
      await dispatch(AgentDataAPI({ _id: agentID }));
      const response = await getFavoriteArtists({ _id: agentID });
      setFavoriteArtist(response.favoriteArtists || []);
    } catch (error) {
      console.error("Error adding to favorites:", error);
    }
  };

  return (
    <div style={{ marginTop: "25px" }}>
      <div style={{ display: "grid", gridTemplateColumns: "auto 1fr",marginBottom:"20px" }}>
        <h2
          className="hMyshannon"
          style={{ color: "rgb(250, 142, 55)", marginBottom: "0px" }}
        >
          MyHome
        </h2>
        <div className="separatorSubCat"></div>
      </div>
      <div style={{display:"grid",gridTemplateColumns:"1fr 1fr",columnGap:"40px"}}>
        <div>
          <h2 className="h2Myshannon" style={{ marginLeft: "20px"}}>
            MyFavorites:
          </h2>
          <p style={{ color: "#B0B0B0",fontSize: "0.65vw",fontWeight: 500,marginLeft:"20px",height:"40px"}}>
            Collect your favorite Shannon artists all in one place by selecting the heart ICON. They will show up in your “MyFavorites” and we will notify you of updates!
          </p>
          <div class="sortingcont right pt-1  me-0" style={{opacity:"0",marginBottom:"9px"}}>
            <div className="d-flex">
              <button
                class={
                  filterHighlighted === 1
                    ? "filter-button sort-active w-inline-block  mt-0"
                    : filterHighlighted === 2
                    ? "filter-button w-inline-block  mt-0"
                    : "filter-button sort-active w-inline-block  mt-0"
                }
                style={{ marginLeft: "0px",fontSize:"0.6vw",padding:"6px 12px" }}
                onClick={() => filterChange("Default")}
              >
                <div>FEATURED</div>
              </button>
              <button
                class={
                  filterHighlighted === 2
                    ? "filter-button sort-active mt-0 me-0"
                    : "filter-button mt-0 me-0"
                }
                onClick={() => filterChange("A-Z")}
                style={{fontSize:"0.6vw",padding:"6px 12px"}}
              >
                <div>ALPHABETICAL A-Z</div>
              </button>
            </div>
          </div>
          <div
            style={{
              height: "39vh",
              overflowY: favoriteArtist.length > 6 ? "scroll" : "",
              paddingLeft: "20px"
            }}
          >
            {favoriteArtist.length > 0 ? (
              <div className="detail_card_6">
                {favoriteArtist.map((artist, index) => (
                  <div className="artistcard homeMyShannon" key={index}>
                    {haveEditAccess && (
                      <img
                        src={crossSvg}
                        alt="Unfavorite"
                        className="CrossButton"
                        onClick={() => handleDeleteFavorite(artist)}
                      />
                    )}
                    <Link to={`/${artist.fullName}`} className="artistcard-link">
                      <img
                        src={String(artist.mainImage)}
                        loading="lazy"
                        alt=""
                        className="image"
                      />
                      <div className="artistnamediv">
                        <div className="artistnametext-v3">
                          {artist.firstname} {artist.lastname}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            ) : haveEditAccess ? (
              <h2 className="newsh2 h">
                Add some artists{" "}
                <span style={{ color: "rgb(250, 142, 55)" }}>Go to Shannon</span>
              </h2>
            ) : (
              <h2 className="newsh2 h">
                No Favorite Artists for this User{" "}
                <span style={{ color: "rgb(250, 142, 55)" }}>Go to Shannon</span>
              </h2>
            )}
          </div>

        </div>
        <div>
        <div>
        <h2 className="h2Myshannon" style={{ marginLeft: "20px" }}>
          Additional Artists:
        </h2>
        <p style={{ color: "#B0B0B0", fontSize: "0.65vw",fontWeight: 500,marginLeft:"20px",height:"40px"}}>
          {" "}
          Scroll and select additional "My Favorites" artists below or select
          from portfolio pages(look for the ICON)on the main Shannon site
        </p>
        <div class="sortingcont right pt-1  me-0" style={{marginBottom:"9px"}}>
          <div className="d-flex">
            <button
              class={
                filterHighlighted === 1
                  ? "filter-button sort-active w-inline-block  mt-0"
                  : filterHighlighted === 2
                  ? "filter-button w-inline-block  mt-0"
                  : "filter-button sort-active w-inline-block  mt-0"
              }
              style={{ marginLeft: "0px",fontSize:"0.6vw",padding:"6px 12px" }}
              onClick={() => filterChange("Default")}
            >
              <div>FEATURED</div>
            </button>
            <button
              class={
                filterHighlighted === 2
                  ? "filter-button sort-active mt-0 me-0"
                  : "filter-button mt-0 me-0"
              }
              onClick={() => filterChange("A-Z")}
              style={{fontSize:"0.6vw",padding:"6px 12px"}}
            >
              <div>ALPHABETICAL A-Z</div>
            </button>
          </div>
        </div>
        {allArtists.length === 0 ? (
          <div style={{ position: "relative", top: "50%", left: "50%" }}>
            <img className="mb-3" alt="loading" src={loading} />
          </div>
        ) : allArtists.length !== 0 && filterCond ? (
          <div
            className="detail_card_6 w-inline-block artist_card_h"
            style={{ marginLeft: "20px" }}
          >
            {allArtists.map((val, ind) => {
              return (
                <Link
                  className="detail_card5_h_contact"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    cursor: "pointer",
                  }}
                  to="#"
                  onClick={(e) => handleAddToFavorite(e, val)}
                >
                  <img
                    src={String(val.mainImage[0].subImage[0].path)}
                    alt="Artist"
                    className="w-100 h-100"
                    style={{ objectFit: "cover" }}
                  ></img>
                  <div className="artistnamediv">
                    <div
                      className="artistnametext-v3"
                      style={{ padding: "6px 0px" }}
                    >
                      {val.artistId.firstname} {val.artistId.lastname}
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        ) : (
          <div className="detail_card_6  w-inline-block artist_card_h">
            {tempArtist.map((val, ind) => {
              return (
                <Link
                  className="detail_card5_h_contact "
                  style={{ position: "relative", overflow: "hidden" }}
                  to="#"
                >
                  <img
                    src={String(val.mainImage[0].subImage[0].path)}
                    className="w-100 h-100"
                    style={{ objectFit: "cover" }}
                  ></img>
                  <div className="artistnamediv">
                    <div
                      className="artistnametext-v3"
                      style={{ padding: "6px 0px" }}
                    >
                      {val.artistId.firstname} {val.artistId.lastname}
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </div>
        </div>
      </div>
      <div
        className="separatorSubCat"
        style={{
          borderBottom: "2px solid #dadada",
          margin: "2vh 0px",
          marginLeft: "20px",
        }}
      ></div>
    </div>
  );
};

export default AgentHome;
