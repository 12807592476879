import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllContents } from "../../AxiosFunctions/Axiosfunctionality";
import { addCart } from "../../redux/addToCart";
import { updateMessage, updateOpen } from "../../redux/message";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import UserAvatar from "../shared/userAvatar";

const images = window.location.origin + "/assets/images";

function Header(props) {
  const { AddToCart } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [loginPopUp, setLoginPopUp] = useState(false);
  const [levelLogin, setLevelLogin] = useState(0);
  const [navLinkClass, setNavLinkClass] = useState(
    "navlink v2 w-nav-link w-nav-button"
  );

  function handleWindowResize() {
    setWindowSize(getWindowSize());
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  useEffect(() => {
    if (windowSize.innerWidth > 991) {
      setNavLinkClass("navlink v2 w-nav-link ");
    } else {
      setNavLinkClass("navlink v2 w-nav-link w-nav-button ");
    }
  }, [windowSize]);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const divisonPagesCurrentStyle = [
    "Illustration-artists",
    "Photography",
    "Medical",
    "Motion",
    "Divisions",
    "Illustration",
  ];
  const isMedical = ["general", "technical", "MEDICAL"];

  const bipocPagesCurrentStyle = [
    "Black",
    "Asian",
    "Latino",
    "Central Asia",
    "Indigenous",
    "AsianArtist",
    "LatinoArtist",
    "CentralAsianArtist",
    "IndigenousArtist",
  ];
  const isGraphicNovel = [
    "covers",
    "sequential",
    "inking",
    "coloring",
    "lettering",
  ];
  const isDivisionStyle = ["divisions", "bipoc", "graphicNovel"];

  const [shannonContent, setShannonContent] = useState([]);

  const location = useLocation();
  const getAllContent = () => {
    getAllContents({ type: "SHANNON" }).then((res) => {
      let shannon = res[0].content;
      setShannonContent(shannon);
    });
  };

  const addToCartArtist = (id, firstname) => {
    dispatch(addCart({ key: id, data: { id: id, Name: firstname } }));
  };

  const addToCartArtistHandler = (id, title) => {
    let key = Object.keys(AddToCart.cartInfo).find((element) => element == id);
    if (key == undefined) {
      addToCartArtist(id, title);
    }
  };

  useEffect(() => {
    getAllContent();

    let obj = JSON.parse(localStorage.getItem("addToCart"));
    if (obj !== null) {
      obj.map((val) => {
        addToCartArtistHandler(val.id, val.Name);
      });
      localStorage.removeItem("addToCart");
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("authorization");
    dispatch(updateOpen(true));
    dispatch(updateMessage("Successfully Logged Out"));
    window.dispatchEvent(new Event("storage"));
  };
  const localStorageAddToCart = () => {
    let addToCartArray = [];
    Object.keys(AddToCart.cartInfo).map((oneKey, i) => {
      if (
        oneKey !== "messageShow" &&
        oneKey !== "count" &&
        oneKey !== "getAnEstimate"
      ) {
        addToCartArray.push(AddToCart.cartInfo[oneKey]);
      }
    });
    if (addToCartArray.length > 0) {
      localStorage.setItem("addToCart", JSON.stringify(addToCartArray));
    } else {
      localStorage.removeItem("addToCart");
    }
  };
  const loggedInUser = props.clientDetails
    ? props.clientDetails
    : props.agentDetails
    ? props.agentDetails
    : null;
  const loggedInUserType = props.clientDetails
    ? "client"
    : props.agentDetails
    ? "myshannon"
    : null;
  const firstLetter = loggedInUser?.firstname[0].toUpperCase();

  const buttonLoginClicked = (level) => {
    setLoginPopUp(true);
    setLevelLogin(level);
  };

  return (
    <div className="menu wf-section mb-0">
      <div className="containerhome f ">
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navbar w-nav"
        >
          <div className="container w-container d-inline pl-0 pr-0 ">
            <Link
              to="/"
              aria-current="page"
              className="brand w-nav-brand w--current"
            >
              <img
                src={images + "/Logo_Shannon3.svg"}
                loading="lazy"
                alt=""
                className="logov2"
              />
            </Link>
            <nav role="navigation" className="navmenu mobMenu w-nav-menu ">
              <div className="navItemsMainContainer">
                <a
                  href="https://kidshannon.com"
                  target="_blank"
                  className={navLinkClass}
                >
                  KIDSHANNON
                </a>

                <Link
                  to="/"
                  aria-current="page"
                  className={
                    navLinkClass + (!props.aciveBtn ? "w--current" : "")
                  }
                >
                  ARTISTS
                </Link>
                <Link
                  to="/divisions"
                  className={
                    navLinkClass +
                    (isDivisionStyle.includes(props.aciveBtn) ||
                    isGraphicNovel.includes(props.graphicNovel) ||
                    divisonPagesCurrentStyle.includes(
                      localStorage.getItem("Category")
                    ) ||
                    bipocPagesCurrentStyle.includes(
                      localStorage.getItem("Bipoc")
                    )
                      ? "w--current"
                      : "")
                  }
                >
                  DIVISIONS
                </Link>
                <Link
                  to="/categories"
                  className={
                    navLinkClass +
                    (props.aciveBtn === "categories" ||
                    (!divisonPagesCurrentStyle.includes(
                      localStorage.getItem("Category")
                    ) &&
                      localStorage.getItem("Category") != "" &&
                      localStorage.getItem("Category") != "none")
                      ? "w--current"
                      : "")
                  }
                >
                  CATEGORIES
                </Link>
                <Link
                  to="/about"
                  className={
                    navLinkClass +
                    (props.aciveBtn === "about" ? "w--current" : "")
                  }
                >
                  ABOUT
                </Link>
                {props.clientDetails || props.agentDetails ? (
                  <></>
                ) : (
                  <Link
                    to="/contact"
                    className={
                      navLinkClass +
                      (props.aciveBtn === "contact" ? "w--current" : "")
                    }
                  >
                    CONTACT/ MY LIST
                    {AddToCart.cartInfo.count > 0 ? (
                      <div className="cartBadge">
                        {AddToCart.cartInfo.count}
                      </div>
                    ) : null}
                  </Link>
                )}
                {(props.clientDetails || props.agentDetails) && (
                  <Link
                    to={`/${loggedInUserType}/${loggedInUser._id}?activeTab=home`}
                    className="navlink v2"
                    style={{ marginLeft: "20px", color: "rgb(250, 142, 55)" }}
                  >
                    <b>MYSHANNON</b>
                  </Link>
                )}
                <div className="headerAdditonalItems">
                  <hr />
                  <Link
                    to="/illustration-artists"
                    className={
                      navLinkClass +
                      (props.aciveBtn === "illustration-artists" ||
                      props.currentCat == "Illustration" ||
                      props.currentCat == "Illustration-artists"
                        ? "w--current"
                        : "")
                    }
                  >
                    ILLUSTRATION
                  </Link>
                  <a
                    href="https://kidshannon.com"
                    target="_blank"
                    className={
                      navLinkClass +
                      (props.aciveBtn === "kidshannon" ? "w--current" : "")
                    }
                    onClick={() => localStorageAddToCart()}
                  >
                    KIDSHANNON
                  </a>

                  <Link
                    to="/bipoc"
                    className={
                      navLinkClass +
                      "  d-flex " +
                      (props.aciveBtn === "bipoc" ||
                      bipocPagesCurrentStyle.includes(props.currentBipoc)
                        ? "w--current"
                        : "")
                    }
                  >
                    BIPOC
                  </Link>

                  <Link
                    to="/properties"
                    className={
                      navLinkClass +
                      (props.aciveBtn === "properties" ? " w--current" : "")
                    }
                  >
                    {" "}
                    PROPERTIES{" "}
                  </Link>

                  <Link
                    to="/medical"
                    className={
                      navLinkClass +
                      (isMedical.includes(props.currentMedical)
                        ? "w--current"
                        : "")
                    }
                  >
                    MEDICAL
                  </Link>

                  <Link
                    to="/graphicNovel"
                    className={
                      navLinkClass +
                      (isGraphicNovel.includes(props.currentGraphical)
                        ? "w--current"
                        : "")
                    }
                  >
                    GRAPHIC NOVEL
                  </Link>

                  <Link
                    to="/motions"
                    className={
                      navLinkClass +
                      (props.aciveBtn === "motions" ||
                      props.currentCat == "motions"
                        ? "w--current"
                        : "")
                    }
                  >
                    MOTION
                  </Link>

                  <Link
                    to="/newest"
                    className={
                      navLinkClass +
                      (props.aciveBtn === "newest"
                        ? " w--current undelinedNavbar"
                        : "")
                    }
                  >
                    {" "}
                    <span style={{ color: "#fa8e37" }}>
                      NEW
                    </span> ILLUSTRATORS{" "}
                  </Link>
                  <Link
                    to="/recentlyUpdated"
                    className={
                      navLinkClass +
                      (props.aciveBtn === "recentlyUpdated" ? "w--current" : "")
                    }
                  >
                    {" "}
                    <span style={{ color: "#fa8e37" }}>
                      UPDATED
                    </span> PORTFOLIOS{" "}
                  </Link>

                  <Link
                    to="/mentorship"
                    className={
                      navLinkClass +
                      (props.aciveBtn === "mentorship"
                        ? " w--current undelinedNavbar"
                        : "")
                    }
                    style={{ color: "#B0B0B0" }}
                  >
                    {" "}
                    MENTORSHIP{" "}
                  </Link>
                  <Link
                    to="/philanthropy"
                    className={
                      navLinkClass +
                      (props.aciveBtn === "philanthropy"
                        ? " w--current undelinedNavbar"
                        : "")
                    }
                    style={{ color: "#B0B0B0" }}
                  >
                    {" "}
                    PHILANTHROPY{" "}
                  </Link>
                </div>
                {props.clientDetails || props.agentDetails ? (
                  <UserAvatar
                    letter={firstLetter || ""}
                    userType={loggedInUserType}
                    userId={loggedInUser._id}
                  />
                ) : (
                  <>
                    {/*  <div className={navLinkClass} style={{ padding: "0" }}>
                      <button
                        className={" headerButton "}
                        onClick={() => buttonLoginClicked(0)}
                      >
                        LOGIN
                      </button>
                    </div>
                    <div className={navLinkClass} style={{ padding: "0" }}>
                      <button
                        className={" headerButton "}
                        onClick={() => buttonLoginClicked(1)}
                      >
                        SIGN UP
                      </button>
                    </div>
                    {loginPopUp && (
                      <PopUpLogin
                        setModal={setLoginPopUp}
                        slevel={levelLogin}
                      />
                    )} */}
                  </>
                )}
              </div>

              <div className="menuinfo v2">
                <img
                  className="infoImage"
                  alt="INFO@SHANNONASSOCIATES.COM • 212.333.2251"
                  src={images + "/shannonInfo.svg"}
                />
              </div>
            </nav>
            <div className="menu-button w-nav-button"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
