import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../AxiosFunctions/Axiosfunctionality";

export const AgentDataAPI = createAsyncThunk(
  "agent/AgentDataAPI",
  async (payload) => {
    return axios
      .post(BASE_URL + "agentUser/getSingleAgent", payload)
      .then((response) => response.data);
  }
);

const initialState = {
  agentData: {},
  loading: false,
};

const AgentSlice = createSlice({
  name: "AgentData",
  initialState,
  reducers: {
    logoutAgent: (state) => {
      // Reset the state to initial state or clear specific fields
      state.agentData = {};
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AgentDataAPI.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(AgentDataAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.agentData = action.payload;
    });
    builder.addCase(AgentDataAPI.rejected, (state, action) => {
      state.loading = false;
      state.agentData = action.payload;
    });
  },
});
export const { logoutAgent } = AgentSlice.actions;
export default AgentSlice.reducer;
