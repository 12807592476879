// Import Libraries
import { Route, Switch, BrowserRouter } from "react-router-dom";
import "react-image-crop/dist/ReactCrop.css";

// Import Resources
import SignInUp from "./containers/signInUp/signInUp";
import Artist from "./containers/artist/admin";

import Admin from "./containers/admin/admin";
import Index from "./containers/clientSite";
import Page404 from "./containers/clientSite/404";
import PrivateRoute from "./containers/PrivateRoute";
import PrivateArtistRoute from "./containers/PrivateArtistRoute";
import ForgetPassword from "./containers/signInUp/forgetPassword";

import { Helmet } from "react-helmet";
import SnackbarCustom from "./components/snackBar/SnackbarCustom";

function App() {
  localStorage.removeItem("artistViewed_V1");
  localStorage.removeItem("artistViewed_V2");
  localStorage.removeItem("artistViewed_V3");
  localStorage.removeItem("artistViewed_V4");
  return (
    <>
      <SnackbarCustom />
      <Helmet>
        <title>Shannon Associates</title>
        <meta name="description" content="Shannon Associates" />
      </Helmet>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/artist/signin"
            name="Artist Sign In"
            component={SignInUp}
          />
          <Route
            exact
            path="/artist/signup"
            name="Artist Sign up"
            component={SignInUp}
          />
          <Route
            exact
            path="/forgetArtist"
            name="Artist forget"
            component={ForgetPassword}
          />
          <Route
            exact
            path="/myshannon/signin"
            name="My Shannon Sign In"
            component={SignInUp}
          />
          <Route
            exact
            path="/myshannon/signup"
            name="My Shannon Sign up"
            component={SignInUp}
          />
          <Route
            exact
            path="/forgetMyShannon"
            name="MyShannon forget"
            component={ForgetPassword}
          />
          <Route
            exact
            path="/admin/signin"
            name="Admin Sign In"
            component={SignInUp}
          />
          <Route
            exact
            path="/forgetAdmin"
            name="Admin forget"
            component={ForgetPassword}
          />
          <PrivateArtistRoute
            exact
            path="/artist/"
            name="Artist"
            component={Artist}
          />
          <PrivateArtistRoute
            exact
            path="/artist/:pageName"
            name="Artist"
            component={Artist}
          />
          <PrivateArtistRoute
            exact
            path="/artist/:pageName/:imageId"
            name="Artist"
            component={Artist}
          />
          <PrivateRoute exact path="/admin/" name="Admin" component={Admin} />

          <PrivateRoute
            exact
            path="/admin/:pageName"
            name="Admin"
            component={Admin}
          />
          <PrivateRoute
            exact
            path="/admin/:pageName/:imageId"
            name="Admin"
            component={Admin}
          />

          <Route
            exact
            path="/"
            name="Home"
            // component={Index}
            render={(props) => {
              if (
                props.match.params.pages !== "admin" ||
                props.match.params.pages !== "artist"
              ) {
                return <Index />;
              } else {
                return <Page404 />;
              }
            }}
          />
          <Route
            exact
            path="/:pages"
            name="Home"
            render={(props) => {
              if (props.match.params.pages === "admin") {
                return <Admin />;
              } else if (props.match.params.pages === "artist") {
                return <Artist />;
              } else if (props.match.params.pages === "404") {
                return <Page404 />;
              } else if (!props.match.params.pages) {
                return <Artist />;
              } else {
                return <Index />;
              }
            }}
          />
          <Route
            exact
            path="/:pages/:search"
            name="Home"
            render={(props) => {
              if (props.match.params.search) {
                if (
                  props.match.params.pages === "artist" ||
                  props.match.params.pages === "bipoc" ||
                  props.match.params.pages === "medical"
                ) {
                  return <Index />;
                } else if (
                  props.match.params.pages === "divisions" ||
                  props.match.params.pages === "categories" ||
                  props.match.params.pages === "graphicNovel"
                ) {
                  return <Index />;
                } else {
                  return <Index />;
                }
              } else {
                return <Page404 />;
              }
            }}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
