import "react-quill/dist/quill.snow.css";
import "../admin.css";

import React, { useEffect, useState } from "react";

function Artists(props) {
  const [formNo2, setFormNo2] = useState(1);

  useEffect(() => {}, []);

  return (
    <div className="px-xl-1">
      <div className={"p-lg-3 ml-5 mt-3 d-flex flex-column align-items-center"}>
        <div className="col-6 p-0 subNavBar d-flex justify-content-between">
          <button
            onClick={() => setFormNo2(1)}
            className={"btn" + (formNo2 === 1 ? " active" : "")}
          >
            Keywords
          </button>
        </div>
      </div>
    </div>
  );
}

export default Artists;
