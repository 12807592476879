import React, { useEffect, useState } from "react";
import loading from "../../../assets/loading.gif";
import { allCityGetter, allStateGetter } from "../../../redux/StateCity";

function Edit(props) {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [occupation, setOccupation] = useState("");
  const [email, setEmail] = useState("");

  const [status, setStatus] = useState("0");

  const [passsword, setPassword] = useState("");

  useEffect(() => {
    setFirstname(props.selectedClient.firstname);
    setLastname(props.selectedClient.lastname);
    setCompany(props.selectedClient.company);
    setOccupation(props.selectedClient.occupation);
    setEmail(props.selectedClient.email);

    setStatus(String(props.selectedClient.status));

    setPassword(props.selectedClient.raw_password);
  }, []);

  return (
    <div className="row px-5 mx-5">
      <label className="col-md-6">
        <div>First Name</div>
        <input
          className="textField"
          value={firstname}
          onChange={(e) => {
            setFirstname(e.target.value);
          }}
        />
      </label>
      <label className="col-md-6">
        <div>Last Name</div>
        <input
          className="textField"
          value={lastname}
          onChange={(e) => {
            setLastname(e.target.value);
          }}
        />
      </label>
      <label className="col-md-6">
        <div>Company</div>
        <input
          className="textField"
          value={company}
          onChange={(e) => {
            setCompany(e.target.value);
          }}
        />
      </label>
      <label className="col-md-6">
        <div>Occupation</div>
        <input
          className="textField"
          value={occupation}
          onChange={(e) => {
            setOccupation(e.target.value);
          }}
        />
      </label>
      <label className="col-md-6">
        <div>Email</div>
        <input
          type="email"
          className="textField"
          disabled
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </label>
      <label className="col-md-6">
        <div>Password</div>
        <input
          type="email"
          className="textField"
          value={passsword}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </label>

      <label className="col-12">Status</label>
      <label className="px-3">
        <input
          className="mr-2"
          name="status"
          type="radio"
          value={"1"}
          checked={status === "1"}
          onChange={(e) => {
            setStatus(e.target.value);
          }}
        />
        {"Active"}
      </label>
      <label className="px-3">
        <input
          className="mr-2"
          name="status"
          type="radio"
          value={"0"}
          checked={status === "0"}
          onChange={(e) => {
            setStatus(e.target.value);
          }}
        />
        {"Inactive"}
      </label>

      <div className="col-12 d-flex justify-content-end">
        {props.holder ? (
          <img
            className="mt-1"
            alt="loading"
            src={loading}
            style={{ width: "30px" }}
          />
        ) : (
          <button
            className="mx-1 myBtn active sm"
            onClick={(e) => {
              props.updateClient(e, {
                _id: props.selectedClient._id,
                firstname: firstname,
                lastname: lastname,
                occupation: occupation,

                company: company,
                email: email,

                status: status,

                raw_password: passsword,
              });
            }}
          >
            SAVE
          </button>
        )}
      </div>
    </div>
  );
}

export default Edit;
