import { useState } from "react";
import PopUpLogin from "./popUpLogin";

const AccessDenied = () => {

  const [modal, setModal] = useState(false);

  return (
    <div style={{width:"100%"}}>
        <div>
            <img src="/assets/images/AccessDenied.png" onClick={()=>{setModal(true)}} style={{cursor:"pointer"}} />
            {/* <h4 className="pwh4">To access this content please log in or create an account.</h4> */}
            {/* <button className="submit-button mr-md-4" onClick={()=>{setModal(true)}}>
                CLICK HERE
            </button> */}
            {
              modal && <PopUpLogin setModal={setModal}/>
            }

        </div>
    </div>
  )
};

export default AccessDenied;