import React, { useState } from "react";
import ArtistUpdatesLists from "./artistsUpdatesList";
import BioArtistAdmin from "../bioUpdateApprove";
import PWUpdateApprove from "../pwUpdateApprove";

import PersonalWorkApprove from "../personalWorkApprove";

import PropertiesUploadApprove from "../propertiesUpdateApprove";
import MotionApprove from "../motionApprove";
import AwardPressApprove from "../awardPressApprove";

function ArtistUpdates(props) {
  const [formNo, setFormNo] = useState(0);
  const [artistUpdateId, setArtistUpdateId] = useState("");
  return (
    <>
      {formNo === 1 ? (
        <BioArtistAdmin
          artistUpdates={props.artistUpdates}
          setFormNo={(e) => setFormNo(e)}
          artistUpdateId={artistUpdateId}
          populateArtistUpdates={props.populateArtistUpdates}
        />
      ) : formNo === 2 ? (
        <PWUpdateApprove
          artistUpdates={props.artistUpdates}
          setFormNo={(e) => setFormNo(e)}
          artistUpdateId={artistUpdateId}

          populateArtistUpdates={props.populateArtistUpdates}/>
      ): formNo === 3 ? (
          <PersonalWorkApprove setFormNo={(e) => setFormNo(e)} artistUpdates={props.artistUpdates} artistUpdateId={artistUpdateId} populateArtistUpdates={props.populateArtistUpdates}/>
      ) : formNo === 4 ? (
        <PropertiesUploadApprove
          artistUpdates={props.artistUpdates}
          setFormNo={(e) => setFormNo(e)}
          artistUpdateId={artistUpdateId}
          populateArtistUpdates={props.populateArtistUpdates}
        />
      ) : formNo === 5 ? (
        <MotionApprove artistUpdates={props.artistUpdates}
          setFormNo={(e) => setFormNo(e)}
          artistUpdateId={artistUpdateId}
          populateArtistUpdates={props.populateArtistUpdates}/>
      ) : formNo === 6 ? (
        <AwardPressApprove artistUpdates={props.artistUpdates}
          setFormNo={(e) => setFormNo(e)}
          artistUpdateId={artistUpdateId}
          populateArtistUpdates={props.populateArtistUpdates}/>
      ) : (
        <div className="col">
          <ArtistUpdatesLists
            artistUpdates={props.artistUpdates}
            deleteArtistUpdate={props.deleteArtistUpdate}
            approveArtistUpdate={props.approveArtistUpdate}
            loading={props.loading}
            setFormNo={(e) => setFormNo(e)}
            setArtistUpdateId={setArtistUpdateId}
          />
        </div>
      )}
    </>
  );
}

export default ArtistUpdates;
