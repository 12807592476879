import { useEffect, useState } from "react";
import AddImage from "../../assets/svgs/addImage.svg";

const PublishWorks = ({ artistDetails, history, setCurrentPW }) => {
  const handlePW = (publishedWork) => {
    setCurrentPW(publishedWork);
    history.push("/artist/publish_works_upload");
  };

  useEffect(() => {
    setCurrentPW(null);
  }, []);

  return (
    <div className="px-1 px-md-5 artist mb-5">
      <h4> PUBLISHED WORKS </h4>
      <p>
        We encourage you to upload projects you want to highlight to clients. This includes any published books, magazine articles, and advertising campaigns. It is a wonderful way to help clients see your artwork in a completed form.
      </p>
      <p style={{ color: "#FF0000"}}>
        ***Please do not upload anything earlier than it’s publication date - you are liable, this rule is good to adhere to in all regards. DO NOT to show partial or completed work (or post about it) until the client announces and shows it online first.
      </p>
      <div className="_4cols-v2">
        <label
          className="artistcardAdmin w-inline-block addImageBtn"
          onClick={() => history.push("/artist/publish_works_upload")}
        >
          <img alt="" src={AddImage} className="addImage" />
        </label>
        {artistDetails?.publishedWorks
          ? [...artistDetails.publishedWorks]
              .reverse()
              .map((publisedWork, ind) => (
                <div
                  className="artistcardAdmin w-inline-block"
                  style={{ cursor: "pointer" }}
                  key={publisedWork.id}
                  onClick={() => handlePW(publisedWork)}
                >
                  <img
                    src={publisedWork.pwMainImage}
                    alt={publisedWork.titlePW}
                    className="image"
                  />
                </div>
              ))
          : null}
      </div>
    </div>
  );
};

export default PublishWorks;
