import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import "./admin.css";
import Header from "../../components/layout2/header";

import Main from "../../components/layout2/main";
import Dashboard from "./dashboard";

import CategoryIndex from "./categoryPages/index";

import ImageUploading from "./imageUploading";
import ArtistImageUpload from "./artist";
import ArtistDashbooardSidebar from "../../components/layout2/artistDashboardSideBar";
import BioArtist from "./bio";
import { decodeToken } from "react-jwt";
import { getSingleArtist } from "../../AxiosFunctions/Axiosfunctionality";
import PublishWorks from "./publishWorks";

import PublishWorksUpload from "./publishWorksUpload";
import PersonalWorks from "./personalWorks";
import PersonalWorkUpload from "./personalWorkUpload";
import PropertiesArtist from "./properties";
import PropertiesUpload from "./propertiesUpload";
import Motions from "./motions";
import MotionsUpload from "./motionsUpload";
import AwardsPress from "./awardsPress";
import AwardPressUpload from "./awardPressUpload";

function Admin(props) {
  const { pageName } = useParams();
  const [artistDetails, setArtistDetails] = useState(null);
  const [motions, setMotions] = useState();
  const [currentPW, setCurrentPW] = useState();

  const [currentPersonalWork, setCurrentPersonalWork] = useState();
  const [currentMotion, setCurrentMotion] = useState();
  const [currentAward, setCurrentAward] = useState();

  const [currentProperty, setCurrentProperty] = useState();

  useEffect(() => {
    const fetchData = async () => {
      // Retrieve the token from localStorage
      const storageData = localStorage.getItem("authorization");

      if (storageData) {
        // Decode the token
        const details = decodeToken(storageData);

        if (details && details._id) {
          try {
            // Fetch the artist details
            const response = await getSingleArtist({ _id: details._id });
            setArtistDetails(response);
            setMotions(response.motions.reverse());
          } catch (error) {
            // Handle any errors, such as network issues or decoding problems
            console.error("Error fetching artist details:", error);
          }
        }
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header />

      <div className="admin d-flex m-0">
        <ArtistDashbooardSidebar
          activePage={pageName}
          artistDetails={artistDetails}
        />
        <Main>
          {!pageName || pageName === "dashboard" ? (
            <Dashboard history={props.history} />
          ) : pageName === "image_upload" ? (
            <ArtistImageUpload />
          ) : pageName === "image_uploading" ? (
            <ImageUploading />
          ) : pageName === "portfolio" ? (
            <CategoryIndex history={props.history} />
          ) : pageName === "bio" ? (
            <BioArtist history={props.history} artistDetails={artistDetails} />
          ) : pageName === "properties" ? (
            <PropertiesArtist
              history={props.history}
              artistDetails={artistDetails}
              setCurrentProperty={setCurrentProperty}
            />
          ) : pageName === "properties_upload" ? (
            <PropertiesUpload
              history={props.history}
              artistDetails={artistDetails}
              currentProperty={currentProperty}
            />
          ) : pageName === "publish_works" ? (
            <PublishWorks
              history={props.history}
              artistDetails={artistDetails}
              setCurrentPW={setCurrentPW}
            />
          ) : pageName === "publish_works_upload" ? (
            <PublishWorksUpload
              history={props.history}
              artistDetails={artistDetails}
              currentPW={currentPW}
            />
          ) : pageName === "motion" ? (
            <Motions
              history={props.history}
              setCurrentMotion={setCurrentMotion}
              artistDetails={artistDetails}
              motions={motions}
            />
          ) : pageName === "motion_upload" ? (
            <MotionsUpload
              history={props.history}
              currentMotion={currentMotion}
              artistDetails={artistDetails}
            />
          ) : pageName === "awards_press" ? (
            <AwardsPress
              history={props.history}
              setCurrentAward={setCurrentAward}
              artistDetails={artistDetails}
            />
          ) : pageName === "awards_press_upload" ? (
            <AwardPressUpload
              history={props.history}
              currentAward={currentAward}
              artistDetails={artistDetails}
            />
          ) : pageName === "awards" ? (
            <CategoryIndex history={props.history} />
          ) : pageName === "press" ? (
            <CategoryIndex history={props.history} />
          ) : pageName === "kids_portfolio" ? (
            <CategoryIndex history={props.history} />
          ) : pageName === "personal_work" ? (
            <PersonalWorks
              history={props.history}
              artistDetails={artistDetails}
              setCurrentPersonalWork={setCurrentPersonalWork}
            />
          ) : pageName === "personal_work_upload" ? (
            <PersonalWorkUpload
              history={props.history}
              artistDetails={artistDetails}
              currentPersonalWork={currentPersonalWork}
            />
          ) : (
            <Redirect to="/artist/" />
          )}
        </Main>
      </div>
    </>
  );
}

export default Admin;
