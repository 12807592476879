import React, { useState, useEffect } from "react";
import {
  getFavoriteArtists,
  removeFavoriteArtist,
} from "../../../AxiosFunctions/Axiosfunctionality";
import { Link } from "react-router-dom";
import loadingImg from "../../../assets/loading.gif";
import crossSvg from "../../../assets/svgs/crossButton.svg"; // Import your SVG file
import MyPopup from "../../../components/myPopup/myPopup";
import { updateMessage, updateOpen } from "../../../redux/message";
import { useDispatch } from "react-redux";
import { AgentDataAPI } from "../../../redux/agentSlice";

const AgentTabFav = ({ agentID, haveEditAccess }) => {
  const [favoriteArtist, setFavoriteArtist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [selectedArtistId, setSelectedArtistId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchFavoriteArtists();
  }, [agentID]);

  const fetchFavoriteArtists = async () => {
    try {
      setLoading(true);
      const response = await getFavoriteArtists({ _id: agentID });
      setFavoriteArtist(response.favoriteArtists || []);
    } catch (error) {
      console.error("Failed to fetch favorite artists:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUnfavorite = async () => {
    try {
      await removeFavoriteArtist({ _id: agentID, artistId: selectedArtistId });
      dispatch(updateOpen(true));
      dispatch(updateMessage("Successfully Unfavorited the Artist"));
      await fetchFavoriteArtists(); // Refresh the list after removal
      await dispatch(AgentDataAPI({ _id: agentID }));

      setDeleteModalActive(false); // Close the modal
    } catch (error) {
      console.error("Failed to unfavorite the artist:", error);
    }
  };

  if (loading) {
    return (
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        <img
          className="mb-3"
          alt="loading"
          src={loadingImg}
          style={{ width: "50px" }}
        />
      </div>
    );
  }

  return (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "auto 1fr",marginBottom:"20px",marginTop:"25px" }}>
        <h2
          className="hMyshannon"
          style={{ color: "rgb(250, 142, 55)", marginBottom: "0px" }}
        >
          MyFavorite Artists
        </h2>
        <div className="separatorSubCat"></div>
      </div>
      {favoriteArtist.length > 0 ? (
        <div className="_4cols-v2">
          {favoriteArtist.map((artist, index) => (
            <div className="artistcard" key={index}>
              {haveEditAccess && (
                <img
                  src={crossSvg}
                  alt="Unfavorite"
                  className="CrossButton"
                  onClick={() => {
                    setSelectedArtistId(artist._id);
                    setDeleteModalActive(true);
                  }}
                />
              )}
              <Link to={`/${artist.fullName}`} className="artistcard-link">
                <img
                  src={String(artist.mainImage)}
                  loading="lazy"
                  alt=""
                  className="image"
                />
                <div className="artistnamediv">
                  <div className="artistnametext-v3">
                    {artist.firstname} {artist.lastname}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      ) : haveEditAccess ? (
        <h2 className="newsh2 h">
          Add some artists{" "}
          <span style={{ color: "rgb(250, 142, 55)" }}>Go to Shannon</span>
        </h2>
      ) : (
        <h2 className="newsh2 h">
          No Favorite Artists for this User{" "}
          <span style={{ color: "rgb(250, 142, 55)" }}>Go to Shannon</span>
        </h2>
      )}

      {deleteModalActive && (
        <MyPopup
          BackClose
          onClose={() => setDeleteModalActive(false)}
          whiteCard={true}
        >
          <div className="mx-5 my-2">
            <h2 style={{ color: "#D16A27" }}>Delete Artist</h2>
            <p>Are you sure you want to delete this artist from favorites?</p>
            <div className="mx-5 my-2 d-flex align-items-center justify-content-center">
              <button className="myBtn active mx-1" onClick={handleUnfavorite}>
                DELETE
              </button>
              <button
                className="myBtn mx-1"
                onClick={() => setDeleteModalActive(false)}
              >
                CANCEL
              </button>
            </div>
          </div>
        </MyPopup>
      )}
    </>
  );
};

export default AgentTabFav;
