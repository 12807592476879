import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { getBipocLatino } from "../../AxiosFunctions/Axiosfunctionality";
import { useDispatch, useSelector } from "react-redux";
import { bannerLoader } from "../../redux/bannerImages";
import loading from "../../assets/loading.gif";

function LatinoArtist(props) {
  const dispatch = useDispatch();
  const { bannerImages } = useSelector((state) => state);
  const [latinoArtist, setLatinoArtist] = useState(null);
  const [filterHighlighted, setFilterHighlighted] = useState(null);
  const history = useHistory();
  const [windowSize] = useState(getWindowSize());
  const [dataOriginal, setDataOriginal] = useState(null);
  const [data, setData] = useState(null);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const filterChange = (filter) => {
    let tempData = [...data];
    let filter_highli = null;
    setDataOriginal([...data]);
    if (filter === "A-Z") {
      filter_highli = 2;
      tempData = tempData.sort((a, b) =>
        a.artistId.lastname
          .normalize()
          .localeCompare(b.artistId.lastname.normalize())
      );
    } else if (dataOriginal) {
      filter_highli = 1;
      tempData = [...dataOriginal];
      // tempData = dataOriginal;
    }

    setData(tempData);
    setFilterHighlighted(filter_highli);
  };

  useEffect(() => {
    if (bannerImages.bipocBannerData.length === 0) {
      dispatch(bannerLoader());
    }
  }, [bannerImages.bipocBannerData.length, dispatch]);

  useEffect(() => {
    getBipocLatino().then((res) => {
      let orderedArtist = res?.sort((a, b) => {
        if (
          a.artistData.lastname
            .normalize()
            .localeCompare(b.artistData.lastname.normalize()) === 0
        ) {
          return a.artistData.firstname
            .normalize()
            .localeCompare(b.artistData.firstname.normalize());
        } else {
          return a.artistData.lastname
            .normalize()
            .localeCompare(b.artistData.lastname.normalize());
        }
      });
      setLatinoArtist(orderedArtist);
    });
  }, []);

  useEffect(() => {
    if (latinoArtist) {
      let orderedArtist = latinoArtist?.sort((a, b) => {
        if (
          a.artistData.lastname
            .normalize()
            .localeCompare(b.artistData.lastname.normalize()) === 0
        ) {
          return a.artistData.firstname
            .normalize()
            .localeCompare(b.artistData.firstname.normalize());
        } else {
          return a.artistData.lastname
            .normalize()
            .localeCompare(b.artistData.lastname.normalize());
        }
      });
      setLatinoArtist(orderedArtist);
    }
  }, [latinoArtist]);

  const mapBipocName = (bipocSubCategory) => {
    const bipocObj = {
      AsianArtist: "Asian",
      LatinoArtist: "Latino/Latina",
      CentralAsianArtist: "Central Asian",
      IndigenousArtist: "Indigenous",
    };
    return bipocObj[bipocSubCategory];
  };

  return (
    <>
      <div class="sortingcont right pt-0  me-0 ">
        <div className="d-flex  ">
          <button
            class={
              filterHighlighted === 1
                ? "filter-button sort-active w-inline-block  mt-0"
                : filterHighlighted === 2
                ? "filter-button w-inline-block  mt-0"
                : "filter-button sort-active w-inline-block  mt-0"
            }
            style={{ marginLeft: "0px" }}
            onClick={() => filterChange("Default")}
          >
            <div>FEATURED</div>
          </button>
          <button
            class={
              filterHighlighted === 2
                ? "filter-button sort-active mt-0 me-0"
                : "filter-button mt-0 me-0"
            }
            onClick={() => filterChange("A-Z")}
          >
            <div>ALPHABETICAL A-Z</div>
          </button>
        </div>
      </div>
      <div className="_2cols2_" style={{ clear: "both" }}>
        {props.children}
        <div
          id="w-node-_4a165d69-02be-f2c1-10f5-69fa4946403e-576fcec6"
          className="divisionscolumn"
        >
          <div
            id="w-node-_4a165d69-02be-f2c1-10f5-69fa4946403f-576fcec6"
            className="divisioncontainer"
          >
            <div>
              {JSON.parse(localStorage.getItem("routePaths")) ? (
                <div
                  className={"d-flex"}
                  style={{ marginBottom: "10px", width: "98.4%" }}
                >
                  {JSON.parse(localStorage.getItem("routePaths")).map(
                    (obj, ind) => {
                      if (ind === 2) {
                        return (
                          <p
                            className={
                              JSON.parse(localStorage.getItem("routePaths"))
                                .length ===
                              ind + 1
                                ? "breadCrumbs"
                                : "breadCrumbsActive"
                            }
                            onClick={
                              JSON.parse(localStorage.getItem("routePaths"))
                                .length ===
                              ind + 1
                                ? () => {}
                                : () => history.push(obj.link)
                            }
                            style={ind === 0 ? {} : { marginLeft: "5px" }}
                          >
                            {mapBipocName(obj.val)}{" "}
                            {JSON.parse(localStorage.getItem("routePaths"))
                              .length ===
                            ind + 1
                              ? null
                              : ">"}
                          </p>
                        );
                      } else {
                        return (
                          <p
                            className={
                              JSON.parse(localStorage.getItem("routePaths"))
                                .length ===
                              ind + 1
                                ? "breadCrumbs"
                                : "breadCrumbsActive"
                            }
                            onClick={
                              JSON.parse(localStorage.getItem("routePaths"))
                                .length ===
                              ind + 1
                                ? () => {}
                                : () => history.push(obj.link)
                            }
                            style={ind === 0 ? {} : { marginLeft: "5px" }}
                          >
                            {obj.val}{" "}
                            {JSON.parse(localStorage.getItem("routePaths"))
                              .length ===
                            ind + 1
                              ? null
                              : ">"}
                          </p>
                        );
                      }
                    }
                  )}
                </div>
              ) : null}
            </div>

            {latinoArtist === null ? (
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <img className="mb-3" alt="loading" src={loading} />
              </div>
            ) : latinoArtist ? (
              <>
                <h2 className="newsh2 h">LATINO/LATINA ARTISTS</h2>
                <div
                  id="w-node-_4a165d69-02be-f2c1-10f5-69fa49464043-576fcec6"
                  className="_4cols-v2"
                >
                  {latinoArtist.map((val) =>
                    val.ImageData.length > 0 ? (
                      <>
                        <Link
                          id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                          data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                          to={"/" + val.artistData.fullName}
                          className="artistcard  w-inline-block"
                        >
                          <img
                            src={String(val.ImageData[0].subImage[0].path)}
                            loading="lazy"
                            alt=""
                            className="image"
                          />
                          <div className="artistnamediv">
                            <div className="artistnametext-v3">
                              {val.artistData.firstname}{" "}
                              {val.artistData.lastname}
                            </div>
                          </div>
                        </Link>
                      </>
                    ) : null
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default LatinoArtist;
