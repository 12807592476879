import React from "react";
import { useState } from "react";
import loading from "../../../assets/loading.gif";
import { allCityGetter, allStateGetter } from "../../../redux/StateCity";

function AddNewAgent(props) {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [occupation, setOccupation] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <>
      <label className="col-md-6">
        <div>First Name</div>
        <input
          className="textField"
          value={firstname}
          onChange={(e) => {
            setFirstname(e.target.value);
          }}
        />
      </label>
      <label className="col-md-6">
        <div>Last Name</div>
        <input
          className="textField"
          value={lastname}
          onChange={(e) => {
            setLastname(e.target.value);
          }}
        />
      </label>
      {/* <label className="col-md-6">
        <div>Company</div>
        <input
          className="textField"
          value={company}
          onChange={(e) => {
            setCompany(e.target.value);
          }}
        />
      </label>
      <label className="col-md-6">
        <div>Occupation</div>
        <input
          className="textField"
          value={occupation}
          onChange={(e) => {
            setOccupation(e.target.value);
          }}
        />
      </label> */}
      <label className="col-md-6">
        <div>Email</div>
        <input
          type="email"
          className="textField"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </label>
      <label className="col-md-6">
        <div>Password</div>
        <input
          type="textField"
          className="textField"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </label>

      <div className="col-12 d-flex justify-content-end">
        {props.holder ? (
          <img
            className="mt-1"
            alt="loading"
            src={loading}
            style={{ width: "30px" }}
          />
        ) : (
          <button
            className="mx-1 myBtn active sm"
            onClick={(e) => {
              props.addAgentUser(e, {
                firstname: firstname,
                lastname: lastname,
                // occupation: occupation,
                // company: company,

                email: email,
                raw_password: password,
              });
            }}
          >
            ADD
          </button>
        )}
      </div>
    </>
  );
}

export default AddNewAgent;
