import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateMessage, updateOpen } from "../../redux/message";

import { getVimeoIDfromURL, getYoutubeIDfromURL } from "../../UserServices/Services";
import { artistMotionGIFUpload, artistMotionVideoUpload } from "../../AxiosFunctions/Axiosfunctionality";

import CrossButton from "../../assets/svgs/crossButton.svg";
import PlusIcon from "../../assets/svgs/plusIcon.svg";
import loadingUpdate from "../../assets/loading_trasnparent.gif";
import MyPopup from "../../components/myPopup/myPopup";

const MotionsUpload = ({history,artistDetails,currentMotion}) => {

    const dispatch = useDispatch();
    const [videoUrl, setVideoUrl] = useState({
        videoKey: "",
        videoValue: "",
        videoID: "",
    });
    const [error, setError] = useState({ videoError: false });
    const [typeOfUpload, setTypeOfUpload] = useState("");
    const [mainimageUrl, setMainImageUrl] = useState(null);
    const [mainimageUpload, setMainImageUpload] = useState(null);
    const [title, setTitle] = useState("");
    const [loader, setLoader] = useState(false);
    const [isPopupShow, setIsPopupShow] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const videoUrlOptions = [
        { value: "YouTube", label: "YouTube" },
        // ...other options
    ];

    useEffect(() => {
        if(currentMotion){
            setTitle(currentMotion.motionTitle)
            currentMotion.motionImageUrl && setMainImageUrl(currentMotion.motionImageUrl);
            currentMotion.motionImageUrl && setTypeOfUpload("gif");
            currentMotion.motionVideoUrl && setVideoUrl(currentMotion.motionVideoUrl);
            currentMotion.motionVideoUrl && setTypeOfUpload("video");
        }
    }, [currentMotion])

    const handleVideoUrlChange = (event) => {
        setVideoUrl((prev) => {
          return { ...prev, [event.target.name]: event.target.value };
        });
    };

    const handleTypeOfUpload = (event)=>{
        if(event.target.value === "video"){
            setMainImageUpload(null);
            setMainImageUrl(null);
            setTitle("");
        }
        
        if(event.target.value === "gif"){
            setVideoUrl({
                videoKey: "",
                videoValue: "",
                videoID: "",
            });
            setTitle("");
        }
        
        setTypeOfUpload(event.target.value);
    }

    const handleMainFileChangeImage = (event) => {
        if (event.target.files && event.target.files[0]) {
          setMainImageUrl(null);
          setMainImageUpload(event.target.files[0]);
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            mainImage: undefined,
          }));
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        setTitle(value);

        let fieldError = "";
        if (name === "title" && !value.trim()) {
            fieldError = "Title is required";
        }

        // Update validation errors state
        if (fieldError) {
            setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: fieldError,
            }));
        } else {
            setValidationErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[name]; // Remove the error entry if validation passes
            return updatedErrors;
            });
        }
    };

    const handleVideoSubmit = async(event)=>{
        event.preventDefault();
        setLoader(true);

        let videoIDMessage;
        if (videoUrl.videoKey === "Vimeo") {
        videoIDMessage = getVimeoIDfromURL(videoUrl.videoValue);
        } else if (videoUrl.videoKey === "YouTube") {
        videoIDMessage = getYoutubeIDfromURL(videoUrl.videoValue);
        }
        if (videoIDMessage === "Invalid URL") {
        setError((prev) => {
            return { ...prev, videoError: true };
        });

        setLoader(false);
        return;
        } else {
        setError((prev) => {
            return { ...prev, videoError: false };
        });
        }
        let videoID;
        if (videoUrl.videoKey === "Vimeo") {
        videoID = "https://player.vimeo.com/video/" + videoIDMessage;
        } else if (videoUrl.videoKey === "YouTube") {
        videoID = "https://www.youtube.com/embed/" + videoIDMessage;
        }

        // Prepare the body of the request
        const formData = new FormData();
        formData.append("artistId", artistDetails?._id);
        formData.append("updateType", "motion");
        formData.append("motionTitle",title);
        formData.append( "motionVideoUrl", JSON.stringify({ ...videoUrl, videoID: videoID || "" }) ); // Assuming socialMedia is an object
        
        try {
            await artistMotionVideoUpload(formData);
            setIsPopupShow(true);
            setLoader(false);

        } catch (error) {
            dispatch(updateOpen(true));
            dispatch(updateMessage(error.message));
            setLoader(false);
        }
    }

    const validateForm = () => {
        let errors = {};
        if (!title.trim()) errors.title = "Title is required";
        if (!mainimageUrl && !mainimageUpload)
          errors.mainImage = "Main image is required";
        setValidationErrors(errors);
    
        // Return true if no errors, false otherwise
        return Object.keys(errors).length === 0;
    };

    const handleGIFSubmit = async(event)=>{
        event.preventDefault();
        setLoader(true);


        if (!validateForm()) {
            setLoader(false);
            return;
        }

        const formData = new FormData();
        formData.append("artistId", artistDetails?._id);
        formData.append("updateType", "motion");
        mainimageUpload && formData.append("motionImageUpload", mainimageUpload); // Append the main image
        mainimageUrl && formData.append("motionImageUrl", mainimageUrl); // Append the main image
        formData.append("motionTitle",title);

        try {
            await artistMotionGIFUpload(formData);
            setIsPopupShow(true);
            setLoader(false);

        } catch (error) {
            dispatch(updateOpen(true));
            dispatch(updateMessage(error.message));
            setLoader(false);
        }
    }

    return (
        <>
        <div className="bioArtistContainer" style={{ paddingLeft: "100px" }}>
            <h4 style={{ marginBottom: "20px", fontSize: "1.2rem"}}>
                MOTION 
            </h4>
            <select className="textField" name="typeOfUpload" value={typeOfUpload} onChange={(e)=>handleTypeOfUpload(e)}>
                <option value = "" >Select</option>
                <option value = "gif" >GIF</option>
                <option value = "video">VIDEO</option>
            </select>
            {
                typeOfUpload === "video" &&
                    <form onSubmit={handleVideoSubmit}>
                        <div className="mb-4">
                            <label htmlFor="videoKey" className="labelsBio" style={{marginBottom:"20px"}}>Video</label>
                            <div className="inputContainer">
                                <label htmlFor="pw_title" className="labelsBio">
                                Title
                                </label>
                                <div className="socialMediaContainerBio">
                                <input
                                    type="text"
                                    placeholder="Title"
                                    value={title}
                                    name="title"
                                    className="textField"
                                    onChange={handleChange}
                                />
                                </div>
                                {validationErrors.title && (
                                <p style={{ color: "red" }}>{validationErrors.title}</p>
                                )}
                            </div>
                            <div className="socialMediaContainerBio">
                                <select
                                    className="textField"
                                    name="videoKey"
                                    value={videoUrl.videoKey}
                                    onChange={(e) => handleVideoUrlChange(e)}
                                    style={{width:"20%"}}
                                >
                                    <option value="">Select</option>
                                    {videoUrlOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                    ))}
                                </select>

                                <input
                                    type="text"
                                    name="videoValue"
                                    placeholder="Link"
                                    value={videoUrl.videoValue}
                                    className="textField socialMediaWebLink"
                                    onChange={(e) => handleVideoUrlChange(e)}
                                />
                            </div>
                            <div className="d-flex mt-4">
                                {loader ? (
                                <img
                                    alt="loading"
                                    src={loadingUpdate}
                                    style={{ width: "40px" }}
                                />
                                ) : (
                                <button className="mx-1 myBtn active sm">SUBMIT</button>
                                )}
                            </div>
                        {error.videoError && <h4 style={{ color: "red" }}>Invalid Url</h4>}
                        </div>
                    </form>
            }
            {
                typeOfUpload === "gif" &&
                <form onSubmit={handleGIFSubmit}>
                    <label htmlFor="pw_cover" className="labelsBio">
                    GIF
                    </label>
                    {mainimageUrl && mainimageUrl !== "null" ? (
                    <div className="imageView" style={{ width: "fit-content" }}>
                        <img
                        className="CrossButton"
                        alt="CrossButton"
                        src={CrossButton}
                        onClick={() => {
                            setMainImageUpload(null);
                            setMainImageUrl(null);
                        }}
                        style={{backgroundColor:"gray"}}
                        />
                        <img className="imageUploaded" alt="PlusIcon" src={mainimageUrl} />
                    </div>
                    ) : mainimageUpload ? (
                    <div
                        className="imageView"
                        style={{ width: "fit-content", width: "15%" }}
                    >
                        <img
                        className="CrossButton"
                        alt="CrossButton"
                        src={CrossButton}
                        onClick={() => {
                            setMainImageUpload(null);
                        }}
                        />
                        <img
                        className="imageUploaded"
                        alt="PlusIcon"
                        src={URL.createObjectURL(mainimageUpload)}
                        />
                    </div>
                    ) : (
                    <label className="uploadArtistImage">
                        <img alt="PlusIcon" src={PlusIcon} />
                        <h5>UPLOAD IMAGE</h5>
                        <input
                        hidden
                        type="file"
                        onChange={handleMainFileChangeImage}
                        accept="image/gif"
                        />
                    </label>
                    )}
                    <div className="inputContainer" style={{marginTop:"30px"}}>
                        <label htmlFor="pw_title" className="labelsBio">
                        Title
                        </label>
                        <div className="socialMediaContainerBio">
                        <input
                            type="text"
                            placeholder="Title"
                            value={title}
                            name="title"
                            className="textField"
                            onChange={handleChange}
                        />
                        </div>
                        {validationErrors.title && (
                        <p style={{ color: "red" }}>{validationErrors.title}</p>
                        )}
                    </div>
                    {validationErrors.mainImage && (
                    <p style={{ color: "red" }}>{validationErrors.mainImage}</p>
                    )}
                    <div className="d-flex mt-4">
                        {loader ? (
                        <img
                            alt="loading"
                            src={loadingUpdate}
                            style={{ width: "40px" }}
                        />
                        ) : (
                        <button className="mx-1 myBtn active sm">SUBMIT</button>
                        )}
                    </div>
                </form>
            }
            {isPopupShow ? (
            <MyPopup
            BackClose
            CloseBtn
            onClose={() => {
                setIsPopupShow(false);
                history.push("/artist/motion");
            }}
            >
            <div className="mx-5 my-4 popUpfontsize">
                Your Data has been received. <br />
                The Shannon Associates team will promptly review and publish it.{" "}
                <br />
                Feel free to reach out to Lori at lori@shannonassociates.com
                with any questions or concerns.
            </div>
            </MyPopup>
        ) : null}
        </div>
        </>
    )
}

export default MotionsUpload