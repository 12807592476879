import React from "react";

import "./admin.css";

function Dashboard(props) {
  return (
    <div className="px-xl-5 mx-xl-5">
      <div className="px-lg-3 py-4 mt-3 d-flex justify-content-between">
        <h4>DASHBOARD</h4>
      </div>
      <div className={"row p-lg-3"}>
        <div className="col-md-6 mb-3">
          <div
            onClick={() => props.history.push("/artist/image_upload")}
            className="dash_card color3 p-5 justify-content-center"
          >
            <h5>IMAGE UPLOAD</h5>
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div
            onClick={() => props.history.push("/artist/bio")}
            className="dash_card color3 p-5 justify-content-center"
          >
            <h5>BIO</h5>
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div
            onClick={() => props.history.push("/artist/publish_works")}
            className="dash_card color3 p-5 justify-content-center"
          >
            <h5>PUBLISHED WORKS</h5>
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div
            onClick={() => props.history.push("/artist/personal_work")}
            className="dash_card color3 p-5 justify-content-center"
          >
            <h5>PERSONAL WORK</h5>
          </div>
        </div>
        <div className="col-md-6 mb-3">

          <div
            onClick={() => props.history.push("/artist/properties")}
            className="dash_card color3 p-5 justify-content-center"
          >
            <h5>PROPERTIES</h5>
          </div>
        </div>
        <div className="col-md-6 mb-3">

          <div
            onClick={() => props.history.push("/artist/motion")}
            className="dash_card color3 p-5 justify-content-center">
            <h5>MOTION</h5>
          </div>
        </div>

        <div className="col-md-6 mb-3">
          <div
            onClick={() => props.history.push("/artist/awards_press")}
            className="dash_card color3 p-5 justify-content-center">
            <h5>AWARDS / PRESS</h5>
          </div>
        </div>
  
      </div>
    </div>
  );
}

export default Dashboard;
