import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import loading from "../../assets/loading.gif";

import { useDispatch, useSelector } from "react-redux";
import { ArtistImageSliceData } from "../../redux/artistImageDataSlice";

function IllustrationArtists(props) {
  const [tempArtist, setTempArtist] = useState([]);
  const [filterCond, setFilterCond] = useState(true);
  const [filterHighlighted, setFilterHighlighted] = useState(null);
  const dispatch = useDispatch();
  const { artistImageDataSlice } = useSelector((state) => state);
  const history = useHistory();
  const [windowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const filterChange = (filter) => {
    if (filter === "A-Z") {
      let temp = [];
      setFilterCond(false);
      let tempImage = [...artistImageDataSlice.artistImages];
      temp = tempImage.sort((a, b) => {
        if (
          a.artistId.lastname
            .normalize()
            .localeCompare(b.artistId.lastname.normalize()) === 0
        ) {
          return a.artistId.firstname
            .normalize()
            .localeCompare(b.artistId.firstname.normalize());
        } else {
          return a.artistId.lastname
            .normalize()
            .localeCompare(b.artistId.lastname.normalize());
        }
      });
      setFilterHighlighted(2);
      setTempArtist(temp);
    } else {
      setFilterHighlighted(1);
      setFilterCond(true);
    }
  };

  useEffect(() => {
    if (artistImageDataSlice.artistImages.length === 0) {
      dispatch(ArtistImageSliceData());
    }
  }, [artistImageDataSlice.artistImages.length, dispatch]);

  return (
    <>
      <div class="sortingcont right pt-0  me-0 ">
        <div className="d-flex  ">
          <button
            class={
              filterHighlighted === 1
                ? "filter-button sort-active w-inline-block  mt-0"
                : filterHighlighted === 2
                ? "filter-button w-inline-block  mt-0"
                : "filter-button sort-active w-inline-block  mt-0"
            }
            style={{ marginLeft: "0px" }}
            onClick={() => filterChange("Default")}
          >
            <div>FEATURED</div>
          </button>
          <button
            class={
              filterHighlighted === 2
                ? "filter-button sort-active mt-0 me-0"
                : "filter-button mt-0 me-0"
            }
            onClick={() => filterChange("A-Z")}
          >
            <div>ALPHABETICAL A-Z</div>
          </button>
        </div>
      </div>
      <div className="_2cols2_" style={{ clear: "both", marginBottom: "1vh" }}>
        {props.children}
        <div
          id="w-node-_4a165d69-02be-f2c1-10f5-69fa4946403e-576fcec6"
          className="divisionscolumn"
        >
          <div
            id="w-node-_4a165d69-02be-f2c1-10f5-69fa4946403f-576fcec6"
            className="divisioncontainer"
          >
            <div>
              {JSON.parse(localStorage.getItem("routePaths")) ? (
                <div
                  className={"d-flex"}
                  style={{ marginBottom: "10px", width: "98.4%" }}
                >
                  {JSON.parse(localStorage.getItem("routePaths")).map(
                    (obj, ind) => (
                      <p
                        className={
                          JSON.parse(localStorage.getItem("routePaths"))
                            .length ===
                          ind + 1
                            ? "breadCrumbs"
                            : "breadCrumbsActive"
                        }
                        onClick={
                          JSON.parse(localStorage.getItem("routePaths"))
                            .length ===
                          ind + 1
                            ? () => {}
                            : () => history.push(obj.link)
                        }
                        style={ind === 0 ? {} : { marginLeft: "5px" }}
                      >
                        {obj.val}{" "}
                        {JSON.parse(localStorage.getItem("routePaths"))
                          .length ===
                        ind + 1
                          ? null
                          : ">"}
                      </p>
                    )
                  )}
                </div>
              ) : null}
            </div>

            <h2 className="newsh2 h">ILLUSTRATION ARTISTS</h2>
            <div
              id="w-node-_4a165d69-02be-f2c1-10f5-69fa49464043-576fcec6"
              className="_4cols-v2"
            >
              {artistImageDataSlice.loading ? (
                <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                  <img className="mb-3" alt="loading" src={loading} />
                </div>
              ) : artistImageDataSlice.artistImages && filterCond ? (
                artistImageDataSlice.artistImages.map((val, ind) => (
                  <>
                    <Link
                      id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                      data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                      to={val.artistId.fullName}
                      className="artistcard  w-inline-block"
                    >
                      <img
                        src={String(val.mainImage[0].subImage[0].path)}
                        loading="lazy"
                        alt=""
                        className="image"
                      />
                      {/* <p className="card_img_text2"> 
                {val.artistId.firstname} {val.artistId.lastname} </p> */}
                      {/* <div className="artistnamediv">
                  <div className="artistnametext">
                    {val.artistId.firstname} {val.artistId.lastname} 
                  </div>
                </div> */}
                      <div className="artistnamediv">
                        <div className="artistnametext-v3">
                          {val.artistId.firstname} {val.artistId.lastname}
                        </div>
                      </div>
                    </Link>
                  </>
                ))
              ) : (
                tempArtist.map((val, ind) => (
                  <>
                    <Link
                      id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                      data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                      to={val.artistId.fullName}
                      className="artistcard  w-inline-block"
                    >
                      <img
                        src={String(val.mainImage[0].subImage[0].path)}
                        loading="lazy"
                        alt=""
                        className="image"
                      />
                      {/* <p className="card_img_text2 pt-2">
                {val.artistId.firstname} {val.artistId.lastname} </p> */}
                      {/* <div className="artistnamediv">
                  <div className="artistnametext">
                    {val.artistId.firstname} {val.artistId.lastname} 
                  </div>
                </div> */}
                      <div className="artistnamediv">
                        <div className="artistnametext-v3">
                          {val.artistId.firstname} {val.artistId.lastname}
                        </div>
                      </div>
                    </Link>
                  </>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IllustrationArtists;
