import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import CrossButton from "../../assets/svgs/crossButton.svg";
import PlusIcon from "../../assets/svgs/plusIcon.svg";
import loadingUpdate from "../../assets/loading_trasnparent.gif";
import BackIconForm from "../../assets/svgs/backIconForm.svg";
import { updateMessage, updateOpen } from "../../redux/message";
import { findSingleContact } from "../../UserServices/Services";
import { artistPersonalWorkApprove } from "../../AxiosFunctions/Axiosfunctionality";

const PersonalWorkApprove = ({setFormNo,artistUpdateId,artistUpdates,populateArtistUpdates}) => {

    const dispatch = useDispatch();   
    const [artistUserAllDetails, setArtistUserAllDetails] = useState(null);
    const [mainimageUpload, setMainImageUpload] = useState(null);
    const [mainimageUrl, setMainImageUrl] = useState(null);
    const [secondaryImages, setSecondaryImages] = useState([]);
    const [secondaryImagesUrl, setSecondaryImagesUrl] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [title, setTitle] = useState("");
    const [displayImages, setDisplayImages] = useState([]);
    const [loader, setLoader] = useState(false);
    const [personalWorkId, setPersonalWorkId] = useState();

    useEffect(() => {
        if (artistUpdateId) {
          let singleUpdate = findSingleContact(artistUpdates, artistUpdateId);
          setArtistUserAllDetails(singleUpdate.artistId);
          setTitle(singleUpdate.personalWorkTitle || "");
          setMainImageUrl(singleUpdate.personalWorkMainImage || null);
          if (singleUpdate.personalWorkSecondaryImages) {
            // Set the main image URL
    
            // Set the secondary images URL, exclude the first element
            setSecondaryImagesUrl(singleUpdate.personalWorkSecondaryImages || []);
            setDisplayImages(singleUpdate.personalWorkSecondaryImages || []);
          }
          setPersonalWorkId(singleUpdate.personalWorkId || singleUpdate._id);
        }else {
          setTitle("");
          setDisplayImages([]);
          setMainImageUrl(null);
          setSecondaryImagesUrl([]);
          setMainImageUpload(null);
          setSecondaryImages([]);
          setPersonalWorkId(null);
        }
      }, [artistUpdateId]);

    useEffect(() => {
        // Combine initial URLs and new file objects for display
        const updatedDisplayImages = [
            ...secondaryImagesUrl,
            ...secondaryImages.map((file) => URL.createObjectURL(file)),
        ];
        setDisplayImages(updatedDisplayImages);
    }, [secondaryImages, secondaryImagesUrl]);
    
    const handleMainFileChangeImage = (event) => {
        if (event.target.files && event.target.files[0]) {
          setMainImageUpload(event.target.files[0]);
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            mainImage: undefined,
          }));
        }
    };

    const handleOtherFileChangeImage = (event) => {
        const images = [];
    
        if (
          event.target.files &&
          displayImages.length + event.target.files.length <= 5
        ) {
          for (let i = 0; i < event.target.files.length; i++) {
            images.push(event.target.files[i]);
          }
          setSecondaryImages([...secondaryImages, ...images]);
        } else {
          dispatch(updateOpen(true));
          dispatch(updateMessage("Max number of images is 5"));
        }
    };

    const handleDeleteImage = (index) => {
        // Adjust logic if needed to handle deleting both types of images: URLs and file objects
        const isUrlImage = index < secondaryImagesUrl.length;
        if (isUrlImage) {
          // Handle deletion of already uploaded images by URL
          const updatedUrls = displayImages.filter((_, idx) => idx !== index);
          setDisplayImages(updatedUrls);
          const updatedUrlsSec = secondaryImagesUrl.filter(
            (_, idx) => idx !== index
          );
          setSecondaryImagesUrl(updatedUrlsSec);
          // Additional logic to update server or state about deleted image
        } else {
          // Handle deletion of newly selected images
          const newFileIndex = index - secondaryImagesUrl.length;
          const updatedFiles = secondaryImages.filter(
            (_, idx) => idx !== newFileIndex
          );
          setSecondaryImages(updatedFiles);
        }
    };

    const handleChange = (event)=>{
        const { value } = event.target;
        setTitle(value);

    }

    const validateForm = () => {
        let errors = {};
        if (!title.trim()) errors.title = "Title is required";
        if (!mainimageUrl && !mainimageUpload)
          errors.mainImage = "Main image is required";
        setValidationErrors(errors);
    
        // Return true if no errors, false otherwise
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
        setLoader(true);

        if (!validateForm()) {
        setLoader(false);
        return;
        }

        const formData = new FormData();
        formData.append("_id", artistUpdateId);
        formData.append("personalWorkId", personalWorkId);
        formData.append("artistId", artistUserAllDetails?._id);
        formData.append("updateType", "personal work");
        mainimageUpload && formData.append("MainImageUpload", mainimageUpload); // Append the main image
        mainimageUrl && formData.append("personalWorkMainImage", mainimageUrl); // Append the main image
        secondaryImages.forEach((file, index) => {
        formData.append(`SecondaryImagesUpload`, file); // Append secondary images
        });
        formData.append(`personalWorkSecondaryImages`, JSON.stringify(secondaryImagesUrl)); // Append secondary images
        formData.append("personalWorkTitle", title);

        try {
            await artistPersonalWorkApprove(formData);
            dispatch(updateOpen(true));
            dispatch(updateMessage("Saved Successfully"));
            setLoader(false);
            await populateArtistUpdates();
            setFormNo(0);
        } catch (err) {
            dispatch(updateOpen(true));
        
            dispatch(updateMessage(err.message));
            setLoader(false);
        }
    }

    return (
        <div className="bioArtistContainer" style={{ paddingLeft: "100px" }}>

            <h4 style={{ marginBottom: "20px", fontSize: "1.2rem"}}>
                PERSONAL WORK
            </h4>
            <form onSubmit={handleSubmit}>
                <label htmlFor="pw_cover" className="labelsBio">
                    COVER / MAIN IMAGE
                </label>
                {mainimageUrl && mainimageUrl !== "null" ? (
                    <div className="imageView" style={{ width: "fit-content"}}>
                        <img
                        className="CrossButton"
                        alt="CrossButton"
                        src={CrossButton}
                        onClick={() => {
                            setMainImageUpload(null);
                            setMainImageUrl(null);
                        }}
                        style={{backgroundColor:"gray"}}
                        />
                        <img className="imageUploaded" alt="PlusIcon" src={mainimageUrl} />
                    </div>
                    ) : mainimageUpload ? (
                    <div className="imageView" style={{ width: "fit-content"}}>
                        <img
                        className="CrossButton"
                        alt="CrossButton"
                        src={CrossButton}
                        onClick={() => {
                            setMainImageUpload(null);
                        }}
                        style={{backgroundColor:"gray"}}
                        />
                        <img
                        className="imageUploaded"
                        alt="PlusIcon"
                        src={URL.createObjectURL(mainimageUpload)}
                        />
                    </div>
                    ) : (
                    <label className="uploadArtistImage">
                        <img alt="PlusIcon" src={PlusIcon} />
                        <h5>UPLOAD IMAGE</h5>
                        <input
                        hidden
                        type="file"
                        onChange={handleMainFileChangeImage}
                        accept="image/*"
                        />
                    </label>
                )}
                {validationErrors.mainImage && (
                <p style={{ color: "red" }}>{validationErrors.mainImage}</p>
                )}
                <div className="publishWorkInputContainer">
                    <div className="inputContainer">
                        <label htmlFor="pw_title" className="labelsBio">
                        Title
                        </label>
                        <div className="socialMediaContainerBio">
                        <input
                            type="text"
                            placeholder="Title"
                            value={title}
                            name="title"
                            className="textField"
                            onChange={handleChange}
                        />
                        </div>
                        {validationErrors.title && (
                        <p style={{ color: "red" }}>{validationErrors.title}</p>
                        )}
                    </div>
                </div>
                <div className="inputContainer" style={{ width: "100%" }}>
                    <label htmlFor="pw_cover" className="labelsBio">
                    SUPPORTING IMAGES
                    </label>
                    <p
                    style={{
                        marginBottom: "30px",
                        fontWeight: "300",
                        lineHeight: "1.5",
                        fontSize: "0.8rem",
                    }}
                    >
                    You can upload up to 5 supporting images (Please adhere to your
                    contract with your client). They can be interiors of a book (full
                    color/ black and white/ sequential, etc.), or additional images
                    from the campaign/ article.
                    </p>
                    <div
                    style={{
                        display: "flex",
                        flexWrap: "wrap",

                        alignItems: "center",
                    }}
                    >
                    {displayImages.length < 5 && (
                        <label
                        className="uploadArtistImage"
                        style={{ marginRight: "5px" }}
                        >
                        <img alt="PlusIcon" src={PlusIcon} />
                        <h5>UPLOAD IMAGE</h5>
                        <p
                            style={{
                            marginBottom: "30px",
                            fontWeight: "300",
                            lineHeight: "1.5",
                            fontSize: "0.8rem",
                            }}
                        >
                            Max. 5 Images
                        </p>
                        <input
                            hidden
                            multiple
                            type="file"
                            onChange={handleOtherFileChangeImage}
                            accept="image/*"
                        />
                        </label>
                    )}

                    {displayImages.map((img, index) => (
                        <div
                        key={index}
                        className="imageView"
                        style={{
                            border: "1px black solid",
                            margin: " 4px",
                            height: "100%",
                        }}
                        >
                        <img
                            className="CrossButton"
                            alt="CrossButton"
                            src={CrossButton}
                            onClick={() => handleDeleteImage(index)}
                            style={{backgroundColor:"gray"}}
                        />
                        <img
                            className="imageUploaded"
                            alt={`Uploaded content ${index}`}
                            src={img}
                        />
                        </div>
                    ))}
                    </div>

                </div>
                <div class="col-12 d-flex justify-content-end">
                    {loader ? (
                        <img alt="loading" src={loadingUpdate} style={{ width: "40px" }} />
                    ) : (
                        <div>
                        <img
                            onClick={() => setFormNo(0)}
                            alt="back"
                            src={BackIconForm}
                            style={{ width: "40px", cursor: "pointer" }}
                        />
                        <button type="submit" class="mx-1 ml-4 myBtn active sm">
                            UPDATE
                        </button>
                        </div>
                    )}
                </div>
            </form>
        </div>
    )
}

export default PersonalWorkApprove