import AddImage from "../../assets/svgs/addImage.svg";

const AwardsPress = ({history,artistDetails,setCurrentAward}) => {

    const handleCurrentAward = (selectedAward)=>{
      setCurrentAward(selectedAward);
      history.push("/artist/awards_press_upload");
    }

    return (
        <div className="px-1 px-md-5 artist mb-5">
          <h4> AWARDS / PRESS </h4>
          <div className="_4cols-v2">
            <label
              className="artistcardAdmin w-inline-block addImageBtn"
              onClick={()=>handleCurrentAward(undefined)}
            >
              <img alt="" src={AddImage} className="addImage" />
            </label>
            {artistDetails?.awardPress
              ? [...artistDetails.awardPress]
                  .reverse()
                  .map(awardPress => (
                    <div
                      className="artistcardAdmin w-inline-block"
                      style={{ cursor: "pointer" }}
                      key={awardPress.awardPressId}
                      onClick={()=>handleCurrentAward(awardPress)}
                    >
                      <img
                        src={awardPress.awardMainImage}
                        alt={awardPress.awardTitle}
                        className="image"
                      />
                    </div>
                  ))
              : null}
          </div>
        </div>
      );
}

export default AwardsPress