import { Fragment, useEffect, useState } from "react";
import { getAllProperties } from "../../AxiosFunctions/Axiosfunctionality";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AccessDenied from "./accessDenied";

const Properties = (props) => {
  const [filterHighlighted, setFilterHighlighted] = useState(null);
  const [data, setData] = useState(null);
  const [dataOriginal, setDataOriginal] = useState(null);
  const [propertiesByCategories, setPropertiesByCategories] = useState();
  const [windowSize] = useState(getWindowSize());
  const history = useHistory();

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const filterChange = (filter) => {
    let tempData = [...data];
    let filter_highli = null;
    setDataOriginal([...data]);
    if (filter === "A-Z") {
      filter_highli = 2;
      tempData = tempData.sort((a, b) =>
        a.artistId.lastname
          .normalize()
          .localeCompare(b.artistId.lastname.normalize())
      );
    } else if (dataOriginal) {
      filter_highli = 1;
      tempData = [...dataOriginal];
      // tempData = dataOriginal;
    }

    setData(tempData);
    setFilterHighlighted(filter_highli);
  };

  useEffect(() => {
    getAllProperties().then((res) => {
      setPropertiesByCategories(res);
    });
  }, []);

  return (
    <>
      <div class="sortingcont right pt-0  me-0 ">
        <div className="d-flex  ">
          <a
            class={
              filterHighlighted == 1
                ? "filter-button sort-active w-inline-block  mt-0"
                : filterHighlighted == 2
                ? "filter-button w-inline-block  mt-0"
                : "filter-button sort-active w-inline-block  mt-0"
            }
            style={{ marginLeft: "0px" }}
            onClick={() => filterChange("Default")}
          >
            <div>FEATURED</div>
          </a>
          <a
            class={
              filterHighlighted == 2
                ? "filter-button sort-active mt-0 me-0"
                : "filter-button mt-0 me-0"
            }
            onClick={() => filterChange("A-Z")}
          >
            <div>ALPHABETICAL A-Z</div>
          </a>
        </div>
      </div>

      <div className="_2cols2_" style={{ clear: "both" }}>
        {props.children}
        <div>
          {props.access ? (
            <>
              <div>
                {JSON.parse(localStorage.getItem("routePaths")) ? (
                  <div
                    className={"d-flex"}
                    style={{ marginBottom: "10px", width: "98.4%" }}
                  >
                    {JSON.parse(localStorage.getItem("routePaths")).map(
                      (obj, ind) => (
                        <p
                          className={
                            JSON.parse(localStorage.getItem("routePaths"))
                              .length ===
                            ind + 1
                              ? "breadCrumbs"
                              : "breadCrumbsActive"
                          }
                          onClick={
                            JSON.parse(localStorage.getItem("routePaths"))
                              .length ===
                            ind + 1
                              ? () => {}
                              : () => history.push(obj.link)
                          }
                          style={ind === 0 ? {} : { marginLeft: "5px" }}
                        >
                          {obj.val}{" "}
                          {JSON.parse(localStorage.getItem("routePaths"))
                            .length ===
                          ind + 1
                            ? null
                            : ">"}
                        </p>
                      )
                    )}
                  </div>
                ) : null}
              </div>
              <h2 className="newsh2 h">PROPERTIES</h2>
              <h3 style={{ marginBottom: "10px", color: "#ce651e" }}>
                Welcome to the Properties Section of our website!
              </h3>
              <p
                style={{
                  marginBottom: "30px",
                  paddingLeft: "20px",
                  fontWeight: "300",
                  lineHeight: "1.5",
                  fontSize: "1rem",
                }}
              >
                We are so happy to present the following original story ideas
                for your review.
                <br />
                <br />
                If you have any questions or would like to know more about a
                project, please contact Shannon Associates at 212.333.2551 or
                email justin@shannonassociates.com. <br />
                If you would like to request an exclusive on a story to present
                to your acquisitions team, let us know by selecting the
                “interested” button inside that particular title. <br />
                We hope you see potential and enjoy these story ideas as much as
                we do.
              </p>
              <p
                style={{
                  fontWeight: "500",
                  lineHeight: "1.5",
                  fontSize: "1rem",
                }}
              >
                Currently Available :
              </p>
              {
                /* Version 1 of properties divided by categories
                                propertiesByCategories && Object.keys(propertiesByCategories).map((key_,i)=>{
                                return (
                                <Fragment key={i}>
                                    <p style={{ marginBottom: "30px", fontWeight: "300", lineHeight: "1.5", fontSize: "0.8rem",color:"#CDCDCD"}}>
                                    Please contact Shannon Associates at 212.333.2551 or email justin@shannonassociates.com, if you have any questions about a story. 
                                    The ideas displayed are the property of the artist. 
                                    All rights reserved.
                                    </p>
                                    <h2 className="newsh2 h">{key_ !== "" ? key_ : "Other"}</h2>
                                    <div id="w-node-_4a165d69-02be-f2c1-10f5-69fa49464043-576fcec6" className="_4cols-v2 mbSp">
                                    {
                                        propertiesByCategories[key_].map((artist,i)=>
                                            <Fragment key={i}>
                                                <Link
                                                id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                                                data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                                                to={artist.artistData.fullName}
                                                className="artistcard  w-inline-block"
                                                >
                                                <img
                                                    src={String(artist.ImageData[0]?.subImage[0]?.path || "")}
                                                    loading="lazy"
                                                    alt={artist.artistData.fullName}
                                                    className="image"
                                                />
                                                <div className="artistnamediv">
                                                    <div className="artistnametext-v3">
                                                    {artist.artistData.firstname}{" "}
                                                    {artist.artistData.lastname}
                                                    </div>
                                                </div>
                                                </Link>
                                            </Fragment>
                                        )
                                    }
                                    </div> 
                                </Fragment>
                                )

      history.push(`/${pages}/${searchValue}?image=${image}`);
                            }) */
                propertiesByCategories &&
                  Object.keys(propertiesByCategories).map((artistName, i) => (
                    <Fragment key={i}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "auto 1fr",
                          marginBottom: "20px",
                        }}
                      >
                        <h2 className="newsh2 h submenuItem">{artistName}</h2>
                        <div className="separatorSubCat"></div>
                      </div>
                      <div className="detail_card2 pwCategpryCont">
                        {propertiesByCategories[artistName].properties
                          .filter((property) => property.status === "1")
                          .map((property, i) => (
                            <Fragment key={i}>
                              <Link
                                id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                                data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                                to={`${propertiesByCategories[artistName].fullName}/properties?image=0&id=${property._id}`}
                                className="artistcard"
                                style={{ height: "auto" }}
                              >
                                <div key={i} style={{ cursor: "pointer" }}>
                                  <img
                                    src={property.propertyMainImage}
                                    alt={property.titleProperty}
                                  />
                                  <label className="pwLabel">
                                    {property.titleProperty}
                                  </label>
                                </div>
                              </Link>
                            </Fragment>
                          ))}
                      </div>
                    </Fragment>
                  ))
              }
            </>
          ) : (
            <AccessDenied />
          )}
        </div>
      </div>
    </>
  );
};

export default Properties;
