import React from "react";
import Table, {
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from "../../../components/table/table";
import loading from "../../../assets/loading.gif";
function ArtistUpdatesLists(props) {
  return (
    <div className="col">
      <Table height="calc(100vh - 350px)">
        <THead>
          <Th minWidth="120">Name</Th>
          <Th minWidth="120">Tab</Th>
          <Th minWidth="120">Request Date</Th>

          <Th width="110"></Th>
        </THead>
        <TBody>
          {props.artistUpdates.map((item, key) => (
            <Tr key={key}>
              <Td>{item.artistId.lastname + " " + item.artistId.firstname}</Td>
              <Td>{item.updateType.toUpperCase()}</Td>
              <Td>
                {new Date(item.createdAt).toLocaleString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </Td>
              <Td className="d-flex">
                <button
                  className="mx-1 myBtn"
                  type="text"
                  onClick={(e) => {
                    props.deleteArtistUpdate(e, item._id);
                  }}
                >
                  DELETE
                </button>
                {props.loading ? (
                  <img
                    className="mt-1"
                    alt="loading"
                    src={loading}
                    style={{ width: "30px" }}
                  />
                ) : (
                  <button
                    className="mx-1 myBtn active"
                    type="text"
                    style={{ width: 100 }}
                    onClick={() => {
                      if (item.updateType === "bio") {
                        props.setFormNo(1);
                        props.setArtistUpdateId(item._id);
                      }
                      if (item.updateType === "published works") {
                        props.setFormNo(2);
                        props.setArtistUpdateId(item._id);
                      }

                      if(item.updateType==="personal work"){
                        props.setFormNo(3);
                        props.setArtistUpdateId(item._id);
                      }
                      if (item.updateType === "properties") {
                        props.setFormNo(4);
                        props.setArtistUpdateId(item._id);
                      }
                      if (item.updateType === "motion") {
                        props.setFormNo(5);
                        props.setArtistUpdateId(item._id);
                      }
                      if (item.updateType === "award/press") {
                        props.setFormNo(6);
                        props.setArtistUpdateId(item._id);
                      }
                    }}
                  >
                    VIEW MORE
                  </button>
                )}
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    </div>
  );
}

export default ArtistUpdatesLists;
