import React, { useEffect, useState } from "react";

import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateAgentData } from "../../AxiosFunctions/Axiosfunctionality";
import Input from "../../components/input/input";
import { updateMessage, updateOpen } from "../../redux/message";
import "./admin.css";
import AgentsList from "./agentsPages/agentsList";
import Edit from "./agentsPages/edit";

function Agents(props) {
  const historyCurrent = useHistory();

  const dispatch = useDispatch();
  const [formNo2, setFormNo2] = useState(2);
  const [search, setSearch] = useState("");
  const [selectedAgent, setSelectedAgent] = useState({});
  const [selectedImages, setSelectedImages] = useState({});

  const [holder, setHolder] = useState(false);
  const [tempAgent, setTempAgent] = useState([]);

  useEffect(() => {
    if (historyCurrent.location.state) {
      checkCurrentUserLocalStorage();
      formChangeEvent(historyCurrent.location.state, true);
      return;
    }
    checkCurrentUserLocalStorage();
  }, []);

  const checkCurrentUserLocalStorage = () => {
    const curr = JSON.parse(localStorage.getItem("currentAgent"));
    if (curr) {
      setSelectedAgent(curr);
    }
  };

  const formChangeEvent = (data, state = false) => {
    props.setFormNo(1);
    if (state) {
      setFormNo2(2);
    } else {
      setFormNo2(2);
    }
    if (!data._id) {
      checkCurrentUserLocalStorage();
      return;
    }
    localStorage.setItem("currentAgent", JSON.stringify(data));
    setSelectedImages([]);

    setSelectedAgent(data);

    setSearch("");
  };

  const updateAgent = (e, data) => {
    setHolder(true);
    updateAgentData(data).then((res) => {
      setHolder(false);
      dispatch(updateOpen(true));
      dispatch(updateMessage(res));
      props.populateAgentUsers();
      props.setFormNo(0);
      setFormNo2(0);
    });
  };

  const changeAgentData = (e) => {
    setSearch(e.target.value);
    const searchvalue = e.target.value.toLowerCase();
    setTempAgent(
      props.agentUsers.filter(function (element) {
        return (
          element.firstname?.toLowerCase().includes(searchvalue) ||
          element.lastname?.toLowerCase().includes(searchvalue) ||
          (element.status === 1 && searchvalue.includes("active")) ||
          (element.status === 0 && searchvalue.includes("inactive"))
        );
      })
    );
  };

  return (
    <div className="px-xl-5 mx-xl-5">
      <div
        className={
          "mx-lg-5 px-lg-3 pb-4 ml-5 d-flex flex-column" +
          (props.formNo === 1 ? " align-items-center" : "")
        }
      >
        {props.formNo === 1 ? (
          <>
            <h4>
              {selectedAgent.firstname} {selectedAgent.lastname}
            </h4>
            <div className="col-6 p-0 subNavBar d-flex justify-content-between">
              <button
                onClick={() => setFormNo2(3)}
                className={"btn" + (formNo2 === 3 ? " active" : "")}
              >
                Edit
              </button>
            </div>
          </>
        ) : (
          <div className="mx-lg-5 px-lg-3 py-4 mt-3 ml-5 d-flex justify-content-between">
            <h4>MYSHANNON USERS</h4>
            <Input
              type="search"
              label="Search"
              value={search}
              onChange={(e) => {
                changeAgentData(e);
              }}
            />
          </div>
        )}
      </div>
      <div className="px-3 mx-5">
        {props.formNo === 1 ? (
          formNo2 === 3 ? (
            <Edit
              holder={holder}
              selectedAgent={selectedAgent}
              setFormNo={(e) => props.setFormNo(e)}
              setFormNo2={(e) => setFormNo2(e)}
              updateAgent={updateAgent}
            />
          ) : (
            <Edit
              holder={holder}
              selectedAgent={selectedAgent}
              setFormNo={(e) => props.setFormNo(e)}
              setFormNo2={(e) => setFormNo2(e)}
              updateAgent={updateAgent}
            />
          )
        ) : (
          <AgentsList
            search={search}
            tempAgent={tempAgent}
            holder={holder}
            agentUsers={props.agentUsers}
            deleteAgentUser={props.deleteAgentUser}
            formChangeEvent={(e) => formChangeEvent(e)}
            reorderAgentHandler={props.reorderAgentHandler}
          />
        )}
      </div>
    </div>
  );
}

export default Agents;
