import React, { useEffect, useState } from "react";

import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  artistDataId,
  updateArtistData,
} from "../../AxiosFunctions/Axiosfunctionality";
import Input from "../../components/input/input";
import { updateMessage, updateOpen } from "../../redux/message";
import "./admin.css";
import ArtistsList from "./artistsPages/artistsList";
import Edit from "./artistsPages/edit";
import ImgSubmission from "./artistsPages/imgSubmission";
import Portfolio from "./artistsPages/portfolio";
import BioUpdateByAdmin from "./bioUpdate";
import PWUpdate from "./pwUpdate";
import PersonalWorkUpdate from "./personalWorkUpdate";
import PropertiesUpdate from "./propertyUpdate";
import MotionUpdate from "./motionUpdate";
import AwardPressUpdate from "./awardPressUpdate";


function Artists(props) {
  const historyCurrent = useHistory();

  const dispatch = useDispatch();
  const [formNo2, setFormNo2] = useState(2);
  const [search, setSearch] = useState("");
  const [selectedArtist, setSelectedArtist] = useState({});
  const [selectedImages, setSelectedImages] = useState({});

  const [holder, setHolder] = useState(false);
  const [tempArtist, setTempArtist] = useState([]);

  const populateImageArtist = (data) => {
    let params = {
      artistId: data._id,
    };
    setHolder(true);
    artistDataId(params).then((res) => {
      if (res == "ERROR") {
        setSelectedImages([]);
      } else if (res.length > 0) {
        setSelectedImages(res[0]);
      }
      setHolder(false);
    });
  };

  useEffect(() => {
    if (historyCurrent.location.state) {
      checkCurrentUserLocalStorage();
      formChangeEvent(historyCurrent.location.state, true);
      return;
    }
    checkCurrentUserLocalStorage();
  }, []);

  const checkCurrentUserLocalStorage = () => {
    const curr = JSON.parse(localStorage.getItem("currentArtist"));
    if (curr) {
      populateImageArtist(curr);
      setSelectedArtist(curr);
    }
  };

  const formChangeEvent = (data, state = false) => {
    props.setFormNo(1);
    if (state) {
      setFormNo2(2);
    } else {
      setFormNo2(2);
    }
    if (!data._id) {
      checkCurrentUserLocalStorage();
      return;
    }
    localStorage.setItem("currentArtist", JSON.stringify(data));
    setSelectedImages([]);
    populateImageArtist(data);
    setSelectedArtist(data);

    setSearch("");
  };

  const updateArtist = (e, data) => {
    setHolder(true);
    updateArtistData(data).then((res) => {
      setHolder(false);
      dispatch(updateOpen(true));
      dispatch(updateMessage(res));
      props.populateArtistUsers();
      props.setFormNo(0);
      setFormNo2(0);
    });
  };
  const changeArtistData = (e) => {
    setSearch(e.target.value);
    const searchvalue = e.target.value.toLowerCase();
    setTempArtist(
      props.artistUsers.filter(function (element) {
        return (
          element.firstname.toLowerCase().includes(searchvalue) ||
          element.lastname.toLowerCase().includes(searchvalue) ||
          (element.status === 1 && searchvalue.includes("active")) ||
          (element.status === 0 && searchvalue.includes("inactive"))
        );
      })
    );
  };

  const updateSelectedImagesArray = (data) => {
    let temp = { ...selectedImages };
    temp.mainImage = data;
    setSelectedImages(temp);
  };

  return (
    <div className="px-xl-5 mx-xl-5">
      <div
        className={
          "mx-lg-5 px-lg-3 pb-4 ml-5 d-flex flex-column" +
          (props.formNo === 1 ? " align-items-center" : "")
        }
      >
        {props.formNo === 1 ? (
          <>
            <h4>
              {selectedArtist.lastname} {selectedArtist.firstname}
            </h4>
            <div className="p-0 subNavBar d-flex justify-content-between">
              <button
                onClick={() => setFormNo2(0)}
                className={"btn" + (formNo2 === 0 ? " active" : "")}
              >
                Bio
              </button>
              <button
                onClick={() => setFormNo2(5)}
                className={"btn" + (formNo2 === 5 ? " active" : "")}
              >
                Published Works
              </button>
              <button
                onClick={() => setFormNo2(7)}
                className={"btn" + (formNo2 === 7 ? " active" : "")}
              >

                Personal Works

              </button>
  <button
                onClick={() => setFormNo2(6)}
                className={"btn" + (formNo2 === 6 ? " active" : "")}
              >

 

                Properties

              </button>

              <button
                onClick={() => setFormNo2(8)}
                className={"btn" + (formNo2 === 8 ? " active" : "")}
              >
                Motion
              </button>
              <button
                onClick={() => setFormNo2(9)}
                className={"btn" + (formNo2 === 8 ? " active" : "")}
              >
                Award / Press
              </button>
              <button
                onClick={() => setFormNo2(2)}
                className={"btn" + (formNo2 === 2 ? " active" : "")}
              >
                Portfolio
              </button>
              <button
                onClick={() => setFormNo2(3)}
                className={"btn" + (formNo2 === 3 ? " active" : "")}
              >
                Edit
              </button>
              <button
                onClick={() => setFormNo2(4)}
                className={"btn" + (formNo2 === 4 ? " active" : "")}
              >
                Similar Artists
              </button>
            </div>
          </>
        ) : (
          <div className="mx-lg-5 px-lg-3 py-4 mt-3 ml-5 d-flex justify-content-between">
            <h4>ARTISTS</h4>
            <Input
              type="search"
              label="Search"
              value={search}
              onChange={(e) => {
                changeArtistData(e);
              }}
            />
          </div>
        )}
      </div>
      <div className="px-3 mx-5">
        {props.formNo === 1 ? (
          formNo2 === 1 ? (
            <ImgSubmission
              holder={holder}
              history={props.history}
              selectedArtist={selectedArtist}
              selectedImages={selectedImages}
            />
          ) : formNo2 === 2 ? (
            <Portfolio
              history={props.history}
              selectedArtist={selectedArtist}
              selectedImages={selectedImages}
              updateSelectedImagesArray={updateSelectedImagesArray}
              holder={holder}
            />
          ) : formNo2 === 3 ? (
            <Edit
              holder={holder}
              selectedArtist={selectedArtist}
              setFormNo={(e) => props.setFormNo(e)}
              setFormNo2={(e) => setFormNo2(e)}
              updateArtist={updateArtist}
            />
          ) : formNo2 === 4 ? (
            <ArtistsList
              search={search}
              tempArtist={tempArtist}
              holder={holder}
              artistUsers={props.artistUsers}
              deleteArtistUser={props.deleteArtistUser}
              formChangeEvent={(e) => formChangeEvent(e)}
              reorderArtistHandler={props.reorderArtistHandler}
              similarArtist={true}
              selectedArtist={selectedArtist}
            />

          ) : formNo2===5?(<PWUpdate selectedArtist={selectedArtist}/>
          ): formNo2===6?(
            <PropertiesUpdate selectedArtist={selectedArtist} />
            ) : formNo2 === 7 ? (
            <PersonalWorkUpdate selectedArtist={selectedArtist}/>
          ) : formNo2 === 8 ? (
            <MotionUpdate selectedArtist ={selectedArtist} />
          ) : formNo2 === 9 ? (
            <AwardPressUpdate selectedArtist ={selectedArtist}/>
          ) : (

            <BioUpdateByAdmin
              selectedArtist={selectedArtist}
              setSelectedArtist={setSelectedArtist}
              artistUsers={props.artistUsers}
              setArtistUsers={props.setArtistUsers}
            />
          )
        ) : (
          <ArtistsList
            search={search}
            tempArtist={tempArtist}
            holder={holder}
            artistUsers={props.artistUsers}
            deleteArtistUser={props.deleteArtistUser}
            formChangeEvent={(e) => formChangeEvent(e)}
            reorderArtistHandler={props.reorderArtistHandler}
          />
        )}
      </div>
    </div>
  );
}

export default Artists;
