import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import "./admin.css";
import Header from "../../components/layout2/header";
import Sidebar from "../../components/layout2/sidebar";
import Main from "../../components/layout2/main";
import Dashboard from "./dashboard";
import WebsiteAdmin from "./websiteAdmin";
import Artists from "./artists";
import CategoryIndex from "./categoryPages/index";
import ContactFormSubmissions from "./contactFormSubmissions";
import ImgViewer from "./imgDetail/imgViewer";
import Banners from "./banners";
import Content from "./content";
import Categories from "./categories";
import {
  getContacts,
  getArtImages,
  getArtist,
  getArtistProfileUpdates,
  artistBioGetAll,
  artistBioDelete,
  getClient,
  AAgentUser,
  DAgentUser,
  UAgentUser,
  getAgent,
} from "../../AxiosFunctions/Axiosfunctionality";
import {
  findCountOfReviewArtist,
  findCountOfArtistUsers,
  sumTotalMainImageCounts,
  findCountOfClientUsers,
  findCountOfAgentUsers,
} from "../../UserServices/Services";
import {
  DArtistUser,
  UArtistUser,
  AArtistUser,
  DClientUser,
  UClientUser,
  AClientUser,
} from "../../AxiosFunctions/Axiosfunctionality";
import { useDispatch, useSelector } from "react-redux";
import { updateMessage, updateOpen } from "../../redux/message";
import { setAuthToken } from "../../AxiosFunctions/setCommonHeader";
import Clients from "./clients";
import Agents from "./agents";

function Admin(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  const [formNo, setFormNo] = useState(0);
  const [loading, setLoading] = useState(false);
  const { pageName } = useParams();
  const { imageId } = useParams();
  const [Contacts, setContacts] = useState([]);
  const [artistUpdates, setArtistUpdates] = useState([]);
  const [artistImages, setArtistImages] = useState([]);
  const [artistUsers, setArtistUsers] = useState([]);
  const [reviewArtistCount, setReviewArtistCount] = useState(0);
  const [clientUsers, setClientUsers] = useState([]);
  const [reviewClientCount, setReviewClientCount] = useState(0);
  const [agentUsers, setAgentUsers] = useState([]);
  const [reviewAgentCount, setReviewAgentCount] = useState(0);
  const [artistProfileUpdates, setArtistProfileUpdates] = useState([]);
  const [reviewImagesCount, setReviewImagesCount] = useState(0);
  const [reviewArtistUpdatesCount, setReviewArtistUpdatesCount] = useState(0);
  const [holder, setHolder] = useState(false);
  const [calculatingArtist, setCalculatingArtist] = useState(true);

  const populateContacts = () => {
    let data = {};
    getContacts(data).then((res) => {
      setContacts(res);
    });
  };
  const populateArtistUpdates = () => {
    let data = {};
    artistBioGetAll(data).then((res) => {
      setArtistUpdates(res);
    });
  };
  const populateArtistImages = () => {
    setCalculatingArtist(true);
    let data = {};
    getArtImages(data).then((res) => {
      setArtistImages(res);
      setReviewImagesCount(sumTotalMainImageCounts(res));
      setCalculatingArtist(false);
    });
  };

  const populateArtistUsers = () => {
    let data = {};
    getArtist(data).then((res) => {
      setArtistUsers(res);
      setReviewArtistCount(findCountOfArtistUsers(res));
    });
  };
  const populateClientUsers = () => {
    let data = {};
    getClient(data).then((res) => {
      setClientUsers(res);
      setReviewClientCount(findCountOfClientUsers(res));
    });
  };
  const populateAgentUsers = () => {
    let data = {};
    getAgent(data).then((res) => {
      setAgentUsers(res);
      setReviewAgentCount(findCountOfAgentUsers(res));
    });
  };
  const populateArtistProfileUpdates = () => {
    let data = {};
    getArtistProfileUpdates(data).then((res) => {
      setArtistProfileUpdates(res);
      setReviewArtistUpdatesCount(res.length);
    });
  };
  const addArtistUser = (e, data) => {
    if (data) {
      setHolder(true);
      AArtistUser(data).then((res) => {
        setHolder(false);
        dispatch(updateOpen(true));
        dispatch(updateMessage(res));
        populateArtistUsers();

        history.push("admin/dashboard");
      });
    }
  };
  const addClientUser = (e, data) => {
    if (data) {
      setHolder(true);
      AClientUser(data).then((res) => {
        setHolder(false);
        dispatch(updateOpen(true));
        dispatch(updateMessage(res));
        populateClientUsers();

        history.push("admin/dashboard");
      });
    }
  };
  const addAgentUser = (e, data) => {
    if (data) {
      setHolder(true);
      AAgentUser(data).then((res) => {
        setHolder(false);
        dispatch(updateOpen(true));
        dispatch(updateMessage(res));
        populateAgentUsers();

        history.push("admin/dashboard");
      });
    }
  };
  const deleteArtistUser = (e, id) => {
    if (id) {
      let data = {
        id: id,
      };
      DArtistUser(data).then((res) => {
        dispatch(updateOpen(true));
        dispatch(updateMessage(res));

        populateArtistUsers();
      });
    }
  };
  const deleteClientUser = (e, id) => {
    if (id) {
      let data = {
        id: id,
      };
      DClientUser(data).then((res) => {
        dispatch(updateOpen(true));
        dispatch(updateMessage(res));
        populateClientUsers();
      });
    }
  };
  const deleteAgentUser = (e, id) => {
    if (id) {
      let data = {
        id: id,
      };
      DAgentUser(data).then((res) => {
        dispatch(updateOpen(true));
        dispatch(updateMessage(res));
        populateAgentUsers();
      });
    }
  };
  const deleteArtistUpdate = (e, id) => {
    if (id) {
      let data = {
        _id: id,
      };
      artistBioDelete(data).then((res) => {
        dispatch(updateOpen(true));
        dispatch(updateMessage(res));
        populateArtistUpdates();
      });
    }
  };

  const approveArtistUser = (e, id) => {
    if (id) {
      let data = {
        id: id,
      };
      setLoading(true);
      UArtistUser(data).then((res) => {
        dispatch(updateOpen(true));
        dispatch(updateMessage(res));
        populateArtistUsers();

        setLoading(false);
      });
    }
  };

  const approveClientUser = (e, id) => {
    if (id) {
      let data = {
        id: id,
      };
      setLoading(true);
      UClientUser(data).then((res) => {
        dispatch(updateOpen(true));
        dispatch(updateMessage(res));

        populateClientUsers();
        setLoading(false);
      });
    }
  };
  const approveAgentUser = (e, id) => {
    if (id) {
      let data = {
        id: id,
      };
      setLoading(true);
      UAgentUser(data).then((res) => {
        dispatch(updateOpen(true));
        dispatch(updateMessage(res));

        populateAgentUsers();
        setLoading(false);
      });
    }
  };

  const getContantCount = (contacts) => {
    let count = 0;
    contacts.forEach((item, key) => {
      if (item.status === 0) {
        count++;
      }
    });
    return count;
  };

  useEffect(() => {
    switch (pageName) {
      case "dashboard":
      case null:
        // When pageName is 'dashboard' or null, execute all
        populateContacts();
        populateArtistUpdates();
        populateArtistImages();
        populateClientUsers();
        populateAgentUsers();
        populateArtistProfileUpdates();
        break;
      case "artists":
        populateArtistUsers();
        break;

      case "clients":
        populateClientUsers();
        break;
      case "myshannons":
        populateAgentUsers();
        break;

      case "contact":
        populateContacts();
        break;
      default:
        // Optionally handle any cases not covered above
        break;
    }
  }, [pageName]); // Depend on pageName to trigger updates

  const reorderArtistHandler = (data) => {
    setArtistUsers(data);
  };
  const reorderClientHandler = (data) => {
    setClientUsers(data);
  };
  const reorderAgentHandler = (data) => {
    setAgentUsers(data);
  };
  return (
    <>
      <Header />
      <div className="admin d-flex m-0">
        <Sidebar setFormNo={setFormNo} activePage={pageName} />
        <Main>
          {!pageName || pageName === "dashboard" ? (
            <Dashboard
              history={props.history}
              //Counts And small Stuff
              contactCount={
                Contacts !== undefined ? getContantCount(Contacts) : 0
              }
              reviewImagesCount={
                calculatingArtist ? "loading" : reviewImagesCount
              }
              reviewArtistCount={reviewArtistCount}
              reviewClientCount={reviewClientCount}
              reviewAgentCount={reviewAgentCount}
              //All Mandatory Data
              contacts={Contacts !== undefined ? Contacts : []}
              artistImages={artistImages !== undefined ? artistImages : []}
              artistUsers={artistUsers !== undefined ? artistUsers : []}
              clientUsers={clientUsers !== undefined ? clientUsers : []}
              agentUsers={agentUsers !== undefined ? agentUsers : []}
              artistUpdates={artistUpdates !== undefined ? artistUpdates : []}
              //functions for contact
              // contactViewMore = {contactViewMore}
              //populate artist users
              populateArtistUpdates={populateArtistUpdates}
              populateArtistImages={populateArtistImages}
              populateArtistUsers={populateArtistUsers}
              populateClientUsers={populateClientUsers}
              populateAgentUsers={populateAgentUsers}
              //artist functionalities
              addArtistUser={addArtistUser}
              deleteArtistUser={deleteArtistUser}
              approveArtistUser={approveArtistUser}
              deleteArtistUpdate={deleteArtistUpdate}
              approveArtistUpdate={approveArtistUser}
              //client functionalities
              addClientUser={addClientUser}
              deleteClientUser={deleteClientUser}
              approveClientUser={approveClientUser}
              approveClientUpdate={approveClientUser}
              //agent functionalities
              addAgentUser={addAgentUser}
              deleteAgentUser={deleteAgentUser}
              approveAgentUser={approveAgentUser}
              approveAgentUpdate={approveAgentUser}
              loading={loading}
              holder={holder}
              formNo={formNo}
              setFormNo={setFormNo}
            />
          ) : pageName === "website_admin" ? (
            <WebsiteAdmin
              history={props.history}
              formNo={formNo}
              setFormNo={setFormNo}
            />
          ) : pageName === "artists" ? (
            imageId ? (
              <ImgViewer artistData={artistUsers} history={props.history} />
            ) : (
              <Artists
                history={props.history}
                artistUsers={artistUsers}
                setArtistUsers={setArtistUsers}
                populateArtistUsers={populateArtistUsers}
                deleteArtistUser={deleteArtistUser}
                approveArtistUser={approveArtistUser}
                formNo={formNo}
                setFormNo={setFormNo}
                reorderArtistHandler={reorderArtistHandler}
              />
            )
          ) : pageName === "clients" ? (
            <Clients
              history={props.history}
              clientUsers={clientUsers}
              setClientUsers={setClientUsers}
              populateClientUsers={populateClientUsers}
              deleteClientUser={deleteClientUser}
              approveClientUser={approveClientUser}
              formNo={formNo}
              setFormNo={setFormNo}
              reorderClientHandler={reorderClientHandler}
            />
          ) : pageName === "myshannons" ? (
            <Agents
              history={props.history}
              agentUsers={agentUsers}
              setAgentUsers={setAgentUsers}
              populateAgentUsers={populateAgentUsers}
              deleteAgentUser={deleteAgentUser}
              approveAgentUser={approveAgentUser}
              formNo={formNo}
              setFormNo={setFormNo}
              reorderAgentHandler={reorderAgentHandler}
            />
          ) : pageName === "banners" ? (
            <Banners history={props.history} />
          ) : pageName === "content" ? (
            <Content history={props.history} />
          ) : pageName === "categories" ? (
            <CategoryIndex history={props.history} />
          ) : pageName === "contact" ? (
            <ContactFormSubmissions
              history={props.history}
              contacts={Contacts !== undefined ? Contacts : []}
              populateContacts={populateContacts}
            />
          ) : (
            <Redirect to="/admin/" />
          )}
        </Main>
      </div>
    </>
  );
}

export default Admin;
