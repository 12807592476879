import React, { useEffect, useState } from "react";
import crossSvg from "../../../assets/svgs/crossButton.svg"; // Import your SVG file
import MyPopup from "../../../components/myPopup/myPopup";
import { updateMessage, updateOpen } from "../../../redux/message";
import { useDispatch } from "react-redux";
import { ClientDataAPI } from "../../../redux/clientSlice";
import rightArrowCircle from "../../../assets/svgs/rightArrowCircle.svg"; // Import your SVG file
import leftArrowCircle from "../../../assets/svgs/leftArrowCircle.svg"; // Import your SVG file
import {
  removeClientMoodBoardItemValue,
  updateClientMoodBoard,
} from "../../../AxiosFunctions/Axiosfunctionality";
import copyButton from "../../../assets/svgs/copyIcon.svg"; // Import your SVG file
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ClientMoodBoardEdit = ({
  clientDetails,
  moodBoardId,
  haveEditAccess,
}) => {
  const [currentMoodBoard, setCurrentMoodBoard] = useState({});
  const [allElements, setAllElements] = useState([]);
  const [selectedElement, setSelectedElement] = useState();
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [orderElements, setOrderElements] = useState(false);
  const [shareModalActive, setShareModalActive] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const currentUrl = window.location.href;

  const dispatch = useDispatch();
  const history = useHistory();
  const handleCopyLink = () => {
    // The value you want to copy
    // Using the Clipboard API to copy the text
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setCopySuccess("Link copied successfully!");
        // Hide the message after 2 seconds
        setTimeout(() => setCopySuccess(""), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        setCopySuccess("Failed to copy text.");
        setTimeout(() => setCopySuccess(""), 2000);
      });
  };
  const removeValueFromMoodItem = async () => {
    try {
      setAllElements((prevElements) =>
        prevElements.filter((item) => item._id !== selectedElement._id)
      );
      const response = await removeClientMoodBoardItemValue({
        userId: clientDetails._id,
        moodBoardId: moodBoardId,
        itemId: selectedElement._id,
      });
      dispatch(ClientDataAPI({ _id: clientDetails._id }));
      dispatch(updateOpen(true));
      dispatch(updateMessage("Item Successfully Removed"));
      setSelectedElement(null);
      setDeleteModalActive(false);
    } catch (error) {
      console.error("Error removing item", error);
    }
  };
  useEffect(() => {
    const currentMoodBoard = clientDetails.moodBoards.find(
      (m) => m._id === moodBoardId
    );
    setCurrentMoodBoard(currentMoodBoard);
    if (currentMoodBoard) {
      setAllElements(currentMoodBoard.moodBoardItems || []);
    }
  }, [clientDetails, moodBoardId]);
  const handleOpenOrderElement = () => {
    setAllElements((prevElements) =>
      prevElements.filter((item) => item.itemType !== "textEdit")
    );

    setOrderElements(true);
  };
  const changeImageOrder = (elementId, direction) => {
    setAllElements((prevElements) => {
      const index = prevElements.findIndex(
        (element) => element._id === elementId
      );
      const targetIndex = direction === "left" ? index - 1 : index + 1;

      // Check if the target index is within bounds
      if (targetIndex >= 0 && targetIndex < prevElements.length) {
        // Swap the elements
        let newElements = [...prevElements];
        [newElements[index], newElements[targetIndex]] = [
          newElements[targetIndex],
          newElements[index],
        ];
        return newElements;
      }

      // Return the original array if no swap is needed
      return prevElements;
    });
  };

  const handleCancelOrderElement = () => {
    setAllElements((prevElements) =>
      prevElements.filter((item) => item.itemType !== "textEdit")
    );
    const currentMoodBoard = clientDetails.moodBoards.find(
      (m) => m._id === moodBoardId
    );
    setCurrentMoodBoard(currentMoodBoard);
    if (currentMoodBoard) {
      setAllElements(currentMoodBoard.moodBoardItems || []);
    }
    dispatch(updateOpen(true));
    dispatch(updateMessage("Order Canceled Successfully"));
    setOrderElements(false);
  };
  const handleSaveOrderElement = async () => {
    try {
      await updateClientMoodBoard({
        userId: clientDetails._id,
        moodBoardId: moodBoardId,

        moodBoardItems: allElements,
      });
      dispatch(ClientDataAPI({ _id: clientDetails._id }));
      dispatch(updateOpen(true));
      dispatch(updateMessage("Order Changed Successfully"));
      setOrderElements(false);
    } catch (error) {
      console.error("Failed to update mood board", error);
    }
  };

  const columns = [[], [], [], [], []]; // Array of arrays for 3 columns
  allElements.forEach((image, index) => {
    columns[index % 5].push(image); // Distribute images round-robin style
  });
  return (
    <>
      <h2 className="newsh2 h">{currentMoodBoard.title}</h2>
      {haveEditAccess && (
        <div style={{ marginBottom: "15px", display: "flex" }}>
          {orderElements ? (
            <button
              onClick={handleSaveOrderElement}
              className={`allArtistsButtons allArtistsButtonsActive `}
            >
              SAVE
            </button>
          ) : (
            <button
              onClick={handleOpenOrderElement}
              className={`allArtistsButtons`}
            >
              ORDER
            </button>
          )}
          {orderElements ? (
            <button
              onClick={handleCancelOrderElement}
              className={`allArtistsButtons`}
            >
              Cancel
            </button>
          ) : (
            <>
              <button
                className={`allArtistsButtons`}
                onClick={() => setShareModalActive(true)}
              >
                SHARE
              </button>
            </>
          )}
        </div>
      )}
      {allElements.length > 0 ? (
        <div className="gallery" style={{ marginBottom: "15px" }}>
          {columns.map((col, index) => (
            <div key={index} className="column">
              {col.map((element, idx) => (
                <div className="imagescontainer" key={idx}>
                  {orderElements && (
                    <div className="image-order-arrows bgTransparentBlack">
                      <img
                        src={leftArrowCircle}
                        alt="Move left"
                        className=" orderArrow"
                        onClick={(e) => {
                          e.stopPropagation();
                          changeImageOrder(element._id, "left");
                        }}
                      />
                      <img
                        src={rightArrowCircle}
                        alt="Move right"
                        className=" orderArrow"
                        onClick={(e) => {
                          e.stopPropagation();

                          changeImageOrder(element._id, "right");
                        }}
                      />
                    </div>
                  )}
                  <img
                    onClick={() => {
                      history.push(
                        `/${element.artist?.fullName}/portfolio?imageURL=${element.artistImage}`
                      );
                    }}
                    className="artistImage"
                    src={element.artistImage}
                    alt={`Grid image ${index + 1}`}
                  />
                  <p
                    onClick={() => {
                      history.push(
                        `/${element.artist?.fullName}/portfolio?imageURL=${element.artistImage}`
                      );
                    }}
                    className="aritstNameMoodBoard "
                  >
                    {element.artist?.firstname} {element.artist?.lastname}
                  </p>
                  {haveEditAccess && (
                    <div className="accessButtonsContainer">
                      <img
                        src={crossSvg}
                        alt="Delete"
                        className="accessButtons"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteModalActive(true);
                          setSelectedElement(element);
                        }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : haveEditAccess ? (
        <h2 className="newsh2 h">
          Add some Images{" "}
          <span style={{ color: "rgb(250, 142, 55)" }}>Go to Shannon</span>
        </h2>
      ) : (
        <h2 className="newsh2 h">
          No Images for this MoodBoard{" "}
          <span style={{ color: "rgb(250, 142, 55)" }}>Go to Shannon</span>
        </h2>
      )}

      {deleteModalActive && selectedElement && (
        <MyPopup
          BackClose
          onClose={() => setDeleteModalActive(false)}
          whiteCard={true}
        >
          <div className="mx-5 my-2">
            <h2 style={{ color: "#D16A27" }}>Delete Artist</h2>
            <p>Are you sure you want to delete this artist from favorites?</p>
            <div className="mx-5 my-2 d-flex align-items-center justify-content-center">
              <button
                className="myBtn active mx-1"
                onClick={removeValueFromMoodItem}
              >
                DELETE
              </button>
              <button
                className="myBtn mx-1"
                onClick={() => setDeleteModalActive(false)}
              >
                CANCEL
              </button>
            </div>
          </div>
        </MyPopup>
      )}
      {shareModalActive && (
        <MyPopup
          BackClose
          onClose={() => setShareModalActive(false)}
          whiteCard={true}
        >
          <div className="mx-5 my-2">
            <h2 style={{ color: "#D16A27" }}>
              Share "{currentMoodBoard.title}"" Idea Board
            </h2>
            <p>Share this Idea Boadd Item to show others</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <input
                type="text"
                disabled
                value={currentUrl}
                className="copyLink"
              />
              <img
                src={copyButton}
                alt="copy"
                className="simpleAccessButtons"
                onClick={() => handleCopyLink()}
              />
            </div>
            {copySuccess && (
              <div style={{ color: "green", margin: "10px" }}>
                {copySuccess}
              </div>
            )}

            <div className="mx-5 my-2 d-flex align-items-center justify-content-center">
              <button
                className="myBtn mx-1"
                onClick={() => setShareModalActive(false)}
              >
                Close
              </button>
            </div>
          </div>
        </MyPopup>
      )}
    </>
  );
};

export default ClientMoodBoardEdit;
