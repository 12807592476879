import { useState } from "react";
import AddImage from "../../assets/svgs/addImage.svg";

const Motions = ({history,setCurrentMotion,artistDetails, motions}) => {

    const handleMotionForm = (currentMotion) => {
        setCurrentMotion(currentMotion);
        history.push("/artist/motion_upload");
    };

    const getSrc = (motion)=>{
        let id = "";
        if(motion.motionVideoUrl){
            id = motion.motionVideoUrl.videoID.split("/")[motion.motionVideoUrl.videoID.split("/").length - 1];    
        }
        return motion.motionImageUrl || "https://img.youtube.com/vi/"+id+"/hqdefault.jpg";
    }

    return (
        <div className="px-1 px-md-5 artist mb-5">
            <h4> MOTION </h4>
            <div className="_4cols-v2">
                <label className="artistcardAdmin w-inline-block addImageBtn" onClick={()=>handleMotionForm(undefined)}>
                <img alt="" src={AddImage} className="addImage"/>
                </label>

                {motions && motions.map(motion => (
                    <div
                    className="artistcardAdmin w-inline-block"
                    style={{ cursor: "pointer" }}
                    key={motion._id}
                    onClick={() => handleMotionForm(motion)}
                    >
                    <img
                        src={getSrc(motion)}
                        alt={motion.personalWorkTitle}
                        className="image"
                    />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Motions