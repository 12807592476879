import ClientTabEdit from "./clientTabEdit";
import ClientTabFav from "./clientTabFav";
import ClientTabMoodBoard from "./clientTabMoodBoard";
import { useHistory, useLocation } from "react-router-dom";
import ClientMoodBoardEdit from "./clientMoodBoardEdit";
import { useEffect } from "react";

const ClientProfile = ({ searchedClientDetails, haveEditAccess }) => {
  const tabsClient = [
    { name: "Favorite Artists", path: "favoriteArtists" },
    { name: "MoodBoards", path: "moodBoards" },
  ];
  const history = useHistory();
  const location = useLocation();

  // Function to parse query parameters
  const getQueryParam = (param) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };
  // Get the 'activeTab' query parameter
  const activeTab = getQueryParam("activeTab");
  const moodBoardId = getQueryParam("moodBoardId");

  const handleTab = (tab, moodBoardId = null) => {
    // Use history.push to navigate and append new search parameters
    if (moodBoardId) {
      history.push(
        `/client/${searchedClientDetails._id}/?activeTab=${tab}&moodBoardId=${moodBoardId}`
      );
      return;
    }
    history.push(`/client/${searchedClientDetails._id}/?activeTab=${tab}`);
  };
  useEffect(() => {
    const checkAccess = setTimeout(() => {
      if (activeTab === "edit" && !haveEditAccess) {
        history.push(`/`);
      }
    }, 1000); // Delay for 1 second (1000 milliseconds)

    return () => clearTimeout(checkAccess); // Clear the timeout if the component unmounts or dependencies change
  }, [activeTab, haveEditAccess]);

  return (
    <div>
      <h2 className="newsh2 h">
        Welcome to{" "}
        <span style={{ color: "rgb(250, 142, 55)" }}>MyShannon</span>
      </h2>
      <div className="clientDashboardGrid">
        {haveEditAccess && activeTab !== "edit" && (
          <div style={{ marginBottom: "15px" }}>
            {tabsClient.map((tab, i) => (
              <button
                key={i}
                className={`allArtistsButtons ${
                  activeTab === tab.path ? "allArtistsButtonsActive" : ""
                } `}
                onClick={() => handleTab(tab.path)}
              >
                {tab.name}
              </button>
            ))}
          </div>
        )}
        {activeTab === "edit" && (
          <ClientTabEdit
            clientDetails={searchedClientDetails}
            haveEditAccess={haveEditAccess}
          />
        )}
        {activeTab === "favoriteArtists" && (
          <ClientTabFav
            clientID={searchedClientDetails?._id}
            haveEditAccess={haveEditAccess}
          />
        )}
        {activeTab === "moodBoards" &&
          (moodBoardId ? (
            <ClientMoodBoardEdit
              moodBoardId={moodBoardId}
              clientDetails={searchedClientDetails}
              haveEditAccess={haveEditAccess}
            />
          ) : (
            <ClientTabMoodBoard
              handleTab={handleTab}
              clientDetails={searchedClientDetails}
              haveEditAccess={haveEditAccess}
            />
          ))}
      </div>
    </div>
  );
};

export default ClientProfile;
