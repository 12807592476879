import React, { useState } from "react";
import { Link } from "react-router-dom";
import { updateMessage, updateOpen } from "../../redux/message";
import { useDispatch } from "react-redux";
import { logoutAgent } from "../../redux/agentSlice";

const UserAvatar = ({ letter, userType, userId }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();

  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const logout = () => {
    localStorage.removeItem("authorization");
    localStorage.removeItem("agentInfo");
    dispatch(logoutAgent());
    dispatch(updateOpen(true));
    dispatch(updateMessage("Successfully Logged Out"));
    window.dispatchEvent(new Event("storage"));
    window.location.assign("https://shannonassociates.com/");
  };
  return (
    <div className="user-avatar-container" onClick={toggleDropdown}>
      <div className="userAvatar">{letter}</div>
      {showDropdown && (
        <div className="CustomDropdownMenu">
          {/* <Link
            to={`/${userType}/${userId}?activeTab=home`}
            className="CustomDropdownItem"
          >
            MYSHANNON
          </Link> */}
          <Link
            to={`/${userType}/${userId}?activeTab=edit`}
            className="CustomDropdownItem"
          >
            SETTINGS
          </Link>
          <div
            onClick={logout}
            className="CustomDropdownItem"
            style={{ color: "#d16a27" }}
          >
            LOG OUT
          </div>
        </div>
      )}
    </div>
  );
};

export default UserAvatar;
