import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./layout2.css";
import { decodeToken } from "react-jwt";

function ArtistDashbooardSidebar(props) {
  const [adminDetails, setAdminDetails] = useState(null);

  let side_toggle = false;
  const activePage = props.activePage;
  const sidebar_display = () => {
    let sideBar = document.getElementsByClassName("sideBar")[0];
    let sideBar_menubtn = document.getElementsByClassName("sideBar_menubtn")[0];
    if (side_toggle) {
      sideBar.classList.add("d-none");
      sideBar_menubtn.style.transform = "rotate(-90deg) translateY(0px)";
      sideBar_menubtn.innerHTML = "|||";
    } else {
      sideBar.classList.remove("d-none");
      sideBar_menubtn.style.transform = "rotate(-90deg) translateY(230px)";
      sideBar_menubtn.innerHTML = "&times;";
    }
    side_toggle = !side_toggle;
  };

  useEffect(() => {
    let storageData = localStorage.getItem("authorization");
    let details = decodeToken(storageData);
    setAdminDetails(details);
  }, []);

  return (
    <>
      <button
        onClick={() => sidebar_display()}
        className="sideBar_menubtn d-md-none d-inline"
      >
        |||
      </button>
      <div className="sideBar d-md-inline d-none">
        <div className="sidebar_header px-5 py-4">
          <div className="profileDet">
            <h5>ARTIST</h5>
            <small style={{ marginTop: "5px" }}>
              {props.artistDetails !== null
                ? props.artistDetails.lastname +
                  " " +
                  props.artistDetails.firstname
                : "ARTIST"}
            </small>
          </div>
        </div>
        <div className="sidebar_body pl-5 pt-4">
          <Link
            className={
              "my-2 " +
              (!activePage || activePage === "dashboard" ? "active" : "")
            }
            to="/artist/dashboard"
          >
            Dashboard
          </Link>
          {/* <Link
            className={"my-2 " + (activePage === "portfolio" ? "active" : "")}
            to="/artist/portfolio"
          >
            Portfolio
          </Link> */}
          <Link
            className={"my-2 " + (activePage === "bio" ? "active" : "")}
            to="/artist/bio"
          >
            Bio
          </Link>
          <Link
            className={"my-2 " + (activePage === "properties" ? "active" : "")}
            to="/artist/properties"
          >
            Properties
          </Link>
          <Link
            className={
              "my-2 " + (activePage === "publish_works" ? "active" : "")
            }
            to="/artist/publish_works"
          >
            Published Works
          </Link>
          {/* <Link
            className={"my-2 " + (activePage === "awards" ? "active" : "")}
            to="/artist/awards"
          >
            Awards
          </Link>
          <Link
            className={"my-2 " + (activePage === "press" ? "active" : "")}
            to="/artist/press"
          >
            Press
          </Link>
          <Link
            className={
              "my-2 " + (activePage === "kids_portfolio" ? "active" : "")
            }
            to="/artist/kids_portfolio"
          >
            Kid&apos;s Portfolio
          </Link> */}
          <Link
            className={
              "my-2 " + (activePage === "personal_work" ? "active" : "")
            }
            to="/artist/personal_work"
          >
            Personal Work
          </Link>
          <Link
            className={
              "my-2 " + (activePage === "motion" ? "active" : "")
            }
            to="/artist/motion"
          >
            Motion
          </Link>
          <Link
            className={
              "my-2 " + (activePage === "awards_press" ? "active" : "")
            }
            to="/artist/awards_press"
          >
            Awards / Press
          </Link>
        </div>
      </div>
    </>
  );
}

export default ArtistDashbooardSidebar;
