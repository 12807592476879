import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const images = window.location.origin + "/assets/images";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div className={className}>
      <img
        src={images + "/right.png"}
        loading="lazy"
        alt=""
        style={{ position: "absolute", top: "0" }}
      />
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div>
      <img
        src={images + "/left.png"}
        loading="lazy"
        alt=""
        style={{ position: "absolute", top: "0" }}
      />
    </div>
  );
}
function Navbar(props) {
  const { AddToCart, ArtistDataAPI } = useSelector((state) => state);

  const pageIllustration = [
    "kidshannon",
    "photography",
    "bipoc",
    "black",
    "medical",
    "motion",
    "categories",
    "newest",
    "recentlyUpdated",
    "about",
    "contact",
  ];
  const bipocPages = [
    "Black",
    "Asian",
    "Latino/Latina",
    "Central Asian",
    "Indigenous",
    "CentralAsianArtist",
    "AsianArtist",
    "LatinoArtist",
    "IndigenousArtist",
  ];
  const isGraphicNovel = [
    "covers",
    "sequential",
    "inking",
    "coloring",
    "lettering",
    "graphicNovel",
    "GraphicNovel",
    "GRAPHIC NOVEL",
  ];
  const [searchOption, setSearchOption] = useState();
  const isMedical = ["general", "technical", "MEDICAL"];
  const localStorageAddToCart = () => {
    let addToCartArray = [];
    Object.keys(AddToCart.cartInfo).map((oneKey, i) => {
      if (
        oneKey !== "messageShow" &&
        oneKey !== "count" &&
        oneKey !== "getAnEstimate"
      ) {
        addToCartArray.push(AddToCart.cartInfo[oneKey]);
      }
    });
    if (addToCartArray.length > 0) {
      localStorage.setItem("addToCart", JSON.stringify(addToCartArray));
    } else {
      localStorage.removeItem("addToCart");
    }
  };

  useEffect(() => {
    if (props.searchArtist === "") {
      setSearchOption();
    } else {
      let tempSearch = ArtistDataAPI.artistData.filter(
        (artist) =>
          artist.firstname
            .toLowerCase()
            .includes(props.searchArtist.toLowerCase()) ||
          artist.lastname
            .toLowerCase()
            .includes(props.searchArtist.toLowerCase()) ||
          (
            artist.firstname.toLowerCase() + artist.lastname.toLowerCase()
          ).includes(
            props.searchArtist.toLowerCase().trim().split(" ").join("")
          ) ||
          (
            artist.lastname.toLowerCase() + artist.firstname.toLowerCase()
          ).includes(
            props.searchArtist.toLowerCase().trim().split(" ").join("")
          )
      );
      setSearchOption(tempSearch);
    }
  }, [props.searchArtist]);

  const removeRoute = () => {
    localStorage.removeItem("routePaths");
    localStorage.setItem("Bipoc", "none");
    localStorage.setItem("Category", "none");
  };
  const sliderSettings = {
    dots: false,
    speed: 500,
    nextArrow: (
      <div style={{ height: "auto" }}>
        <img src={images + "/right.png"} loading="lazy" alt="" />
      </div>
    ),
    prevArrow: <div style={{ height: "auto" }}></div>,
    arrows: true,
    variableWidth: true,
    className: "notes-slider",
    swipeToSlide: true,
  };

  return (
    <div className="w-100">
      <div className="div-block-11 w-100 ">
        <div className="mobileViewNavbar ">
          <div className="sliderContainer">
            <Slider {...sliderSettings}>
              <Link
                to="/illustration-artists"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "illustration-artists" ||
                  props.currentCat == "Illustration" ||
                  props.currentCat == "Illustration-artists"
                    ? "w--current"
                    : "")
                }
              >
                ILLUSTRATION
              </Link>
              <a
                href="https://kidshannon.com"
                target="_blank"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "kidshannon" ? "w--current" : "")
                }
                onClick={() => localStorageAddToCart()}
              >
                KIDSHANNON
              </a>

              <Link
                to="/bipoc"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "bipoc" ||
                  bipocPages.includes(props.currentBipoc)
                    ? "w--current"
                    : "")
                }
              >
                BIPOC
              </Link>

              <Link
                to="/properties"
                className={
                  "filterhomelink v2 mb-3 " +
                  (props.aciveBtn === "properties" ? " w--current" : "")
                }
              >
                {" "}
                PROPERTIES{" "}
              </Link>

              <Link
                to="/medical"
                className={
                  "filterhomelink v2 " +
                  (isMedical.includes(props.currentMedical) ? "w--current" : "")
                }
              >
                MEDICAL
              </Link>

              <Link
                to="/graphicNovel"
                className={
                  "filterhomelink v2 " +
                  (isGraphicNovel.includes(props.currentGraphical)
                    ? "w--current"
                    : "")
                }
              >
                GRAPHIC NOVEL
              </Link>

              <Link
                to="/motions"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "motions" || props.currentCat == "motions"
                    ? "w--current"
                    : "")
                }
              >
                MOTION
              </Link>
              <Link className="navbarDot"> · </Link>
              <Link
                to="/newest"
                className={
                  "filterhomelink v2 mb-3 " +
                  (props.aciveBtn === "newest"
                    ? " w--current undelinedNavbar"
                    : "")
                }
              >
                {" "}
                <span style={{ color: "#fa8e37" }}>NEW</span> ILLUSTRATORS{" "}
              </Link>
              <Link
                to="/recentlyUpdated"
                className={
                  "filterhomelink v2 mb-3 " +
                  (props.aciveBtn === "recentlyUpdated" ? "w--current" : "")
                }
              >
                {" "}
                <span style={{ color: "#fa8e37" }}>
                  UPDATED
                </span> PORTFOLIOS{" "}
              </Link>
              <Link className="navbarDot"> · </Link>
              <Link
                to="/mentorship"
                className={
                  "filterhomelink v2 mb-3 " +
                  (props.aciveBtn === "mentorship"
                    ? " w--current undelinedNavbar"
                    : "")
                }
                style={{ color: "#B0B0B0" }}
              >
                {" "}
                MENTORSHIP{" "}
              </Link>
              <Link
                to="/philanthropy"
                className={
                  "filterhomelink v2 mb-3 " +
                  (props.aciveBtn === "philanthropy"
                    ? " w--current undelinedNavbar"
                    : "")
                }
                style={{ color: "#B0B0B0" }}
              >
                {" "}
                PHILANTHROPY{" "}
              </Link>
            </Slider>
          </div>

          {props.searchBar ? (
            <div className="form-block-2 search" style={{ marginLeft: "auto" }}>
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                method="get"
                className="form-2"
              >
                <input
                  onChange={(e) => {
                    props.updateTempArtist(e);
                  }}
                  value={props.searchArtist}
                  type="text"
                  className="searchbarhome w-input"
                  maxLength="256"
                  name="Search"
                  data-name="Search"
                  placeholder="SEARCH"
                  id="Search"
                />
                <Link to="#" className="link-block-3 w-inline-block"></Link>
              </form>
            </div>
          ) : null}
        </div>
      </div>

      <div className="fullViewNavbar">
        <div className="filterhomebox v2">
          <div
            className="filterhomebox v2"
            style={{ padding: "14px 0px 0px 0px", lineHeight: "0" }}
          >
            <Link
              to="/illustration-artists"
              className={
                "filterhomelink v2 " +
                (props.aciveBtn === "illustration-artists" ||
                props.currentCat == "Illustration" ||
                props.currentCat == "Illustration-artists"
                  ? "w--current"
                  : "")
              }
            >
              ILLUSTRATION
            </Link>
            <a
              href="https://kidshannon.com"
              target="_blank"
              className={
                "filterhomelink v2 " +
                (props.aciveBtn === "kidshannon" ? "w--current" : "")
              }
              onClick={() => localStorageAddToCart()}
            >
              KIDSHANNON
            </a>
            <div className="navbarBipocLink">
              {/* <Link to="/bipoc" className={"filterhomelink v2 " + (props.aciveBtn === "bipoc" ? "w--current" : "")}>BIPOC <span className='bipocSpan'></span> </Link> */}
              <Link
                to="/bipoc"
                className={
                  "filterhomelink v2 " +
                  (props.aciveBtn === "bipoc" ||
                  bipocPages.includes(props.currentBipoc)
                    ? "w--current"
                    : "")
                }
              >
                BIPOC
              </Link>
              <div
                className="navbarBipocItemsLink"
                style={{ lineHeight: "0.7" }}
              >
                <Link
                  to="/bipoc/black"
                  className={
                    "filterhomelink v2 mb-3 " +
                    (props.currentBipoc === "Black" ? "w--current" : "")
                  }
                >
                  BLACK
                </Link>
                <Link
                  to="/bipoc/asianArtist"
                  className={
                    "filterhomelink v2 mb-3 " +
                    (props.currentBipoc === "Asian" ? "w--current" : "")
                  }
                >
                  ASIAN
                </Link>
                <Link
                  to="/bipoc/latinoArtist"
                  className={
                    "filterhomelink v2 mb-3 " +
                    (props.currentBipoc === "Latino/Latina" ? "w--current" : "")
                  }
                >
                  LATINO/LATINA
                </Link>
                <Link
                  to="/bipoc/centralAsianArtist"
                  className={
                    "filterhomelink v2 mb-3 " +
                    (props.currentBipoc === "Central Asian" ? "w--current" : "")
                  }
                >
                  CENTRAL ASIAN
                </Link>
                <Link
                  to="/bipoc/indigenousArtist"
                  className={
                    "filterhomelink v2 mb-1 " +
                    (props.currentBipoc === "Indigenous" ? "w--current" : "")
                  }
                >
                  INDIGENOUS
                </Link>
              </div>
            </div>

            <Link
              to="/properties"
              className={
                "filterhomelink v2 mb-3 " +
                (props.aciveBtn === "properties" ? " w--current" : "")
              }
            >
              {" "}
              PROPERTIES{" "}
            </Link>

            {/* <Link to="/cgi" className={"filterhomelink v2 " + (props.aciveBtn === "cgi" ? "w--current" : "")}>CGI</Link> */}
            <div className="navbarBipocLink">
              <Link
                to="/medical"
                className={
                  "filterhomelink v2 " +
                  (isMedical.includes(props.currentMedical) ? "w--current" : "")
                }
              >
                MEDICAL
              </Link>
              <div
                className="navbarBipocItemsLink"
                style={{ lineHeight: "0.7" }}
              >
                <Link
                  to="/medical/general"
                  className={
                    "filterhomelink v2 mb-3 " +
                    (props.currentMedical === "general" ? "w--current" : "")
                  }
                >
                  GENERAL
                </Link>
                <Link
                  to="/medical/technical"
                  className={
                    "filterhomelink v2 mb-1 " +
                    (props.currentMedical === "technical" ? "w--current" : "")
                  }
                >
                  TECHNICAL
                </Link>
              </div>
            </div>
            <div className="navbarBipocLink">
              <Link
                to="/graphicNovel"
                className={
                  "filterhomelink v2 " +
                  (isGraphicNovel.includes(props.currentGraphical)
                    ? "w--current"
                    : "")
                }
              >
                GRAPHIC NOVEL
              </Link>
              <div
                className="navbarBipocItemsLink"
                style={{ lineHeight: "0.7" }}
              >
                <Link
                  to="/graphicNovel/covers"
                  className={
                    "filterhomelink v2 mb-3 " +
                    (props.currentGraphical === "covers" ? "w--current" : "")
                  }
                >
                  COVERS
                </Link>
                <Link
                  to="/graphicNovel/sequential"
                  className={
                    "filterhomelink v2 mb-3 " +
                    (props.currentGraphical === "sequential"
                      ? "w--current"
                      : "")
                  }
                >
                  SEQUENTIAL
                </Link>
                <Link
                  to="/graphicNovel/inking"
                  className={
                    "filterhomelink v2 mb-3 " +
                    (props.currentGraphical === "inking" ? "w--current" : "")
                  }
                >
                  INKING
                </Link>
                <Link
                  to="/graphicNovel/coloring"
                  className={
                    "filterhomelink v2 mb-3 " +
                    (props.currentGraphical === "coloring" ? "w--current" : "")
                  }
                >
                  COLORING
                </Link>
                <Link
                  to="/graphicNovel/lettering"
                  className={
                    "filterhomelink v2 mb-1 " +
                    (props.currentGraphical === "lettering" ? "w--current" : "")
                  }
                >
                  LETTERING
                </Link>
              </div>
            </div>
            <Link
              to="/motions"
              className={
                "filterhomelink v2 " +
                (props.aciveBtn === "motions" || props.currentCat == "motions"
                  ? "w--current"
                  : "")
              }
            >
              MOTION
            </Link>
            <Link className="navbarDot"> · </Link>
            <Link
              to="/newest"
              className={
                "filterhomelink v2 mb-3 " +
                (props.aciveBtn === "newest"
                  ? " w--current undelinedNavbar"
                  : "")
              }
            >
              {" "}
              <span style={{ color: "#fa8e37" }}>NEW</span> ILLUSTRATORS{" "}
            </Link>
            <Link
              to="/recentlyUpdated"
              className={
                "filterhomelink v2 mb-3 " +
                (props.aciveBtn === "recentlyUpdated" ? "w--current" : "")
              }
            >
              {" "}
              <span style={{ color: "#fa8e37" }}>UPDATED</span> PORTFOLIOS{" "}
            </Link>
            <Link className="navbarDot"> · </Link>
            <Link
              to="/mentorship"
              className={
                "filterhomelink v2 mb-3 " +
                (props.aciveBtn === "mentorship"
                  ? " w--current undelinedNavbar"
                  : "")
              }
              style={{ color: "#B0B0B0" }}
            >
              {" "}
              MENTORSHIP{" "}
            </Link>
            <Link
              to="/philanthropy"
              className={
                "filterhomelink v2 mb-3 " +
                (props.aciveBtn === "philanthropy"
                  ? " w--current undelinedNavbar"
                  : "")
              }
              style={{ color: "#B0B0B0" }}
            >
              {" "}
              PHILANTHROPY{" "}
            </Link>
          </div>

          {props.searchBar ? (
            <div
              className="form-block-2"
              style={{ marginLeft: "auto", zIndex: "2" }}
            >
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                method="get"
                className="form-2"
              >
                <input
                  onChange={(e) => {
                    props.updateTempArtist(e);
                  }}
                  type="text"
                  value={props.searchArtist}
                  className="searchbarhome w-input"
                  maxLength="256"
                  name="Search"
                  data-name="Search"
                  placeholder="SEARCH"
                  id="Search"
                />
                <Link to="#" className="link-block-3 w-inline-block"></Link>
              </form>
              {searchOption && props.aciveBtn !== undefined ? (
                <ul className="searchListUl">
                  {searchOption.map((listE, index) => (
                    <li key={index}>
                      <a onClick={removeRoute} href={"/" + listE.fullName}>
                        {listE.firstname + " " + listE.lastname}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
