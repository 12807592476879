import SnackBar from "react-material-snackbar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOpen } from "../../redux/message";

const SnackbarCustom = (props) => {
  const { message } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(function changerState() {
      dispatch(updateOpen(false));
    }, 4000);
  }, [message]);
  return (
    <div
      style={{
        position: "fixed", // Makes the snackbar fixed relative to the viewport
        left: "50%", // Centers the snackbar horizontally
        bottom: "20px", // Positions the snackbar at the bottom of the viewport
        // Ensures it's centered properly
        zIndex: "10000", // Ensures it stays on top of all other content
        width: "auto", // Adjust width as necessary
        maxWidth: "90%", // Avoids the snackbar being too wide on small screens
      }}
    >
      <SnackBar show={message.open} timer={4000}>
        <label>{message.message.toUpperCase()}</label>
      </SnackBar>
    </div>
  );
};

export default SnackbarCustom;
