import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Table, {
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from "../../../components/table/table";

import MyPopup from "../../../components/myPopup/myPopup";

import loading from "../../../assets/loading.gif";
function AgentsList(props) {
  const [isPopupShow, setIsPopupShow] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(false);
  const [characters, updateCharacters] = useState([]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateCharacters(items);
  };
  useEffect(() => {
    updateCharacters(props.agentUsers);
  }, [props.agentUsers]);
  return (
    <>
      <button className={"mx-3 mb-3 myBtn"} type="text">
        ALL MYSHANNON USERS
      </button>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="charactersStuff">
          {(provided) => (
            <Table height="calc(100vh - 350px)" provided={provided}>
              <THead>
                <Th minWidth="120">FirstName</Th>
                <Th minWidth="120">LastName</Th>

                <Th minWidth="120">Status</Th>
                <Th width="110"></Th>
              </THead>
              <TBody>
                {props.agentUsers.length > 0
                  ? props.search !== ""
                    ? props.tempAgent.map((item, key) => (
                        <Tr key={key}>
                          <Td>{item.firstname}</Td>
                          <Td>{item.lastname}</Td>

                          <Td
                            className={
                              item.status === 1 ? "text-success" : "text-danger"
                            }
                          >
                            {item.status === 1 ? "Active" : "Inactive"}
                          </Td>
                          <Td className="d-flex">
                            <button
                              onClick={() => props.formChangeEvent(item)}
                              className="mx-1 myBtn"
                              type="text"
                            >
                              EDIT
                            </button>
                            {props.holder ? (
                              <img
                                className="mt-1"
                                alt="loading"
                                src={loading}
                                style={{ width: "30px" }}
                              />
                            ) : (
                              <button
                                className="mx-1 myBtn active"
                                type="text"
                                onClick={(e) => {
                                  setIsPopupShow(true);
                                  setSelectedAgent(item);
                                }}
                              >
                                DELETE
                              </button>
                            )}
                          </Td>
                        </Tr>
                      ))
                    : characters.map((item, key) => (
                        <Draggable
                          key={item._id}
                          draggableId={item._id}
                          index={key}
                        >
                          {(provided) => (
                            <Tr key={key} provided={provided}>
                              <Td>{item.firstname}</Td>
                              <Td>{item.lastname}</Td>
                              <Td
                                className={
                                  item.status === 1
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {item.status === 1 ? "Active" : "Inactive"}
                              </Td>
                              <Td className="d-flex">
                                <button
                                  onClick={() => props.formChangeEvent(item)}
                                  className="mx-1 myBtn"
                                  type="text"
                                >
                                  EDIT
                                </button>
                                {props.holder ? (
                                  <img
                                    className="mt-1"
                                    alt="loading"
                                    src={loading}
                                    style={{ width: "30px" }}
                                  />
                                ) : (
                                  <button
                                    className="mx-1 myBtn active"
                                    type="text"
                                    onClick={(e) => {
                                      setIsPopupShow(true);
                                      setSelectedAgent(item);
                                    }}
                                  >
                                    DELETE
                                  </button>
                                )}
                              </Td>
                            </Tr>
                          )}
                        </Draggable>
                      ))
                  : ""}
              </TBody>
              {provided.placeholder}
            </Table>
          )}
        </Droppable>
      </DragDropContext>

      {isPopupShow ? (
        <MyPopup
          BackClose
          onClose={() => {
            setIsPopupShow(false);
          }}
        >
          <div className="mx-5 my-2">
            <>
              Are you sure you want to delete "{selectedAgent.firstname}{" "}
              {selectedAgent.lastname}" profile,
              <br />
              <div className="my-4">this action can't be undone.</div>
            </>
            <div
              className="mx-5 my-2 d-flex"
              style={{ justifyContent: "space-between" }}
            >
              <button
                className="mx-1 myBtn"
                type="text"
                style={{ background: "red", padding: "10px 15px" }}
                onClick={(e) => {
                  props.deleteAgentUser(e, selectedAgent._id);
                  setIsPopupShow(false);
                }}
              >
                DELETE
              </button>
              <button
                className="mx-1 myBtn"
                type="text"
                style={{ background: "#ffb71b", padding: "10px 15px" }}
                onClick={(e) => {
                  setIsPopupShow(false);
                }}
              >
                CANCEL
              </button>
            </div>
          </div>
        </MyPopup>
      ) : null}
    </>
  );
}

export default AgentsList;
