import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import { updateMessage, updateOpen } from "../../redux/message";
import AddImage from "../../assets/svgs/addImage.svg";
import BackIconForm from "../../assets/svgs/backIconForm.svg";

import PlusIcon from "../../assets/svgs/plusIcon.svg";
import PdfIcon from "../../assets/img/pdfIcon.png";
import AddMoreInArray from "../../assets/svgs/addMoreInArray.svg";
import CrossIcon from "../../assets/svgs/crossIcon.svg";
import CrossButton from "../../assets/svgs/crossButton.svg";
import CrossButtonBlack from "../../assets/svgs/crossButtonBlack.svg";
import unHide from "../../assets/img/icons8-hide-48.png";
import hide from "../../assets/img/icons8-eye-64.png";
import loadingUpdate from "../../assets/loading_trasnparent.gif";
import {
  artistPropertyDeleteByAdmin,
  artistPropertyUpdateByAdmin,
  getSingleArtist,
  uploadFiles,
  uploadPdfFunction,
} from "../../AxiosFunctions/Axiosfunctionality";
import MyPopup from "../../components/myPopup/myPopup";

const defaultFormFields = {
  title: "",
  author: "",
  illustrator: "",
  description: "",
  similarTitles: "",
  synopsis: "",
  categorySimple: "",
};
const initialCategory = {
  categoryKeyProperty: "",
  categoryValueProperty: null,
};
const PropertiesUpdate = ({ selectedArtist }) => {
  const [level, setLevel] = useState(0);
  const [properties, setProperties] = useState(selectedArtist.properties);
  const [currentProperty, setCurrentProperty] = useState();
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [loader, setLoader] = useState(false);
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [deleteModalInactive, setDeleteModalInactive] = useState(false);
  const [mainimageUpload, setMainImageUpload] = useState(null);
  const [mainimageUrl, setMainImageUrl] = useState(null);
  const [pdfUpload, setPdfUpload] = useState(null);
  const [pdfDetails, setPdfDetails] = useState({ name: null, url: null });
  const [pdfDummyUpload, setPdfDummyUpload] = useState(null);
  const [pdfDummyDetails, setPdfDummyDetails] = useState({
    name: null,
    url: null,
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [categories, setCategories] = useState([initialCategory]);

  const {
    title,
    author,
    illustrator,
    description,
    similarTitles,
    synopsis,
    categorySimple,
  } = formFields;
  const dispatch = useDispatch();
  useEffect(() => {
    setLevel(0);
    setCurrentProperty(null);
  }, []);
  const validateForm = () => {
    let errors = {};
    if (!title.trim()) errors.title = "Title is required";
    if (!mainimageUrl && !mainimageUpload)
      errors.mainImage = "Main image is required";

    setValidationErrors(errors);

    // Return true if no errors, false otherwise
    return Object.keys(errors).length === 0;
  };
  const handleLevelChange = (property) => {
    setLevel(1);
    setCurrentProperty(property);
  };

  const handleBackButton = () => {
    setLevel(0);
    setCurrentProperty(null);
  };

  const handleDeleteProperty = async (selectedProperty) => {
    try {
      await artistPropertyDeleteByAdmin({
        idProperty: selectedProperty._id,
        artistId: selectedArtist._id,
      });
      const currentArt = await getSingleArtist({ _id: selectedArtist._id });
      dispatch(updateOpen(true));
      dispatch(updateMessage("Deleted Successfully"));

      setLoader(false);
      setProperties(currentArt.properties);
    } catch (err) {
      dispatch(updateOpen(true));

      dispatch(updateMessage(err.message));
      setLoader(false);
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { color: ["black", "red", "blue", "green"] },
      ],
      ["link"],
      ["clean"],
    ],
  };

  useEffect(() => {
    if (currentProperty) {
      setFormFields({
        title: currentProperty.titleProperty,
        author: currentProperty.authorProperty,
        illustrator: currentProperty.illustratorProperty,
        description: currentProperty.descriptionProperty,
        similarTitles: currentProperty.similarTitlesProperty,
        synopsis: currentProperty.synopsisProperty,
        category: currentProperty.extraProperties,
        categorySimple: currentProperty.categoryProperty,
      });
      setCategories(currentProperty.extraProperties);

      setMainImageUrl(currentProperty.propertyMainImage || null);
      setPdfDetails(currentProperty.propertyPdf || null);
      setPdfDummyDetails(currentProperty.propertyDummyPdf || null);
    } else {
      setFormFields(defaultFormFields);
      setMainImageUrl(null);

      setCategories([initialCategory]);
      setMainImageUpload(null);
      setPdfUpload(null);
      setPdfDetails(null);
      setPdfDummyDetails(null);
      setCurrentProperty(null);
    }
  }, [currentProperty]);

  const handleMainFileChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setMainImageUrl(null);
      setMainImageUpload(event.target.files[0]);
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        mainImage: undefined,
      }));
    }
  };
  const handlePdfFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setPdfUpload(file);
      const url = URL.createObjectURL(file);
      setPdfDetails({ name: file.name, url: url });
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        pdfFile: undefined,
      }));
    }
  };

  const handlePdfDummyFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setPdfDummyUpload(file);
      const url = URL.createObjectURL(file);
      setPdfDummyDetails({ name: file.name, url: url });
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        pdfFile: undefined,
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });

    let fieldError = "";
    if (name === "title" && !value.trim()) {
      fieldError = "Title is required";
    } else if (name === "category" && !value) {
      fieldError = "Category is required";
    }

    // Update validation errors state
    if (fieldError) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldError,
      }));
    } else {
      setValidationErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name]; // Remove the error entry if validation passes
        return updatedErrors;
      });
    }
  };
  const handleChangeText = (text) => {
    text = text !== "<p><br></p>" ? text : "";
    setFormFields((prev) => ({ ...prev, synopsis: text }));
  };
  const makeId = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    if (!validateForm()) {
      setLoader(false);
      return;
    }
    const idProperty = currentProperty ? currentProperty.id : makeId(24);

    const updatedCategories = await Promise.all(
      categories.map(async (category, index) => {
        // Process files if there are any
        if (category.files) {
          const urls = await uploadFiles(category.files); // uploadFiles should be implemented to handle the upload and return URLs
          delete category.files;

          return {
            ...category,
            categoryValueProperty:
              category.categoryKeyProperty === "carousel" ? urls : urls[0],
          }; // Clear file data after upload
        }
        return category; // Return the category as is if there's no file to upload
      })
    );

    let propertyMainImage, propertyPdf, propertyDummyPdf; // Initialize variable outside of the constant structure

    // Handling asynchronous file upload
    if (mainimageUpload) {
      const urls = await uploadFiles([mainimageUpload]); // Assumed this returns an array of URLs
      propertyMainImage = urls[0]; // Assuming you want to append the first URL
    } else if (mainimageUrl) {
      propertyMainImage = mainimageUrl; // Use existing main image URL if upload is not needed
    }
    if (pdfUpload) {
      const urls = await uploadPdfFunction(pdfUpload); // Assumed this returns an array of URLs
      propertyPdf = JSON.stringify(urls); // Assuming you want to append the first URL
    } else {
      propertyPdf = JSON.stringify(pdfDetails); // Use existing main image URL if upload is not needed
    }

    if (pdfDummyUpload) {
      const urlsDummy = await uploadPdfFunction(pdfDummyUpload);
      propertyDummyPdf = JSON.stringify(urlsDummy);
    } else {
      propertyDummyPdf = JSON.stringify(pdfDummyDetails);
    }

    const formDataEntries = {
      artistId: selectedArtist?._id,
      updateType: "properties",
      titleProperty: title,
      authorProperty: author,
      descriptionProperty: description,
      illustratorProperty: illustrator,
      similarTitlesProperty: similarTitles,
      synopsisProperty: synopsis,
      categoryProperty: categorySimple,
      extraProperties: JSON.stringify(updatedCategories),

      ...(propertyMainImage && { propertyMainImage }), // Add propertyMainImage only if it's defined
      ...(propertyPdf && { propertyPdf }), // Add propertyMainImage only if it's defined
      ...(propertyDummyPdf && { propertyDummyPdf }), // Add propertyMainImage only if it's defined
      ...(idProperty && { idProperty }), // Add idProperty only if it's defined
    };
    try {
      await artistPropertyUpdateByAdmin(formDataEntries);
      const currentArt = await getSingleArtist({ _id: selectedArtist._id });
      dispatch(updateOpen(true));
      dispatch(updateMessage("Saved Successfully"));

      setLoader(false);
      setProperties(currentArt.properties);
      handleBackButton();
    } catch (err) {
      dispatch(updateOpen(true));

      dispatch(updateMessage(err.message));
      setLoader(false);
    }
    setLoader(false);
  };
  const handleFileChange = (index, event) => {
    const newFiles = event.target.files; // This is a FileList, not a regular array

    if (newFiles.length) {
      const updatedCategories = [...categories];

      // Convert FileList to an array and append new files
      updatedCategories[index].files = [
        ...(updatedCategories[index].files || []),
        ...Array.from(newFiles), // Convert FileList to an array
      ];

      setCategories(updatedCategories);
    }
  };
  const handleTextChange = (text, index) => {
    text = text !== "<p><br></p>" ? text : "";

    const newCategories = [...categories];
    newCategories[index].categoryValueProperty = text; // Store text data
    setCategories(newCategories);
  };
  const handleCategoryChange = (index, value) => {
    const newCategories = categories.map((category, i) => {
      if (i === index) {
        delete category.files;

        return {
          ...category,
          categoryKeyProperty: value,
          categoryValueProperty: null,
        };
      }
      return category;
    });
    setCategories(newCategories);
  };

  const addCategory = () => {
    setCategories([...categories, initialCategory]);
  };

  const removeCategory = (index) => {
    const newCategories = categories.filter((_, i) => i !== index);
    setCategories(newCategories);
  };

  const handleStatusChange = async (property, status) => {
    const formDataEntries = {
      artistId: selectedArtist?._id,
      updateType: "properties",
      titleProperty: property.titleProperty,
      authorProperty: property.authorProperty,
      descriptionProperty: property.descriptionProperty,
      illustratorProperty: property.illustratorProperty,
      similarTitlesProperty: property.similarTitlesProperty,
      synopsisProperty: property.synopsisProperty,
      categoryProperty: property.categoryProperty,
      extraProperties: JSON.stringify(property.extraProperties),
      statusProperty: status,
      idProperty: property.id,
      propertyMainImage: property.propertyMainImage || "",
      propertyPdf: JSON.stringify(property.propertyPdf) || "null", // Add propertyMainImage only if it's defined
      propertyDummyPdf: JSON.stringify(property.propertyDummyPdf) || "null", // Add propertyMainImage only if it's defined
    };
    try {
      await artistPropertyUpdateByAdmin(formDataEntries);
      const currentArt = await getSingleArtist({ _id: selectedArtist._id });
      dispatch(updateOpen(true));
      dispatch(updateMessage("Saved Successfully"));
      setProperties(currentArt.properties);
    } catch (err) {
      dispatch(updateOpen(true));
      dispatch(updateMessage(err.message));
    }
  };

  return (
    <>
      {level === 0 ? (
        <div className="px-1 px-md-5 artist mb-5">
          <h4> PROPERTIES </h4>
          <h4> ACTIVE </h4>
          <div className="_4cols-v2">
            <label
              className="artistcardAdmin w-inline-block addImageBtn"
              onClick={() => handleLevelChange(null)}
            >
              <img alt="" src={AddImage} className="addImage" />
            </label>
            {properties
              ? [...properties]
                  .reverse()
                  .filter((property) => property.status === "1")
                  .map((property, ind) => (
                    <div
                      className="artistcardAdmin w-inline-block"
                      style={{ cursor: "pointer" }}
                      key={ind}
                    >
                      <div className="crossSection">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12px"
                          height="12px"
                          viewBox="0 0 352 512"
                          onClick={() => setDeleteModalActive(true)}
                        >
                          <path
                            fill="grey"
                            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                          />
                        </svg>
                        <img
                          src={property.status === "0" ? unHide : hide}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginTop: "1vh",
                          }}
                          onClick={() => handleStatusChange(property, "0")}
                        />
                      </div>
                      <img
                        onClick={() => handleLevelChange(property)}
                        src={property.propertyMainImage}
                        alt={property.propertyTitle}
                        className="image"
                      />
                      {deleteModalActive && (
                        <MyPopup
                          BackClose
                          onClose={() => {
                            setDeleteModalActive(false);
                          }}
                        >
                          <div className="mx-5 my-2">
                            <>
                              Are you sure you want to delete this element?
                              <br />
                              <div className="my-4">
                                This action can't be undone.
                              </div>
                            </>
                            <div
                              className="mx-5 my-2 d-flex"
                              style={{ justifyContent: "space-between" }}
                            >
                              <button
                                className="mx-1 myBtn"
                                type="text"
                                style={{
                                  background: "red",
                                  padding: "10px 15px",
                                }}
                                onClick={() => handleDeleteProperty(property)}
                              >
                                DELETE
                              </button>
                              <button
                                className="mx-1 myBtn"
                                type="text"
                                style={{
                                  background: "#ffb71b",
                                  padding: "10px 15px",
                                }}
                                onClick={(e) => {
                                  setDeleteModalActive(false);
                                }}
                              >
                                CANCEL
                              </button>
                            </div>
                          </div>
                        </MyPopup>
                      )}
                    </div>
                  ))
              : null}
          </div>
          <h4> INACTIVE </h4>
          <div className="_4cols-v2">
            {properties
              ? [...properties]
                  .reverse()
                  .filter((property) => property.status === "0")
                  .map((property, ind) => (
                    <div
                      className="artistcardAdmin w-inline-block"
                      style={{ cursor: "pointer" }}
                      key={ind}
                    >
                      <div className="crossSection">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12px"
                          height="12px"
                          viewBox="0 0 352 512"
                          onClick={() => setDeleteModalInactive(true)}
                        >
                          <path
                            fill="grey"
                            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                          />
                        </svg>
                        <img
                          src={property.status ? unHide : hide}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginTop: "1vh",
                          }}
                          onClick={() => handleStatusChange(property, "1")}
                        />
                      </div>
                      <img
                        onClick={() => handleLevelChange(property)}
                        src={property.propertyMainImage}
                        alt={property.propertyTitle}
                        className="image"
                      />
                      {deleteModalInactive && (
                        <MyPopup
                          BackClose
                          onClose={() => {
                            setDeleteModalInactive(false);
                          }}
                        >
                          <div className="mx-5 my-2">
                            <>
                              Are you sure you want to delete this element?
                              <br />
                              <div className="my-4">
                                This action can't be undone.
                              </div>
                            </>
                            <div
                              className="mx-5 my-2 d-flex"
                              style={{ justifyContent: "space-between" }}
                            >
                              <button
                                className="mx-1 myBtn"
                                type="text"
                                style={{
                                  background: "red",
                                  padding: "10px 15px",
                                }}
                                onClick={() => handleDeleteProperty(property)}
                              >
                                DELETE
                              </button>
                              <button
                                className="mx-1 myBtn"
                                type="text"
                                style={{
                                  background: "#ffb71b",
                                  padding: "10px 15px",
                                }}
                                onClick={(e) => {
                                  setDeleteModalInactive(false);
                                }}
                              >
                                CANCEL
                              </button>
                            </div>
                          </div>
                        </MyPopup>
                      )}
                    </div>
                  ))
              : null}
          </div>
        </div>
      ) : (
        level === 1 && (
          <div className="bioArtistContainer" style={{ paddingLeft: "100px" }}>
            <h4 style={{ marginBottom: "0px", fontSize: "1.2rem" }}>
              PROPERTIES
            </h4>
            <img
              onClick={handleBackButton}
              alt="back"
              src={BackIconForm}
              style={{ width: "40px", cursor: "pointer" }}
            />
            <p
              style={{
                marginBottom: "30px",
                fontWeight: "300",
                lineHeight: "1.5",
                fontSize: "0.8rem",
              }}
            >
              A Property is an original story or idea shown in sketches or
              "dummy book" form that we will try to sell to a Publisher or
              investor. Please only upload images and stories that you own
              copryright to, or that are in the public domain. Remember, this is
              going to be seen by editors and art directors who will need to get
              approve by a discerning acquisitions team (sales / marketing /
              editors / art directories / publishers) before the story can be
              acquire or an offer made. This is normally a long process, but the
              more work you put into your story, the better it will show as a
              workable and viable product.
            </p>
            <h4 style={{ marginBottom: "0px", fontSize: "1.2rem" }}>
              PROJECT INFORMATION
            </h4>
            <form onSubmit={handleSubmit}>
              <label htmlFor="pw_cover" className="labelsBio">
                COVER / MAIN IMAGE
              </label>
              <p
                style={{
                  marginBottom: "30px",
                  fontWeight: "300",
                  lineHeight: "1.5",
                  fontSize: "0.8rem",
                }}
              >
                Consider uploading an image that has a title or cover design.
                Remember you are pitching an idea to sell to the market - think
                it through and look professional.
              </p>
              {mainimageUrl && mainimageUrl !== "null" ? (
                <div className="imageView" style={{ width: "fit-content" }}>
                  <img
                    className="CrossButton"
                    alt="CrossButton"
                    src={CrossButton}
                    onClick={() => {
                      setMainImageUpload(null);
                      setMainImageUrl(null);
                    }}
                    style={{ backgroundColor: "gray" }}
                  />
                  <img
                    className="imageUploaded"
                    alt="PlusIcon"
                    src={mainimageUrl}
                  />
                </div>
              ) : mainimageUpload ? (
                <div className="imageView" style={{ width: "fit-content" }}>
                  <img
                    className="CrossButton"
                    alt="CrossButton"
                    src={CrossButton}
                    onClick={() => {
                      setMainImageUpload(null);
                    }}
                    style={{ backgroundColor: "gray" }}
                  />
                  <img
                    className="imageUploaded"
                    alt="PlusIcon"
                    src={URL.createObjectURL(mainimageUpload)}
                  />
                </div>
              ) : (
                <label className="uploadArtistImage">
                  <img alt="PlusIcon" src={PlusIcon} />
                  <h5>UPLOAD IMAGE</h5>
                  <input
                    hidden
                    type="file"
                    onChange={handleMainFileChangeImage}
                    accept="image/*"
                  />
                </label>
              )}
              {validationErrors.mainImage && (
                <p style={{ color: "red" }}>{validationErrors.mainImage}</p>
              )}

              <div className="publishWorkInputContainer">
                <div className="inputContainer">
                  <label htmlFor="pw_title" className="labelsBio">
                    Title
                  </label>
                  <div className="socialMediaContainerBio">
                    <input
                      type="text"
                      placeholder="Title"
                      value={title}
                      name="title"
                      className="textField"
                      onChange={handleChange}
                    />
                  </div>
                  {validationErrors.title && (
                    <p style={{ color: "red" }}>{validationErrors.title}</p>
                  )}
                </div>
                <div className="inputContainer">
                  <label htmlFor="pw_author" className="labelsBio">
                    Author
                  </label>
                  <div className="socialMediaContainerBio">
                    <input
                      type="text"
                      placeholder="Author"
                      value={author}
                      name="author"
                      className="textField"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="inputContainer">
                  <label htmlFor="pw_illustrator" className="labelsBio">
                    Illustrator
                  </label>
                  <div className="socialMediaContainerBio">
                    <input
                      type="text"
                      placeholder="Illustrator"
                      value={illustrator}
                      name="illustrator"
                      className="textField"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="inputContainer">
                  <label htmlFor="pw_synopsis" className="labelsBio">
                    Category
                  </label>
                  <select
                    className="textField"
                    style={{ padding: "9.5px 15px" }}
                    onChange={handleChange}
                    value={categorySimple}
                    name="categorySimple"
                  >
                    <option value="">Select Category Type</option>
                    <option value="Picture Book">Picture Book</option>
                    <option value="Board Book">Board Book</option>
                    <option value="Early Reader">Early Reader</option>
                    <option value="Middle Grade">Middle Grade</option>
                    <option value="Graphic Novel">Graphic Novel</option>
                    <option value="Young Adult">Young Adult</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="inputContainer" style={{ width: "100%" }}>
                  <label htmlFor="property_description" className="labelsBio">
                    Brief Description
                  </label>
                  <p
                    style={{
                      marginBottom: "10px",
                      fontWeight: "300",
                      lineHeight: "1.5",
                      fontSize: "0.8rem",
                    }}
                  >
                    Come up with a few sentences that describe what your project
                    is about. Keep it simple, but make it interesting -
                    something YOU would want to purchase or buy.
                  </p>
                  <div className="socialMediaContainerBio">
                    <input
                      type="text"
                      placeholder="Description"
                      value={description}
                      name="description"
                      className="textField"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="inputContainer" style={{ width: "100%" }}>
                  <label htmlFor="pw_similarTitles" className="labelsBio">
                    Similar Titles
                  </label>
                  <p
                    style={{
                      marginBottom: "10px",
                      fontWeight: "300",
                      lineHeight: "1.5",
                      fontSize: "0.8rem",
                    }}
                  >
                    Editors like to see where you think your project fits in the
                    publishing market. Do research and come up with a few
                    similiar title that make it easier for the acquisitions team
                    to see where your book could sell.
                  </p>
                  <div className="socialMediaContainerBio">
                    <input
                      type="text"
                      placeholder="Similar Titles"
                      value={similarTitles}
                      name="similarTitles"
                      className="textField"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="bio_desc_container" style={{ width: "100%" }}>
                  <label htmlFor="pw_synopsis" className="labelsBio">
                    Synopsis
                  </label>
                  <p
                    style={{
                      marginBottom: "10px",
                      fontWeight: "300",
                      lineHeight: "1.5",
                      fontSize: "0.8rem",
                    }}
                  >
                    Use this space to give more information on the book project.
                    (If you have no more information - this space will not show
                    up)
                  </p>
                  <ReactQuill
                    value={synopsis}
                    onChange={handleChangeText}
                    modules={modules}
                  />
                </div>

                <div style={{ width: "100%", marginBottom: "20px" }}>
                  <label htmlFor="manuscript_upload" className="labelsBio">
                    PDF of Manuscript
                  </label>
                  <p
                    style={{
                      marginBottom: "30px",
                      fontWeight: "300",
                      lineHeight: "1.5",
                      fontSize: "0.8rem",
                    }}
                  >
                    Upload a PDF of your manuscript. Many editors would like to
                    see a "text only" version of your story or book idea.
                  </p>
                  <div className="inputContainer">
                    {pdfDetails && pdfDetails.url && pdfDetails.name ? (
                      <>
                        <div
                          className="imageView"
                          style={{ width: "fit-content" }}
                        >
                          <img
                            className="CrossButton"
                            alt="CrossButton"
                            src={CrossButtonBlack}
                            onClick={() => {
                              setPdfDetails(null);
                              setPdfUpload(null);
                            }}
                          />

                          <a
                            href={pdfDetails.url}
                            download={pdfDetails.name}
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <img
                              className="pdfIcon"
                              alt="PDF icon"
                              src={PdfIcon}
                              style={{ height: "120px" }} // Adjust size as needed
                            />
                          </a>
                          <p
                            className="pdfName"
                            style={{
                              width: "fit-content",

                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            {pdfDetails.name}
                          </p>
                        </div>
                      </>
                    ) : (
                      <label className="uploadPdfLabel">
                        <img alt="PlusIcon" src={PlusIcon} />
                        <h5>UPLOAD PDF</h5>
                        <input
                          hidden
                          type="file"
                          id="pdf_upload"
                          onChange={handlePdfFileChange}
                          accept="application/pdf"
                        />
                      </label>
                    )}
                  </div>
                </div>

                <div style={{ width: "100%" }}>
                  <label htmlFor="manuscript_upload" className="labelsBio">
                    Book Dummy
                  </label>
                  <div className="inputContainer">
                    {pdfDummyDetails &&
                    pdfDummyDetails.url &&
                    pdfDummyDetails.name ? (
                      <>
                        <div
                          className="imageView"
                          style={{ width: "fit-content" }}
                        >
                          <img
                            className="CrossButton"
                            alt="CrossButton"
                            src={CrossButtonBlack}
                            onClick={() => {
                              setPdfDummyDetails(null);
                              setPdfDummyUpload(null);
                            }}
                          />

                          <a
                            href={pdfDummyDetails.url}
                            download={pdfDummyDetails.name}
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <img
                              className="pdfIcon"
                              alt="PDF icon"
                              src={PdfIcon}
                              style={{ height: "120px" }} // Adjust size as needed
                            />
                          </a>
                          <p
                            className="pdfName"
                            style={{
                              width: "fit-content",

                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                          >
                            {pdfDummyDetails.name}
                          </p>
                        </div>
                      </>
                    ) : (
                      <label
                        className="uploadPdfLabel"
                        style={{ cursor: "pointer" }}
                      >
                        <img alt="PlusIcon" src={PlusIcon} />
                        <h5>UPLOAD PDF</h5>
                        <input
                          hidden
                          type="file"
                          id="pdf_upload"
                          onChange={handlePdfDummyFileChange}
                          accept="application/pdf"
                        />
                      </label>
                    )}
                  </div>
                </div>

                <div className="inputContainer" style={{ width: "100%" }}>
                  <label htmlFor="pw_category" className="labelsBio">
                    Properties Extra Content
                  </label>
                  <p
                    style={{
                      marginBottom: "10px",
                      fontWeight: "300",
                      lineHeight: "1.5",
                      fontSize: "0.8rem",
                    }}
                  >
                    Use this additional "block" sections to help present your
                    idea. "TEXT" is using words. "IMAGE" is additional image
                    that you have crafted to fit this space. One image, or a
                    collaboration of images uploaded design together. "BANNER"
                    image is a fixed, thin, banner image that runs across this
                    space.
                  </p>
                  {categories.map((category, index) => (
                    <div key={index} style={{ marginBottom: "10px" }}>
                      <select
                        value={category.categoryKeyProperty}
                        className="textField"
                        style={{ maxWidth: "300px" }}
                        onChange={(e) =>
                          handleCategoryChange(index, e.target.value)
                        }
                      >
                        <option value="">Select Extra Content Type</option>
                        <option value="text">Text</option>
                        <option value="banner">Banner</option>
                        <option value="carousel">Carousel</option>
                      </select>
                      {categories.length > 1 && (
                        <span
                          className="addMoreInArray"
                          onClick={() => removeCategory(index)}
                        >
                          <img
                            style={{ maxWidth: "40px", marginLeft: "10px" }}
                            alt="CrossIcon"
                            src={CrossIcon}
                          />
                        </span>
                      )}
                      {category.categoryKeyProperty === "text" && (
                        <div className="bio_desc_container">
                          <ReactQuill
                            value={category.categoryValueProperty}
                            onChange={(text) => handleTextChange(text, index)}
                            modules={modules}
                          />
                        </div>
                      )}

                      {category.categoryKeyProperty === "banner" &&
                        (category.files ? (
                          <div className="imageView" style={{ width: "35%" }}>
                            <img
                              className="CrossButton"
                              alt="CrossButton"
                              src={CrossButton}
                              onClick={() => {
                                const newCategories = [...categories];
                                delete newCategories[index].files;
                                setCategories(newCategories);
                              }}
                              style={{ backgroundColor: "gray" }}
                            />
                            <img
                              className=""
                              alt="Banner preview"
                              src={URL.createObjectURL(category.files[0])}
                            />
                          </div>
                        ) : category.categoryValueProperty ? (
                          <div className="imageView">
                            <img
                              className="CrossButton"
                              alt="CrossButton"
                              src={CrossButton}
                              onClick={() => {
                                const newCategories = [...categories];
                                delete newCategories[index].files;
                                setCategories(newCategories);
                              }}
                              style={{ backgroundColor: "gray" }}
                            />
                            <img
                              className=""
                              alt="Banner preview"
                              src={category.categoryValueProperty}
                            />
                          </div>
                        ) : (
                          <label className="uploadArtistImage">
                            <img alt="PlusIcon" src={PlusIcon} />
                            <h5>UPLOAD IMAGE</h5>
                            <input
                              hidden
                              type="file"
                              onChange={(e) => handleFileChange(index, e)}
                              accept="image/*"
                            />
                          </label>
                        ))}

                      {category.categoryKeyProperty === "carousel" && (
                        <>
                          <div
                            className="imageGallery"
                            style={{
                              display: "flex",
                              flexWrap: "wrap",

                              alignItems: "center",
                            }}
                          >
                            <label className="uploadArtistImage">
                              <img alt="PlusIcon" src={PlusIcon} />
                              <h5>UPLOAD IMAGES</h5>
                              <input
                                hidden
                                type="file"
                                multiple
                                onChange={(e) => handleFileChange(index, e)}
                                accept="image/*"
                              />
                            </label>
                            {category.categoryValueProperty &&
                              category.categoryValueProperty.map(
                                (url, urlIndex) => (
                                  <div
                                    key={urlIndex}
                                    className="imageView"
                                    style={{
                                      border: "1px black solid",
                                      margin: " 4px",
                                      height: "100%",
                                    }}
                                  >
                                    <img
                                      className="CrossButton"
                                      alt="Remove"
                                      src={CrossButton}
                                      onClick={() => {
                                        const newCategories = [...categories];
                                        newCategories[
                                          index
                                        ].categoryValueProperty.splice(
                                          urlIndex,
                                          1
                                        ); // Remove this URL
                                        setCategories(newCategories);
                                      }}
                                      style={{ backgroundColor: "gray" }}
                                    />
                                    <img
                                      className="imageUploaded"
                                      alt={`Carousel preview ${urlIndex}`}
                                      src={url}
                                    />
                                  </div>
                                )
                              )}
                            {category.files &&
                              category.files.map((file, fileIndex) => {
                                if (file instanceof Blob) {
                                  // Only preview if it's a file to be uploaded
                                  return (
                                    <div
                                      key={`new-${fileIndex}`}
                                      className="imageView"
                                      style={{
                                        border: "1px black solid",
                                        margin: " 4px",
                                        height: "100%",
                                      }}
                                    >
                                      <img
                                        className="CrossButton"
                                        alt="Remove"
                                        src={CrossButton}
                                        onClick={() => {
                                          const newCategories = [...categories];
                                          // Remove the file from files
                                          newCategories[index].files.splice(
                                            fileIndex,
                                            1
                                          );
                                          setCategories(newCategories);
                                        }}
                                        style={{ backgroundColor: "gray" }}
                                      />
                                      <img
                                        className="imageUploaded"
                                        alt={`New Carousel preview ${fileIndex}`}
                                        src={URL.createObjectURL(file)}
                                      />
                                    </div>
                                  );
                                }
                                return null;
                              })}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                  {validationErrors.category && (
                    <p style={{ color: "red" }}>{validationErrors.category}</p>
                  )}
                  <br />
                  <div
                    className="addMoreInArray"
                    style={{ marginBottom: "20px" }}
                    onClick={addCategory}
                  >
                    <img alt="PlusIcon" src={AddMoreInArray} />
                  </div>
                </div>

                <div className="d-flex mt-4">
                  {loader ? (
                    <img
                      alt="loading"
                      src={loadingUpdate}
                      style={{ width: "40px" }}
                    />
                  ) : (
                    <button className="mx-1 myBtn active sm">SUBMIT</button>
                  )}
                </div>
              </div>
            </form>
          </div>
        )
      )}
    </>
  );
};

export default PropertiesUpdate;
