import loadingUpdate from "../../assets/loading_trasnparent.gif";
import React, { useEffect, useState } from "react";
import PlusIcon from "../../assets/svgs/plusIcon.svg";
import AddMoreInArray from "../../assets/svgs/addMoreInArray.svg";
import CrossIcon from "../../assets/svgs/crossIcon.svg";
import CrossButton from "../../assets/svgs/crossButton.svg";
import { artistBioApprove } from "../../AxiosFunctions/Axiosfunctionality";
import { useDispatch } from "react-redux";
import { updateMessage, updateOpen } from "../../redux/message";
import {
  findSingleContact,
  getVimeoIDfromURL,
  getYoutubeIDfromURL,
} from "../../UserServices/Services";
import BackIconForm from "../../assets/svgs/backIconForm.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
function BioArtistAdmin({
  artistUpdates,
  artistUpdateId,
  setFormNo,
  populateArtistUpdates,
}) {
  const [artistUserAllDetails, setArtistUserAllDetails] = useState(null);
  const [bio, setBio] = useState("");
  const [imageUpload, setImageUpload] = useState(null);
  const [imageURL, setImageURL] = useState(null);

  const [socialMedia, setSocialMedia] = useState([
    { socialKey: "", socialValue: "" },
  ]);
  const [videoUrl, setVideoUrl] = useState({
    videoKey: "",
    videoValue: "",
    videoID: "",
  });

  const [skills, setSkills] = useState([""]);
  const [website, setWebsite] = useState([""]);
  const [pronouns, setPronouns] = useState([""]);
  const [photoCredit, setPhotoCredit] = useState("");

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({ videoError: false });
  const [bipocType, setBipocType] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (artistUpdateId) {
      let singleUpdate = findSingleContact(artistUpdates, artistUpdateId);
      setArtistUserAllDetails(singleUpdate.artistId);
      setBio(singleUpdate.bio || "");
      setImageURL(singleUpdate.artistImage || null);
      setVideoUrl(singleUpdate.videoUrl || { videoKey: "", videoValue: "" });
      setSocialMedia(
        singleUpdate.socialMedia || [{ socialKey: "", socialValue: "" }]
      );
      setSkills(singleUpdate.skills || [""]);
      setWebsite(singleUpdate.website || [""]);
      setPronouns(singleUpdate.pronouns || "");
      setPhotoCredit(singleUpdate.photoCredit || "");
      setBipocType(singleUpdate.bipocType || "");
    }
  }, [artistUpdateId]);

  const socialMediaOptions = [
    { value: "facebook", label: "Facebook" },
    { value: "instagram", label: "Instagram" },
    { value: "twitter", label: "X" },
    { value: "linkedin", label: "LinkedIn" },
    { value: "pinterest", label: "Pinterest" },
    { value: "youtube", label: "Youtube" },
    // ...other options
  ];

  const videoUrlOptions = [
    { value: "YouTube", label: "YouTube" },
    // ...other options
  ];

  const skillsOptions = [
    { value: "Photoshop", label: "Photoshop" },
    { value: "Watercolor", label: "Watercolor" },
    { value: "Oil Painting", label: "Oil Painting" },
    { value: "3D Rendering", label: "3D Rendering" },
    { value: "Vector / Illustrator", label: "Vector / Illustrator" },
    { value: "Design / Graphic Design", label: "Design / Graphic Design" },
    // ...other options
  ];

  // Handle file uploads
  const handleFileChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      // Update the imageUpload state with the file
      setImageUpload(event.target.files[0]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    let videoIDMessage;
    if (videoUrl.videoKey === "Vimeo") {
      videoIDMessage = getVimeoIDfromURL(videoUrl.videoValue);
    } else if (videoUrl.videoKey === "YouTube") {
      videoIDMessage = getYoutubeIDfromURL(videoUrl.videoValue);
    }
    if (videoIDMessage === "Invalid URL") {
      setError((prev) => {
        return { ...prev, videoError: true };
      });

      setLoader(false);
      return;
    } else {
      setError((prev) => {
        return { ...prev, videoError: false };
      });
    }
    let videoID;
    if (videoUrl.videoKey === "Vimeo") {
      videoID = "https://player.vimeo.com/video/" + videoIDMessage;
    } else if (videoUrl.videoKey === "YouTube") {
      videoID = "https://www.youtube.com/embed/" + videoIDMessage;
    }
    // Prepare the body of the request
    const formData = new FormData();
    formData.append("_id", artistUpdateId);
    formData.append("artistId", artistUserAllDetails?._id);
    formData.append("artistImageUpload", imageUpload);
    formData.append("artistImage", imageURL);
    formData.append("bio", bio);
    formData.append("socialMedia", JSON.stringify(socialMedia)); // Assuming socialMedia is an object
    formData.append(
      "videoUrl",
      JSON.stringify({ ...videoUrl, videoID: videoID || "" })
    ); // Assuming socialMedia is an object
    formData.append("skills", JSON.stringify(skills)); // Assuming skills is an array or object
    formData.append("website", JSON.stringify(website));
    formData.append("pronouns", JSON.stringify(pronouns));
    formData.append("photoCredit", JSON.stringify(photoCredit));
    formData.append("bipocType", bipocType);

    // Send the request

    try {
      await artistBioApprove(formData);
      dispatch(updateOpen(true));

      dispatch(updateMessage("Saved Successfully"));
      await populateArtistUpdates();
      setFormNo(0);
      setLoader(false);
    } catch (err) {
      dispatch(updateOpen(true));

      dispatch(updateMessage(err.message));
      setLoader(false);
    }
  };

  const handleVideoUrlChange = (event) => {
    setVideoUrl((prev) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };
  const handleChangeText = (text) => {
    text = text !== "<p><br></p>" ? text : "";
    setBio(text);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { color: ["black", "red", "blue", "green"] },
      ],
      ["link"],
      ["clean"],
    ],
  };
  const handleSocialMediaChange = (index, event) => {
    const newSocialMedia = socialMedia.map((item, i) => {
      if (i === index) {
        return { ...item, [event.target.name]: event.target.value };
      }
      return item;
    });
    setSocialMedia(newSocialMedia);
  };

  // Function to handle changes in skills inputs
  const handleSkillsChange = (index, event) => {
    const newSkills = [...skills];
    newSkills[index] = event.target.value;
    setSkills(newSkills);
  };
  const handleWebsitesChange = (index, event) => {
    const newWebsites = [...website];
    newWebsites[index] = event.target.value;
    setWebsite(newWebsites);
  };

  const handlePronounsChange = (event) => {
    const newPronouns = event.target.value;
    setPronouns(newPronouns);
  };

  const handlePhotoCreditChange = (event) => {
    let newPhotoCredit = event.target.value;
    setPhotoCredit(newPhotoCredit);
  };

  const addMoreWebsite = () => {
    setWebsite([...website, ""]);
  };

  const handleDeleteWebsite = (index) => {
    const filteredWebsites = website.filter((_, i) => i !== index);
    setWebsite(filteredWebsites);
  };
  // Function to add more social media inputs
  const addMoreSocialMedia = () => {
    setSocialMedia([...socialMedia, { socialKey: "", socialValue: "" }]);
  };

  // Function to add more skills inputs
  const addMoreSkills = () => {
    setSkills([...skills, ""]);
  };
  const handleDeleteSocialMedia = (index) => {
    const filteredSocialMedia = socialMedia.filter((_, i) => i !== index);
    setSocialMedia(filteredSocialMedia);
  };

  // Function to delete a specific skill
  const handleDeleteSkill = (index) => {
    const filteredSkills = skills.filter((_, i) => i !== index);
    setSkills(filteredSkills);
  };
  const changeArtistBipocType = (e) => {
    if (e.target.value === bipocType) {
      setBipocType("None");
      return;
    } else {
      setBipocType(e.target.value);
    }
  };
  return (
    <div className="bioArtistContainer">
      <h4 style={{ marginBottom: "0px", fontSize: "1.2rem" }}>
        Biography Section
      </h4>
      <p
        style={{
          marginBottom: "30px",
          fontWeight: "300",
          lineHeight: "1.5",
          fontSize: "0.8rem",
        }}
      >
        We are finding that clients want to know more about who are they hiring.
        Take time to fill out this section fully, as it reflects who you are and
        proves what you can do professionally.
      </p>
      <label htmlFor="bio_img" className="labelsBio">
        Biography Photo
      </label>
      <p style={{ fontWeight: "300", lineHeight: "1.5", fontSize: "0.8rem" }}>
        May we recommend that you select a personal photo/image that will
        inspire confidence in the client to hire you.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="uploadSection" style={{ marginBottom: "10px" }}>
          {imageURL && imageURL !== "null" ? (
            <div className="imageView">
              <img
                className="CrossButton"
                alt="CrossButton"
                src={CrossButton}
                onClick={() => {
                  setImageUpload(null);
                  setImageURL(null);
                }}
                style={{ backgroundColor: "gray" }}
              />
              <img className="imageUploaded" alt="PlusIcon" src={imageURL} />
            </div>
          ) : imageUpload ? (
            <div className="imageView">
              <img
                className="CrossButton"
                alt="CrossButton"
                src={CrossButton}
                onClick={() => {
                  setImageUpload(null);
                  setImageURL(null);
                }}
                style={{ backgroundColor: "gray" }}
              />
              <img
                className="imageUploaded"
                alt="PlusIcon"
                src={URL.createObjectURL(imageUpload)}
              />
            </div>
          ) : (
            <label className="uploadArtistImage">
              <img alt="PlusIcon" src={PlusIcon} />
              <h5>UPLOAD IMAGE</h5>
              <input
                hidden
                type="file"
                onChange={handleFileChangeImage}
                accept="image/*"
              />
            </label>
          )}
        </div>
        <div style={{ marginBottom: "30px" }}>
          <label htmlFor="bio_credit" className="labelsBio">
            Photo Credit
          </label>
          <p
            style={{ fontWeight: "300", lineHeight: "1.5", fontSize: "0.8rem" }}
          >
            If the photo that you select has a credit to a photographer, please
            list here
          </p>
          <div className="socialMediaContainerBio">
            <input
              type="text"
              placeholder="Photo credit"
              value={photoCredit}
              className="textField"
              style={{ marginBottom: "5px", width: "50%" }}
              onChange={handlePhotoCreditChange}
            />
          </div>
        </div>
        <div className="inputFields">
          <div className="bio_desc_container">
            <label htmlFor="bio_desc" className="labelsBio">
              Biography
            </label>
            <p
              style={{
                marginBottom: "10px",
                fontWeight: "300",
                lineHeight: "1.5",
                fontSize: "0.8rem",
              }}
            >
              Be warm, informative and professional in the description of
              yourself and your work. There is a limit of 430 words in this
              section. <br /> Please update as things change in your life. If
              you are listing clients you have worked with, please add somethign
              like “selected list of clients" or "among others"
              <br />
            </p>
            <ReactQuill
              value={bio}
              onChange={handleChangeText}
              modules={modules}
            />
          </div>
          <div className="separator" />
          <div className="mb-4">
            <label htmlFor="bio_pronouns" className="labelsBio">
              Pronouns
            </label>
            <p
              style={{
                marginBottom: "10px",
                fontWeight: "300",
                lineHeight: "1.5",
                fontSize: "0.8rem",
              }}
            >
              Optional, but helpful to clients in knowing how to address you.
              (Example: she/her/hers) <br />
            </p>
            <div className="socialMediaContainerBio">
              <input
                type="text"
                placeholder="Pronouns"
                value={pronouns}
                className="textField"
                style={{ marginBottom: "5px", width: "50%" }}
                onChange={handlePronounsChange}
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="labelsBio">BIPOC</label>
            <p
              style={{
                marginBottom: "10px",
                fontWeight: "300",
                lineHeight: "1.5",
                fontSize: "0.8rem",
              }}
            >
              If you identify as, and would like to be shown in the BIPOC
              section of our site, please indicate here
              <br />
            </p>
            <label style={{ display: "inline" }}>
              <input
                className="mr-2"
                name="Black"
                type="radio"
                value={"Black"}
                checked={bipocType === "Black"}
                onClick={(e) => changeArtistBipocType(e)}
              />
              Black
            </label>
            <label style={{ display: "inline" }} className="px-3">
              <input
                className="mr-2"
                name="Asian"
                type="radio"
                value={"Asian"}
                checked={bipocType === "Asian"}
                onClick={(e) => changeArtistBipocType(e)}
              />
              Asian
            </label>
            <label style={{ display: "inline" }} className="px-3">
              <input
                className="mr-2"
                name="Latino"
                type="radio"
                value={"Latino"}
                checked={bipocType === "Latino"}
                onClick={(e) => changeArtistBipocType(e)}
              />
              Latino
            </label>
            <label style={{ display: "inline" }} className="px-3">
              <input
                className="mr-2"
                name="Central Asia"
                type="radio"
                value={"Central Asia"}
                checked={bipocType === "Central Asia"}
                onClick={(e) => changeArtistBipocType(e)}
              />
              Central Asia
            </label>
            <label style={{ display: "inline" }} className="px-3">
              <input
                className="mr-2"
                name="Indigenous"
                type="radio"
                value={"Indigenous"}
                checked={bipocType === "Indigenous"}
                onClick={(e) => changeArtistBipocType(e)}
              />
              Indigenous
            </label>
          </div>
          <div className="socialMediaSelect mb-4">
            <label htmlFor="bio_desc" className="labelsBio">
              Social Media Links
            </label>
            <p
              style={{
                fontWeight: "300",
                lineHeight: "1.5",
                fontSize: "0.8rem",
              }}
            >
              Please make sure contact information in any link you connect to
              has Shannon Associates (info@shannonassociates.com) listed as
              contact for all job inquiries or mentioned as your MyShannon.
            </p>
            {socialMedia?.map((singleSocial, index) => (
              <div className="socialMediaContainerBio" key={index}>
                <select
                  key={index}
                  className="textField"
                  name="socialKey"
                  value={singleSocial.socialKey}
                  onChange={(e) => handleSocialMediaChange(index, e)}
                >
                  <option value="">Select</option>
                  {socialMediaOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  name="socialValue"
                  placeholder="Link"
                  value={singleSocial.socialValue}
                  className="textField socialMediaWebLink"
                  onChange={(e) => handleSocialMediaChange(index, e)}
                />
                {socialMedia.length > 1 && (
                  <div
                    className="addMoreInArray"
                    onClick={() => handleDeleteSocialMedia(index)}
                  >
                    <img
                      alt="CrossIcon"
                      src={CrossIcon}
                      onClick={() => handleDeleteSocialMedia(index)}
                    />
                  </div>
                )}
              </div>
            ))}
            <div className="addMoreInArray" onClick={addMoreSocialMedia}>
              <img alt="PlusIcon" src={AddMoreInArray} />
            </div>
          </div>
          <div className="skllsSelect mb-4">
            <label htmlFor="bio_desc" className="labelsBio">
              Select Skills
            </label>
            <p
              style={{
                fontWeight: "300",
                lineHeight: "1.5",
                fontSize: "0.8rem",
              }}
            >
              Choose all that apply
            </p>
            {skills?.map((skill, index) => (
              <div key={index} className="socialMediaContainerBio">
                <select
                  value={skill}
                  className="textField"
                  onChange={(e) => handleSkillsChange(index, e)}
                >
                  <option value="">Select</option>
                  {skillsOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {skills.length > 1 && (
                  <div
                    className="addMoreInArray"
                    onClick={() => handleDeleteSkill(index)}
                  >
                    <img alt="CrossIcon" src={CrossIcon} />
                  </div>
                )}
              </div>
            ))}
            <div className="addMoreInArray" onClick={addMoreSkills}>
              <img alt="PlusIcon" src={AddMoreInArray} />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="bio_desc" className="labelsBio">
              Personal / Portfolio Websites
            </label>
            <p
              style={{
                marginBottom: "10px",
                fontWeight: "300",
                lineHeight: "1.5",
                fontSize: "0.8rem",
              }}
            >
              Note: "Please make sure contact information in any link you
              connect to has Shannon Associates (info@shannonassociates.com)
              listed as contact for all job inquiries or mentioned as your
              MyShannon."
            </p>
            {website?.map((singleWebsite, index) => (
              <div key={index} className="socialMediaContainerBio">
                <input
                  type="text"
                  placeholder="Link"
                  value={singleWebsite}
                  className="textField"
                  style={{ marginBottom: "5px", width: "87%" }}
                  onChange={(e) => handleWebsitesChange(index, e)}
                />

                {website.length > 1 && (
                  <div
                    className="addMoreInArray"
                    onClick={() => handleDeleteWebsite(index)}
                  >
                    <img alt="CrossIcon" src={CrossIcon} />
                  </div>
                )}
              </div>
            ))}
            <div
              className="addMoreInArray"
              style={{ marginBottom: "20px" }}
              onClick={addMoreWebsite}
            >
              <img alt="PlusIcon" src={AddMoreInArray} />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="videoKey" className="labelsBio">
              Artistic Process Video
            </label>
            <p
              style={{
                marginBottom: "10px",
                fontWeight: "300",
                lineHeight: "1.5",
                fontSize: "0.8rem",
              }}
            >
              Please feel free to upload a video of how you create your work.
              Clients are interested in knowing your process and how you get to
              your final piece.
            </p>
            <div className="socialMediaContainerBio">
              <select
                className="textField"
                name="videoKey"
                value={videoUrl.videoKey}
                onChange={(e) => handleVideoUrlChange(e)}
              >
                <option value="">Select</option>
                {videoUrlOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>

              <input
                type="text"
                name="videoValue"
                placeholder="Link"
                value={videoUrl.videoValue}
                className="textField socialMediaWebLink"
                onChange={(e) => handleVideoUrlChange(e)}
              />
            </div>
            {error.videoError && <h4 style={{ color: "red" }}>Invalid Url</h4>}
          </div>
          <div class="col-12 d-flex justify-content-end">
            {loader ? (
              <img
                alt="loading"
                src={loadingUpdate}
                style={{ width: "40px" }}
              />
            ) : (
              <div>
                <img
                  onClick={() => setFormNo(0)}
                  alt="back"
                  src={BackIconForm}
                  style={{ width: "40px", cursor: "pointer" }}
                />
                <button type="submit" class="mx-1 ml-4 myBtn active sm">
                  UPDATE
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default BioArtistAdmin;
