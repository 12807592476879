import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import AddImage from "../../assets/svgs/addImage.svg";
import BackIconForm from "../../assets/svgs/backIconForm.svg";
import CrossButton from "../../assets/svgs/crossButton.svg";
import loadingUpdate from "../../assets/loading_trasnparent.gif";
import PlusIcon from "../../assets/svgs/plusIcon.svg";
import { updateMessage, updateOpen } from "../../redux/message";
import { artistPersonalWorkUpdateByAdmin, artistPersonalWorkDeleteByAdmin, getSingleArtist } from "../../AxiosFunctions/Axiosfunctionality";


const PersonalWorkUpdate = ({selectedArtist}) => {

    const dispatch = useDispatch();
    const [level, setLevel] = useState(0);
    const [personalWorks, setPersonalWorks] = useState(selectedArtist.personalWorks);
    const [personalWorkId, setPersonalWorkId] = useState();
    const [currentPersonalWork, setCurrentPersonalWork] = useState();
    const [isPopupShow, setIsPopupShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [mainimageUpload, setMainImageUpload] = useState(null);
    const [mainimageUrl, setMainImageUrl] = useState(null);
    const [secondaryImages, setSecondaryImages] = useState([]);
    const [secondaryImagesUrl, setSecondaryImagesUrl] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [title, setTitle] = useState("");
    const [displayImages, setDisplayImages] = useState([]);

    useEffect(() => {
        if (currentPersonalWork) {
            setTitle(currentPersonalWork.personalWorkTitle || "");
          
          setMainImageUrl(currentPersonalWork.personalWorkMainImage || null);
          if (currentPersonalWork.personalWorkSecondaryImages) {
            // Set the main image URL
    
            // Set the secondary images URL, exclude the first element
            setSecondaryImagesUrl(currentPersonalWork.personalWorkSecondaryImages || []);
            setDisplayImages(currentPersonalWork.personalWorkSecondaryImages || []);
          }
          setPersonalWorkId(currentPersonalWork._id);
        } else {
          setDisplayImages([]);
          setMainImageUrl(null);
          setSecondaryImagesUrl([]);
          setMainImageUpload(null);
          setSecondaryImages([]);
          setTitle("");
          setPersonalWorkId(null);
        }
      }, [currentPersonalWork]);
    
    const handleLevelChange = (personalWork) => {
        setLevel(1);
        setCurrentPersonalWork(personalWork);
    };

    const handleDeletePw = async (personalWork) => {
        try {
            await artistPersonalWorkDeleteByAdmin({
                personalWorkId: personalWork.personalWorkId,
                artistId: selectedArtist._id,
            });
            const currentArt = await getSingleArtist({ _id: selectedArtist._id }); 
            dispatch(updateOpen(true));
            dispatch(updateMessage("Deleted Successfully"));
            setIsPopupShow(true);
            setLoader(false);
            setPersonalWorks(currentArt.personalWorks);
        } catch (err) {
          dispatch(updateOpen(true));
    
          dispatch(updateMessage(err.message));
          setLoader(false);
        }
    };
    
    const handleBackButton = () => {
        setLevel(0);
        setCurrentPersonalWork(null);
        setPersonalWorkId(null);
    };

    useEffect(() => {
        // Combine initial URLs and new file objects for display
        const updatedDisplayImages = [
            ...secondaryImagesUrl,
            ...secondaryImages.map((file) => URL.createObjectURL(file)),
        ];
        setDisplayImages(updatedDisplayImages);
    }, [secondaryImages, secondaryImagesUrl]);
    
    const handleMainFileChangeImage = (event) => {
        if (event.target.files && event.target.files[0]) {
          setMainImageUpload(event.target.files[0]);
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            mainImage: undefined,
          }));
        }
    };

    const handleOtherFileChangeImage = (event) => {
        const images = [];
    
        if (
          event.target.files &&
          displayImages.length + event.target.files.length <= 5
        ) {
          for (let i = 0; i < event.target.files.length; i++) {
            images.push(event.target.files[i]);
          }
          setSecondaryImages([...secondaryImages, ...images]);
        } else {
          dispatch(updateOpen(true));
          dispatch(updateMessage("Max number of images is 5"));
        }
    };

    const handleDeleteImage = (index) => {
        // Adjust logic if needed to handle deleting both types of images: URLs and file objects
        const isUrlImage = index < secondaryImagesUrl.length;
        if (isUrlImage) {
          // Handle deletion of already uploaded images by URL
          const updatedUrls = displayImages.filter((_, idx) => idx !== index);
          setDisplayImages(updatedUrls);
          const updatedUrlsSec = secondaryImagesUrl.filter(
            (_, idx) => idx !== index
          );
          setSecondaryImagesUrl(updatedUrlsSec);
          // Additional logic to update server or state about deleted image
        } else {
          // Handle deletion of newly selected images
          const newFileIndex = index - secondaryImagesUrl.length;
          const updatedFiles = secondaryImages.filter(
            (_, idx) => idx !== newFileIndex
          );
          setSecondaryImages(updatedFiles);
        }
    };

    const handleChange = (event)=>{
        const { value } = event.target;
        setTitle(value);

    }

    const validateForm = () => {
        let errors = {};
        if (!title.trim()) errors.title = "Title is required";
        if (!mainimageUrl && !mainimageUpload)
          errors.mainImage = "Main image is required";
        setValidationErrors(errors);
    
        // Return true if no errors, false otherwise
        return Object.keys(errors).length === 0;
    };

    const makeId = (length) => {
        let result = "";
        const characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    };

    const handleSubmit = async(event)=>{
        event.preventDefault();
        setLoader(true);

        if (!validateForm()) {
        setLoader(false);
        return;
        }

        const formData = new FormData();

        formData.append("personalWorkId", currentPersonalWork?.personalWorkId || makeId(24));
        formData.append("artistId", selectedArtist._id);
        formData.append("updateType", "published works");
        mainimageUpload && formData.append("MainImageUpload", mainimageUpload); // Append the main image
        mainimageUrl && formData.append("personalWorkMainImage", mainimageUrl); // Append the main image
        secondaryImages.forEach((file, index) => {
          formData.append(`SecondaryImagesUpload`, file); // Append secondary images
        });
        formData.append(`personalWorkSecondaryImages`, JSON.stringify(secondaryImagesUrl)); // Append secondary images
        formData.append("personalWorkTitle", title);
    
        // Send the request
        try {
          await artistPersonalWorkUpdateByAdmin(formData);
          const currentArt = await getSingleArtist({ _id: selectedArtist._id });
          dispatch(updateOpen(true));
          dispatch(updateMessage("Saved Successfully"));
          setIsPopupShow(true);
          setLoader(false);
          setPersonalWorks(currentArt.personalWorks);
          handleBackButton();
        } catch (err) {
          dispatch(updateOpen(true));
    
          dispatch(updateMessage(err.message));
          setLoader(false);
        }
    }

    return (
    <>
        {level === 0 ? (
        <div className="px-1 px-md-5 artist mb-5">
            <h4> PERSONAL WORKS </h4>
            <div className="_4cols-v2">
            <label
                className="artistcardAdmin w-inline-block addImageBtn"
                onClick={() => handleLevelChange(null)}
            >
                <img alt="" src={AddImage} className="addImage" />
            </label>
            {personalWorks
                ? [...personalWorks].reverse().map((personalWork, ind) => (
                    <div
                    className="artistcardAdmin w-inline-block"
                    style={{ cursor: "pointer" }}
                    key={ind}
                    >
                    <div
                        onClick={() => handleDeletePw(personalWork)}
                        className="crossSection"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12px"
                        height="12px"
                        viewBox="0 0 352 512"
                        >
                        <path
                            fill="grey"
                            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                        />
                        </svg>
                    </div>
                    <img
                        onClick={() => handleLevelChange(personalWork)}
                        src={personalWork.personalWorkMainImage}
                        alt={personalWork.personalWorkTitle}
                        className="image"
                    />
                    </div>
                )):null}
            </div>
        </div>
        ): ( level === 1 && (<>
                <div className="bioArtistContainer">
                <h4 style={{ marginBottom: "10px", fontSize: "1.2rem" }}>
                    PERSONAL WORK
                </h4>
                <img
                    onClick={handleBackButton}
                    alt="back"
                    src={BackIconForm}
                    style={{ width: "40px", cursor: "pointer" }}
                />
                <form onSubmit={handleSubmit}>
                    <label htmlFor="pw_cover" className="labelsBio">
                        COVER / MAIN IMAGE
                    </label>
                    {mainimageUrl && mainimageUrl !== "null" ? (
                        <div className="imageView" style={{ width: "fit-content"}}>
                            <img
                            className="CrossButton"
                            alt="CrossButton"
                            src={CrossButton}
                            onClick={() => {
                                setMainImageUpload(null);
                                setMainImageUrl(null);
                            }}
                            style={{backgroundColor:"gray"}}
                            />
                            <img className="imageUploaded" alt="PlusIcon" src={mainimageUrl} />
                        </div>
                        ) : mainimageUpload ? (
                        <div className="imageView" style={{ width: "fit-content"}}>
                            <img
                            className="CrossButton"
                            alt="CrossButton"
                            src={CrossButton}
                            onClick={() => {
                                setMainImageUpload(null);
                            }}
                            style={{backgroundColor:"gray"}}
                            />
                            <img
                            className="imageUploaded"
                            alt="PlusIcon"
                            src={URL.createObjectURL(mainimageUpload)}
                            />
                        </div>
                        ) : (
                        <label className="uploadArtistImage">
                            <img alt="PlusIcon" src={PlusIcon} />
                            <h5>UPLOAD IMAGE</h5>
                            <input
                            hidden
                            type="file"
                            onChange={handleMainFileChangeImage}
                            accept="image/*"
                            />
                        </label>
                    )}
                    {validationErrors.mainImage && (
                    <p style={{ color: "red" }}>{validationErrors.mainImage}</p>
                    )}
                    <div className="publishWorkInputContainer">
                        <div className="inputContainer">
                            <label htmlFor="pw_title" className="labelsBio">
                            Title
                            </label>
                            <div className="socialMediaContainerBio">
                            <input
                                type="text"
                                placeholder="Title"
                                value={title}
                                name="title"
                                className="textField"
                                onChange={handleChange}
                            />
                            </div>
                            {validationErrors.title && (
                            <p style={{ color: "red" }}>{validationErrors.title}</p>
                            )}
                        </div>
                    </div>
                    <div className="inputContainer" style={{ width: "100%" }}>
                        <label htmlFor="pw_cover" className="labelsBio">
                        SUPPORTING IMAGES
                        </label>
                        <p
                        style={{
                            marginBottom: "30px",
                            fontWeight: "300",
                            lineHeight: "1.5",
                            fontSize: "0.8rem",
                        }}
                        >
                        You can upload up to 5 supporting images (Please adhere to your
                        contract with your client). They can be interiors of a book (full
                        color/ black and white/ sequential, etc.), or additional images
                        from the campaign/ article.
                        </p>
                        <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
        
                            alignItems: "center",
                        }}
                        >
                        {displayImages.length < 5 && (
                            <label
                            className="uploadArtistImage"
                            style={{ marginRight: "5px" }}
                            >
                            <img alt="PlusIcon" src={PlusIcon} />
                            <h5>UPLOAD IMAGE</h5>
                            <p
                                style={{
                                marginBottom: "30px",
                                fontWeight: "300",
                                lineHeight: "1.5",
                                fontSize: "0.8rem",
                                }}
                            >
                                Max. 5 Images
                            </p>
                            <input
                                hidden
                                multiple
                                type="file"
                                onChange={handleOtherFileChangeImage}
                                accept="image/*"
                            />
                            </label>
                        )}
        
                        {displayImages.map((img, index) => (
                            <div
                            key={index}
                            className="imageView"
                            style={{
                                border: "1px black solid",
                                margin: " 4px",
                                height: "100%",
                            }}
                            >
                            <img
                                className="CrossButton"
                                alt="CrossButton"
                                src={CrossButton}
                                onClick={() => handleDeleteImage(index)}
                                style={{backgroundColor:"gray"}}
                            />
                            <img
                                className="imageUploaded"
                                alt={`Uploaded content ${index}`}
                                src={img}
                            />
                            </div>
                        ))}
                        </div>
        
                        <p
                        style={{
                            marginBottom: "30px",
                            fontWeight: "300",
                            lineHeight: "1.5",
                            fontSize: "0.8rem",
                            color: "#FF0000",
                        }}
                        >
                        Please press submit button below.
                        </p>
                    </div>
                    <div class="d-flex mt-4">
                        {loader ? (
                        <img
                            alt="loading"
                            src={loadingUpdate}
                            style={{ width: "40px" }}
                        />
                        ) : (
                        <button class="mx-1 myBtn active sm">SUBMIT</button>
                        )}
                    </div>
                </form>
                </div>
            </>
        ))}
    </>
  );
}

export default PersonalWorkUpdate