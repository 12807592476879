import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllMedical } from "../../AxiosFunctions/Axiosfunctionality";
import loading from "../../assets/loading.gif";
import { useHistory } from "react-router-dom";

function SearchByMedical(props) {
  const [data, setData] = useState(null);
  const [dataOriginal, setDataOriginal] = useState(null);
  const [filterHighlighted, setFilterHighlighted] = useState(null);
  const [medical, setMedical] = useState(null);
  const sortingSeq = ["GENERAL", "TECHNICAL"];
  const history = useHistory();
  const [windowSize] = useState(getWindowSize());

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const filterChange = (filter) => {
    let tempData = [...data];
    let filter_highli = null;
    setDataOriginal([...data]);
    if (filter === "A-Z") {
      filter_highli = 2;
      tempData = tempData.sort((a, b) =>
        a.artistId.lastname
          .normalize()
          .localeCompare(b.artistId.lastname.normalize())
      );
    } else if (dataOriginal) {
      filter_highli = 1;
      tempData = [...dataOriginal];
      // tempData = dataOriginal;
    }

    setData(tempData);
    setFilterHighlighted(filter_highli);
  };

  useEffect(() => {
    setMedical(null);
    getAllMedical().then((res) => {
      let temp = [];
      for (let index = 0; index < res.length; index++) {
        let orderedArtist = res[index].data?.sort((a, b) => {
          if (
            a.artistId.lastname
              .normalize()
              .localeCompare(b.artistId.lastname.normalize()) === 0
          ) {
            return a.artistId.firstname
              .normalize()
              .localeCompare(b.artistId.firstname.normalize());
          } else {
            return a.artistId.lastname
              .normalize()
              .localeCompare(b.artistId.lastname.normalize());
          }
        });
        temp.push({
          cat: res[index].cat,
          data: orderedArtist,
        });
      }

      temp.sort((a, b) => {
        return sortingSeq.indexOf(a.cat) - sortingSeq.indexOf(b.cat);
      });
      const gNovel = temp.filter(
        (obj) => obj.cat === props.medical.toUpperCase()
      );

      setMedical(gNovel);
    });
  }, [props.medical]);

  return (
    <>
      <div class="sortingcont right pt-0  me-0 ">
        <div className="d-flex  ">
          <button
            class={
              filterHighlighted === 1
                ? "filter-button sort-active w-inline-block  mt-0"
                : filterHighlighted === 2
                ? "filter-button w-inline-block  mt-0"
                : "filter-button sort-active w-inline-block  mt-0"
            }
            style={{ marginLeft: "0px" }}
            onClick={() => filterChange("Default")}
          >
            <div>FEATURED</div>
          </button>
          <button
            class={
              filterHighlighted === 2
                ? "filter-button sort-active mt-0 me-0"
                : "filter-button mt-0 me-0"
            }
            onClick={() => filterChange("A-Z")}
          >
            <div>ALPHABETICAL A-Z</div>
          </button>
        </div>
      </div>
      <div className="_2cols2_" style={{ clear: "both" }}>
        {props.children}
        <div
          id="w-node-_4a165d69-02be-f2c1-10f5-69fa4946403e-576fcec6"
          className="divisionscolumn"
        >
          <div
            id="w-node-_4a165d69-02be-f2c1-10f5-69fa4946403f-576fcec6"
            className="divisioncontainer"
          >
            <div>
              {JSON.parse(localStorage.getItem("routePaths")) ? (
                <div
                  className={"d-flex"}
                  style={{ marginBottom: "10px", width: "98.4%" }}
                >
                  {JSON.parse(localStorage.getItem("routePaths")).map(
                    (obj, ind) => (
                      <p
                        className={
                          JSON.parse(localStorage.getItem("routePaths"))
                            .length ===
                          ind + 1
                            ? "breadCrumbs"
                            : "breadCrumbsActive"
                        }
                        onClick={
                          JSON.parse(localStorage.getItem("routePaths"))
                            .length ===
                          ind + 1
                            ? () => {}
                            : () => history.push(obj.link)
                        }
                        style={ind === 0 ? {} : { marginLeft: "5px" }}
                      >
                        {obj.val}{" "}
                        {JSON.parse(localStorage.getItem("routePaths"))
                          .length ===
                        ind + 1
                          ? null
                          : ">"}
                      </p>
                    )
                  )}
                </div>
              ) : null}
            </div>

            {medical === null ? (
              <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                <img className="mb-3" alt="loading" src={loading} />
              </div>
            ) : medical ? (
              medical.map((obj) => (
                <>
                  <h2 className="newsh2 h">{obj.cat}</h2>
                  <div
                    id="w-node-_4a165d69-02be-f2c1-10f5-69fa49464043-576fcec6"
                    className="_4cols-v2"
                  >
                    {obj.data.map((val) => (
                      <>
                        <Link
                          id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                          data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                          to={"/" + val.artistId.fullName}
                          className="artistcard  w-inline-block"
                        >
                          <img
                            src={String(val.mainImage[0].subImage[0].path)}
                            loading="lazy"
                            alt=""
                            className="image"
                          />
                          <div className="artistnamediv">
                            <div className="artistnametext-v3">
                              {val.artistId.firstname} {val.artistId.lastname}
                            </div>
                          </div>
                        </Link>
                      </>
                    ))}
                  </div>
                </>
              ))
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchByMedical;
