import { useEffect, useState } from "react";
import ReactQuill from "react-quill";

import CrossButton from "../../assets/svgs/crossButton.svg";
import PlusIcon from "../../assets/svgs/plusIcon.svg";
import AddMoreInArray from "../../assets/svgs/addMoreInArray.svg";
import CrossIcon from "../../assets/svgs/crossIcon.svg";
import loadingUpdate from "../../assets/loading_trasnparent.gif";
import { useDispatch } from "react-redux";
import { updateMessage, updateOpen } from "../../redux/message";
import MyPopup from "../../components/myPopup/myPopup";
import { artistAwardsUpload, uploadFiles } from "../../AxiosFunctions/Axiosfunctionality";


const defaultFormFields = {
    title: "",
    source:"",
    author: "",
    illustrator: "",
    date: "",
    artistContributed: "",
    link: "",
    typeOfUpload: "award",
};
const initialCategory = {
    categoryKeyProperty: "",
    categoryValueProperty: null,
};

const AwardPressUpload = ({history, artistDetails ,currentAward}) => {

    const dispatch = useDispatch();
    const [mainimageUrl, setMainImageUrl] = useState(null);
    const [mainimageUpload, setMainImageUpload] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [formFields, setFormFields] = useState(defaultFormFields);
    const [categories, setCategories] = useState([initialCategory]);
    const [isPopupShow, setIsPopupShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const {
        title,
        source,
        author,
        illustrator,
        date,
        artistContributed,
        link,
        typeOfUpload
    } = formFields;

    const modules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { color: ["black", "red", "blue", "green"] },
          ],
          ["link"],
          ["clean"],
        ],
    };

    useEffect(() => {
        if (currentAward) {
            setFormFields({
                title: currentAward.awardTitle,
                source: currentAward.awardSource,
                author: currentAward.awardAuthor,
                illustrator: currentAward.awardIllustrator,
                date: currentAward.awardDate,
                artistContributed: currentAward.awardArtistContributed,
                link: currentAward.awardLink,
                typeOfUpload: currentAward.awardType,
            });
          setCategories(currentAward.extraProperties);    
          setMainImageUrl(currentAward.awardMainImage);
        } else {
          setFormFields(defaultFormFields);
          setMainImageUrl(null);
          setCategories([initialCategory]);
          setMainImageUpload(null);
        }
    }, [currentAward]);

    const handleMainFileChangeImage = (event) => {
        if (event.target.files && event.target.files[0]) {
          setMainImageUpload(event.target.files[0]);
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            mainImage: undefined,
          }));
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value });
    }

    const handleCategoryChange = (index, value) => {
        const newCategories = categories.map((category, i) => {
          if (i === index) {
            delete category.files;
    
            return {
              ...category,
              categoryKeyProperty: value,
              categoryValueProperty: null,
            };
          }
          return category;
        });
        setCategories(newCategories);
    };

    const removeCategory = (index) => {
        const newCategories = categories.filter((_, i) => i !== index);
        setCategories(newCategories);
    };

    const handleTextChange = (text, index) => {
        text = text !== "<p><br></p>" ? text : "";
    
        const newCategories = [...categories];
        newCategories[index].categoryValueProperty = text; // Store text data
        setCategories(newCategories);
    };

    const handleFileChange = (index, event) => {
        const newFiles = event.target.files; // This is a FileList, not a regular array
    
        if (newFiles.length) {
          const updatedCategories = [...categories];
    
          // Convert FileList to an array and append new files
          updatedCategories[index].files = [
            ...(updatedCategories[index].files || []),
            ...Array.from(newFiles), // Convert FileList to an array
          ];
    
          setCategories(updatedCategories);
        }
    };

    const addCategory = () => {
        setCategories([...categories, initialCategory]);
    };

    const validateForm = () => {
        let errors = {};
        if (!title.trim()) errors.title = "Title is required";
        if (!mainimageUrl && !mainimageUpload)
          errors.mainImage = "Main image is required";
    
        setValidationErrors(errors);
    
        // Return true if no errors, false otherwise
        return Object.keys(errors).length === 0;
      };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoader(true);
        if (!validateForm()) {
          setLoader(false);
          return;
        }
    
        const updatedCategories = await Promise.all(
          categories.map(async (category, index) => {
            // Process files if there are any
            if (category.files) {
              const urls = await uploadFiles(category.files); // uploadFiles should be implemented to handle the upload and return URLs
              delete category.files;
    
              return {
                ...category,
                categoryValueProperty:
                  category.categoryKeyProperty === "carousel" ? urls : urls[0],
              }; // Clear file data after upload
            }
            return category; // Return the category as is if there's no file to upload
          })
        );
    
        let awardMainImage;
    
        // Handling asynchronous file upload
        if (mainimageUpload) {
          const urls = await uploadFiles([mainimageUpload]); // Assumed this returns an array of URLs
          awardMainImage = urls[0]; // Assuming you want to append the first URL
        } else if (mainimageUrl) {
          awardMainImage = mainimageUrl; // Use existing main image URL if upload is not needed
        }

        const formDataEntries = {
            artistId: artistDetails?._id,
            updateType: "award/press",
            ...(currentAward && {awardPressId : currentAward.awardPressId}),
            awardTitle: title,
            awardSource: source,
            awardAuthor: author,
            awardIllustrator: illustrator,
            awardDate: date,
            awardArtistContributed: artistContributed,
            awardLink: link,
            awardType: typeOfUpload,
            extraProperties: JSON.stringify(updatedCategories),
            ...(awardMainImage && { awardMainImage }), // Add propertyMainImage only if it's defined
        };

        try {
          await artistAwardsUpload(formDataEntries);
          dispatch(updateOpen(true));
          dispatch(updateMessage("Saved Successfully"));
          setIsPopupShow(true);
          setLoader(false);
        } catch (err) {
          dispatch(updateOpen(true));
    
          dispatch(updateMessage(err.message));
          setLoader(false);
        }
      };

    return (
    <div className="bioArtistContainer" style={{ paddingLeft: "100px" }}>
        <h4 style={{ marginBottom: "0px", fontSize: "1.2rem" }}>AWARDS / PRESS</h4>
        <p style={{ marginBottom: "30px", fontWeight: "300", lineHeight: "1.5", fontSize: "0.8rem", }}>
            We encourage you to upload any awards or honors received for your artwork. Also, any press / articles where you or your work is highlighted.
        </p>

        <select className="textField" name="typeOfUpload" value={typeOfUpload} onChange={handleChange}>
            <option value = "award" >AWARD</option>
            <option value = "press">PRESS</option>
        </select>
        
        <form onSubmit={handleSubmit}>
            <label htmlFor="pw_cover" className="labelsBio">
            COVER / MAIN IMAGE
            </label>
            <p style={{ marginBottom: "30px", fontWeight: "300", lineHeight: "1.5", fontSize: "0.8rem", }}>
                If a published book, please upload the designed cover (with type) produced by the client. If a campaign, please use the final approved image designed for the campaign or article.
            </p>
            {mainimageUrl && mainimageUrl !== "null" ? (
                <div className="imageView" style={{ width: "fit-content"}}>
                    <img
                    className="CrossButton"
                    alt="CrossButton"
                    src={CrossButton}
                    onClick={() => {
                        setMainImageUpload(null);
                        setMainImageUrl(null);
                    }}
                    style={{backgroundColor:"gray"}}
                    />
                    <img className="imageUploaded" alt="PlusIcon" src={mainimageUrl} />
                </div>
                ) : mainimageUpload ? (
                <div className="imageView" style={{ width: "fit-content"}}>
                    <img
                    className="CrossButton"
                    alt="CrossButton"
                    src={CrossButton}
                    onClick={() => {
                        setMainImageUpload(null);
                    }}
                    style={{backgroundColor:"gray"}}
                    />
                    <img
                    className="imageUploaded"
                    alt="PlusIcon"
                    src={URL.createObjectURL(mainimageUpload)}
                    />
                </div>
                ) : (
                <label className="uploadArtistImage">
                    <img alt="PlusIcon" src={PlusIcon} />
                    <h5>UPLOAD IMAGE</h5>
                    <input
                    hidden
                    type="file"
                    onChange={handleMainFileChangeImage}
                    accept="image/*"
                    />
                </label>
            )}
            <div className="publishWorkInputContainer">
                {
                    typeOfUpload === "press" && (
                    <div className="inputContainer">
                        <label htmlFor="press_source" className="labelsBio">
                        Source
                        </label>
                        <div className="socialMediaContainerBio">
                        <input
                            type="text"
                            placeholder="Source"
                            value={source}
                            name="source"
                            className="textField"
                            onChange={handleChange}
                        />
                        </div>
                        {validationErrors.source && (
                        <p style={{ color: "red" }}>{validationErrors.source}</p>
                        )}
                    </div>)
                }
                <div className="inputContainer">
                    <label htmlFor="awards_title" className="labelsBio">
                    Title
                    </label>
                    <div className="socialMediaContainerBio">
                    <input
                        type="text"
                        required
                        placeholder="Title"
                        value={title}
                        name="title"
                        className="textField"
                        onChange={handleChange}
                    />
                    </div>
                </div>
                <div className="inputContainer">
                    <label htmlFor="pw_title" className="labelsBio">
                    Author
                    </label>
                    <div className="socialMediaContainerBio">
                    <input
                        type="text"
                        placeholder="Author"
                        value={author}
                        name="author"
                        className="textField"
                        onChange={handleChange}
                    />
                    </div>
                </div>
                <div className="inputContainer">
                    <label htmlFor="pw_illustrator" className="labelsBio">
                    Illustrator
                    </label>
                    <div className="socialMediaContainerBio">
                    <input
                        type="text"
                        placeholder="Illustrator"
                        value={illustrator}
                        name="illustrator"
                        className="textField"
                        onChange={handleChange}
                    />
                    </div>
                </div>
                <div className="inputContainer">
                    <label htmlFor="pw_publishedDate" className="labelsBio">
                    {typeOfUpload === "award" && "Date of Award"}
                    {typeOfUpload === "press" && "Date of Article"}
                    {typeOfUpload === "" && "Date"}
                    </label>
                    <div className="socialMediaContainerBio">
                    <input
                        type="date"
                        placeholder="Date"
                        value={date}
                        name="date"
                        className="textField"
                        onChange={handleChange}
                    />
                    </div>
                </div>

                <div className="inputContainer">
                    <label htmlFor="pw_artistContributed" className="labelsBio">
                    Work Artist Contributed (example: Cover illustration)
                    </label>
                    <div className="socialMediaContainerBio">
                    <input
                        type="text"
                        placeholder="Artist Contributed"
                        value={artistContributed}
                        name="artistContributed"
                        className="textField"
                        onChange={handleChange}
                    />
                    </div>
                </div>

                <div className="inputContainer">
                    <label htmlFor="pw_artistContributed" className="labelsBio">
                    Link
                    </label>
                    <div className="socialMediaContainerBio">
                    <input
                        type="text"
                        placeholder="Link"
                        value={link}
                        name="link"
                        className="textField"
                        onChange={handleChange}
                    />
                    </div>
                </div>
                <div className="inputContainer" style={{ width: "100%" }}>
                    <label htmlFor="pw_category" className="labelsBio">
                    Awards Extra Content
                    </label>
                    <p
                    style={{
                        marginBottom: "10px",
                        fontWeight: "300",
                        lineHeight: "1.5",
                        fontSize: "0.8rem",
                    }}
                    >
                    Use these additional "block" sections. Use "Text" to tell more about the award and your work.
                    Use "Carousel" to include a number of images together to support the work.
                    "Banner is an image that fits across this space at a sixe of __x__pixels.
                    </p>
                    {categories.map((category, index) => (
                    <div key={index} style={{ marginBottom: "10px" }}>
                        <select
                        value={category.categoryKeyProperty}
                        className="textField"
                        style={{ maxWidth: "300px" }}
                        onChange={(e) => handleCategoryChange(index, e.target.value)}
                        >
                        <option value="">Select Extra Content Type</option>
                        <option value="text">Text</option>
                        <option value="banner">Banner</option>
                        <option value="carousel">Carousel</option>
                        </select>
                        {categories.length > 1 && (
                        <span
                            className="addMoreInArray"
                            onClick={() => removeCategory(index)}
                        >
                            <img
                            style={{ maxWidth: "40px", marginLeft: "10px" }}
                            alt="CrossIcon"
                            src={CrossIcon}
                            />
                        </span>
                        )}
                        {category.categoryKeyProperty === "text" && (
                        <div className="bio_desc_container">
                            <ReactQuill
                            value={category.categoryValueProperty}
                            onChange={(text) => handleTextChange(text, index)}
                            modules={modules}
                            />
                        </div>
                        )}

                        {category.categoryKeyProperty === "banner" &&
                        (category.files ? (
                            <div className="imageView" style={{ width: "fit-content" }}>
                            <img
                                className="CrossButton"
                                alt="CrossButton"
                                src={CrossButton}
                                onClick={() => {
                                const newCategories = [...categories];
                                delete newCategories[index].files;
                                setCategories(newCategories);
                                }}
                                style={{backgroundColor:"gray"}}
                            />
                            <img
                                className=""
                                alt="Banner preview"
                                src={URL.createObjectURL(category.files[0])}
                            />
                            </div>
                        ) : category.categoryValueProperty ? (
                            <div className="imageView" style={{ width: "fit-content" }}>
                            <img
                                className="CrossButton"
                                alt="CrossButton"
                                src={CrossButton}
                                onClick={() => {
                                const newCategories = [...categories];
                                delete newCategories[index].files;
                                setCategories(newCategories);
                                }}
                                style={{backgroundColor:"gray"}}
                            />
                            <img
                                className=""
                                alt="Banner preview"
                                src={category.categoryValueProperty}
                            />
                            </div>
                        ) : (
                            <label className="uploadArtistImage">
                            <img alt="PlusIcon" src={PlusIcon} />
                            <h5>UPLOAD IMAGE</h5>
                            <input
                                hidden
                                type="file"
                                onChange={(e) => handleFileChange(index, e)}
                                accept="image/*"
                            />
                            </label>
                        ))}

                        {category.categoryKeyProperty === "carousel" && (
                        <>
                            <div
                            className="imageGallery"
                            style={{
                                display: "flex",
                                flexWrap: "wrap",

                                alignItems: "center",
                            }}
                            >
                            <label className="uploadArtistImage">
                                <img alt="PlusIcon" src={PlusIcon} />
                                <h5>UPLOAD IMAGES</h5>
                                <input
                                hidden
                                type="file"
                                multiple
                                onChange={(e) => handleFileChange(index, e)}
                                accept="image/*"
                                />
                            </label>
                            {category.categoryValueProperty &&
                                category.categoryValueProperty.map((url, urlIndex) => (
                                <div
                                    key={urlIndex}
                                    className="imageView"
                                    style={{
                                    border: "1px black solid",
                                    margin: " 4px",
                                    height: "100%",
                                    }}
                                >
                                    <img
                                    className="CrossButton"
                                    alt="Remove"
                                    src={CrossButton}
                                    onClick={() => {
                                        const newCategories = [...categories];
                                        newCategories[
                                        index
                                        ].categoryValueProperty.splice(urlIndex, 1); // Remove this URL
                                        setCategories(newCategories);
                                    }}
                                    style={{backgroundColor:"gray"}}
                                    />
                                    <img
                                    className="imageUploaded"
                                    alt={`Carousel preview ${urlIndex}`}
                                    src={url}
                                    />
                                </div>
                                ))}
                            {category.files &&
                                category.files.map((file, fileIndex) => {
                                if (file instanceof Blob) {
                                    // Only preview if it's a file to be uploaded
                                    return (
                                    <div
                                        key={`new-${fileIndex}`}
                                        className="imageView"
                                        style={{
                                        border: "1px black solid",
                                        margin: " 4px",
                                        height: "100%",
                                        }}
                                    >
                                        <img
                                        className="CrossButton"
                                        alt="Remove"
                                        src={CrossButton}
                                        onClick={() => {
                                            const newCategories = [...categories];
                                            // Remove the file from files
                                            newCategories[index].files.splice(
                                            fileIndex,
                                            1
                                            );
                                            setCategories(newCategories);
                                        }}
                                        style={{backgroundColor:"gray"}}
                                        />
                                        <img
                                        className="imageUploaded"
                                        alt={`New Carousel preview ${fileIndex}`}
                                        src={URL.createObjectURL(file)}
                                        />
                                    </div>
                                    );
                                }
                                return null;
                                })}
                            </div>
                        </>
                        )}
                    </div>
                    ))}
                    {validationErrors.category && (
                    <p style={{ color: "red" }}>{validationErrors.category}</p>
                    )}
                    <br />
                    <div
                    className="addMoreInArray"
                    style={{ marginBottom: "20px" }}
                    onClick={addCategory}
                    >
                    <img alt="PlusIcon" src={AddMoreInArray} />
                    </div>
                </div>
                <div className="d-flex mt-4">
                    {loader ? (
                    <img
                        alt="loading"
                        src={loadingUpdate}
                        style={{ width: "40px" }}
                    />
                    ) : (
                    <button className="mx-1 myBtn active sm">SUBMIT</button>
                    )}
                </div>
            </div>
        </form>
        {isPopupShow ? (
            <MyPopup
            BackClose
            CloseBtn
            onClose={() => {
                setIsPopupShow(false);
                history.push("/artist");
            }}
            >
            <div className="mx-5 my-4 popUpfontsize">
                Your Data has been received. <br />
                The Shannon Associates team will promptly review and publish it.{" "}
                <br />
                Feel free to reach out to Lori Rucker at lori@shannonassociates.com
                with any questions or concerns.
            </div>
            </MyPopup>
        ) : null}

    </div>
    )
}

export default AwardPressUpload