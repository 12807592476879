import { useState } from "react";
import { useDispatch } from "react-redux";
import { clientLoginApi, clientRegisterApi } from "../../redux/signInUpSlice";
import { setAuthToken } from "../../AxiosFunctions/setCommonHeader";
import { updateMessage, updateOpen } from "../../redux/message";

import { forgetClient } from "../../AxiosFunctions/Axiosfunctionality";
import loading from "../../assets/loading.gif";
import imgClientPopUp from "../../assets/img/ClientLogInPopUp.png";

const defaultLogin = {
  emailLogin: "",
  password: "",
};

const defaultCreateAccount = {
  name: "",
  firstName: "",
  lastName:"",
  occupation:"",
  company: "",
  emailCreateAccount: "",
  passwordCreateAccount:"",
};

const defaultForgotPassword = {
  emailForgotPassword: "",
};

const PopUpLogin = ({ setModal, slevel = 0 }) => {
  const [level, setLevel] = useState(slevel);
  const [loginForm, setLoginForm] = useState(defaultLogin);
  const [createAccountForm, setCreateAccountForm] =
    useState(defaultCreateAccount);
  const [forgotPasswordForm, setForgotPasswordForm] = useState(
    defaultForgotPassword
  );
  const { emailForgotPassword } = forgotPasswordForm;
  const { emailLogin, password } = loginForm;
  const { name,firstName,lastName,occupation, company, emailCreateAccount,passwordCreateAccount } = createAccountForm;
  const dispatch = useDispatch();
  const [validation, setValidation] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [loadingGif, setLoadingGif] = useState(false);

  const handleChangeLogin = (event) => {
    const { name, value } = event.target;
    setLoginForm({ ...loginForm, [name]: value });
  };

  const handleChangeCreateAccount = (event) => {
    setValidation("");

    const { name, value } = event.target;
    setCreateAccountForm({ ...createAccountForm, [name]: value });
  };

  const handleChangeForgotPassword = (event) => {
    setValidation("");

    const { name, value } = event.target;
    setForgotPasswordForm({ ...forgotPasswordForm, [name]: value });
  };

  const handleChangeLevel = (level) => {
    setLevel(level);
    setLoginForm(defaultLogin);
    setCreateAccountForm(defaultCreateAccount);
  };

  const handleSubmitLogIn = async (e) => {
    e.preventDefault();

    let data = {
      email: emailLogin,
      password: password,
    };
    if (emailLogin === "" || password === "") {
      setValidation("Email and Password Required");
      return;
    }

    dispatch(clientLoginApi(data)).then((res) => {
      localStorage.setItem(
        "authorization",
        res.payload.token !== undefined ? res.payload.token : ""
      );

      setAuthToken(res.token);
      dispatch(updateOpen(true));
      setShowLoader(true);
      if (res.payload.token !== undefined) {
        dispatch(updateMessage("Successfully Login"));
        setModal(false);
        window.dispatchEvent(new Event("storage"));
      } else {
        dispatch(updateMessage("Invalid Credentials or In Active Account"));
      }
    });
  };

  const handleSubmitCreateAccount = async (e) => {
    e.preventDefault();
    let data = {
      firstname:firstName,
      lastname:lastName,
      company,
      occupation,
      email: emailCreateAccount,
      password: passwordCreateAccount
    };
    
    if (emailCreateAccount === "" || company === "" || firstName === "" || lastName === "" || occupation === "" || passwordCreateAccount === "") {
      setValidation("All fields are REQUIRED");
      // dispatch(updateOpen(true))
      // dispatch(updateMessage("Email And Names Required"))
      return;
    }

    setShowLoader(false);
    dispatch(clientRegisterApi(data)).then((res) => {
      setLevel(2);
    });
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoadingGif(true);
    await forgetClient({
      email: emailForgotPassword,
    });
    setLoadingGif(false);
    setLevel(4);
  };

  return (
    <>
      <div className="modalTutorial">
        <div
          className="containerModal"
          style={{
            border: "solid 40px #EDECEC",
            boxShadow: "inset 5px 5px 20px #b3b3b3",
            backgroundColor: "white",
            padding:"100px"
          }}
        >
          <div className="modalCloseBtn">
            <button
              onClick={() => {
                setModal(false);
              }}
            >
              ×
            </button>
          </div>
          {level === 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "50px 100px",
              }}
            >
              <img style={{ width: "100px" }} src={imgClientPopUp} alt="Logo" />
              <p
                style={{
                  margin: "40px 0px",
                  color: "#3B7BA8",
                  fontSize: "30px",
                  lineHeight: "1.2",
                  textAlign: "center",
                  fontFamily: '"Gotu"',
                }}
              >
                By creating an account, <br />
                you will have access to review <br />
                original story ideas currently <br />
                being shown in our <br />
                Properties Section.
              </p>
              <form className="popUploginForm" onSubmit={handleSubmitLogIn}>
                <div style={{ width: "50%" }}>
                  <label htmlFor="pw_subCategory" className="labelsBio">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="Email"
                    value={emailLogin}
                    name="emailLogin"
                    className="textField"
                    style={{ marginBottom: "5px", width: "100%" }}
                    onChange={handleChangeLogin}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <label htmlFor="pw_subCategory" className="labelsBio">
                    Password
                  </label>
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    name="password"
                    className="textField"
                    style={{ marginBottom: "5px", width: "100%" }}
                    onChange={handleChangeLogin}
                  />
                </div>
                {validation && <p className="error">{validation}</p>}
                <button type="submit" class="mx-1 myBtn active sm mt-3 mb-3">
                  ENTER
                </button>
                <p
                  onClick={() => handleChangeLevel(1)}
                  style={{ cursor: "pointer" }}
                >
                  Create an account
                </p>
                <p
                  onClick={() => handleChangeLevel(3)}
                  style={{ cursor: "pointer" }}
                >
                  Forgot Password
                </p>
              </form>
            </div>
          )}
          {level === 1 && (
            <div>
              <form
                className="popUpCreateAccount"
                onSubmit={handleSubmitCreateAccount}
              >
                <div>
                  <label htmlFor="pw_subCategory" className="labelsBio">
                    First Name
                  </label>
                  <input
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    name="firstName"
                    className="textField"
                    style={{ marginBottom: "5px", width: "100%" }}
                    onChange={handleChangeCreateAccount}
                  />
                </div>
                <div>
                  <label htmlFor="pw_subCategory" className="labelsBio">
                    Last Name
                  </label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    name="lastName"
                    className="textField"
                    style={{ marginBottom: "5px", width: "100%" }}
                    onChange={handleChangeCreateAccount}
                  />
                </div>
                <div>
                  <label htmlFor="pw_subCategory" className="labelsBio">
                    Occupation
                  </label>
                  <input
                    type="text"
                    placeholder="Occupation"
                    value={occupation}
                    name="occupation"
                    className="textField"
                    style={{ marginBottom: "5px", width: "100%" }}
                    onChange={handleChangeCreateAccount}
                  />
                </div>
                <div>
                  <label htmlFor="pw_subCategory" className="labelsBio">
                    Company
                  </label>
                  <input
                    type="text"
                    placeholder="Company"
                    value={company}
                    name="company"
                    className="textField"
                    style={{ marginBottom: "5px", width: "100%" }}
                    onChange={handleChangeCreateAccount}
                  />
                </div>
                <div style={{ gridColumn: "1/3" }}>
                  <label htmlFor="pw_subCategory" className="labelsBio">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="Email"
                    value={emailCreateAccount}
                    name="emailCreateAccount"
                    className="textField"
                    style={{ marginBottom: "5px", width: "100%" }}
                    onChange={handleChangeCreateAccount}
                  />
                </div>
                <div style={{ gridColumn: "1/3" }}>
                  <label htmlFor="pw_subCategory" className="labelsBio">
                    Password
                  </label>
                  <input
                    type="password"
                    value={passwordCreateAccount}
                    name="passwordCreateAccount"
                    className="textField"
                    style={{ marginBottom: "5px", width: "100%" }}
                    onChange={handleChangeCreateAccount}
                  />
                </div>
                {validation && <p className="error">{validation}</p>}

                <div style={{ gridColumn: "1/3", justifySelf: "center" }}>
                  <button type="submit" class="mx-1 ml-4 myBtn active sm">
                    CREATE ACCOUNT
                  </button>
                </div>
                <p
                  onClick={() => handleChangeLevel(0)}
                  style={{
                    cursor: "pointer",
                    justifySelf: "flex-end",
                    gridColumn: "1/3",
                  }}
                >
                  Already have an account? Log in
                </p>
              </form>
            </div>
          )}
          {level === 2 && (
            <div
              style={{
                width: "500px",
                height: "200px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <p style={{ textAlign: "center" }}>
                We appreciate your interest in the artist's resources, and we
                will contact you with the password details within the next 24
                hours.
              </p>
            </div>
          )}
          {level === 3 && (
            <div>
              <form
                className="popUpCreateAccount"
                onSubmit={handleForgotPassword}
              >
                <div style={{ gridColumn: "1/3" }}>
                  <p style={{ textAlign: "center" }}>
                    Type the email of your account, please verify that the email
                    is well written
                    <br />
                    For security if the email is not correct you won't recieve
                    anything.
                  </p>
                  <label htmlFor="pw_subCategory" className="labelsBio">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="Email"
                    value={emailForgotPassword}
                    name="emailForgotPassword"
                    className="textField"
                    style={{ marginBottom: "5px", width: "100%" }}
                    onChange={handleChangeForgotPassword}
                  />
                </div>
                {validation && <p className="error">{validation}</p>}

                <div style={{ gridColumn: "1/3", justifySelf: "center" }}>
                  {loadingGif ? (
                    <img className="mb-3" alt="loading" src={loading} />
                  ) : (
                    <button type="submit" class="mx-1 ml-4 myBtn active sm">
                      SEND EMAIL
                    </button>
                  )}
                </div>
                <p
                  onClick={() => handleChangeLevel(0)}
                  style={{
                    cursor: "pointer",
                    justifySelf: "flex-end",
                    gridColumn: "1/3",
                  }}
                >
                  Already have an account? Log in
                </p>
              </form>
            </div>
          )}
          {level === 4 && (
            <div
              style={{
                width: "500px",
                height: "200px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <p style={{ textAlign: "center" }}>
                Thank you, an email has been sent with the new account password.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PopUpLogin;
