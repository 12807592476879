import React, { useEffect, useState } from "react";
import ClientProfile from "./clientProfile";
import { getSingleClient } from "../../../AxiosFunctions/Axiosfunctionality";
import { useHistory } from "react-router-dom";
import loadingImg from "../../../assets/loading.gif";
import { useSelector } from "react-redux";

const ClientUser = ({ clientDetails, clientID }) => {
  const [searchedClientDetails, setSearchedClientDetails] = useState(null);
  const [haveEditAccess, setHaveEditAccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const { ClientDataAPI } = useSelector((state) => state);
  const history = useHistory();
  useEffect(() => {
    if (!clientID && !clientDetails) {
      history.replace("/client/signin");
    } else if (clientID) {
      // If clientID is there, check for clientDetails
      if (clientDetails) {
        // Set access based on ID match
        setHaveEditAccess(clientDetails._id === clientID);
      } else {
        setHaveEditAccess(false);
      }
    } else if (clientDetails && !clientID) {
      // If clientID is not there but clientDetails is
      history.replace(`/client/${clientDetails._id}?activeTab=favoriteArtists`);
    }
  }, [clientID, clientDetails]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the artist details
        const responseClient = await getSingleClient({ _id: clientID });
        if (
          responseClient === "NO CLIENT FOUND" ||
          responseClient === "ERROR"
        ) {
          setSearchedClientDetails(null);
        } else {
          setSearchedClientDetails(responseClient);
        }
      } catch (error) {
        setSearchedClientDetails(null);

        console.error("Error fetching artist details:", error);
      } finally {
        setLoading(false);
      }
    };
    if (clientID && clientDetails?._id !== clientID) {
      fetchData();
    } else if (
      ClientDataAPI?.clientData &&
      Object.keys(ClientDataAPI.clientData).length > 0
    ) {
      setSearchedClientDetails(ClientDataAPI.clientData);
      setLoading(false);
    }
  }, [clientDetails, ClientDataAPI?.clientData]);

  if (loading) {
    return (
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        <img
          className="mb-3"
          alt="loading"
          src={loadingImg}
          style={{ width: "50px" }}
        />
      </div>
    );
  }
  return (
    <div>
      {searchedClientDetails ? (
        <ClientProfile
          searchedClientDetails={searchedClientDetails}
          haveEditAccess={haveEditAccess}
        />
      ) : (
        <h1>No Client Found</h1>
      )}
    </div>
  );
};

export default ClientUser;
