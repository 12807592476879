import React, { useState } from "react";
import Header from "../../../components/layout2/header";
import MyPopup from "../../../components/myPopup/myPopup";
import "../../artist/artist.css";
import BackArrow from "../../../assets/svgs/backArrow.svg";
import { useHistory } from "react-router-dom";
import { changeArtistImageKeywords } from "../../../AxiosFunctions/Axiosfunctionality";
import { useEffect } from "react";
import {
  getCategory,
  getImageBaseURL,
} from "../../../AxiosFunctions/Axiosfunctionality";
import { useDispatch } from "react-redux";
import { updateMessage, updateOpen } from "../../../redux/message";
import ReactCrop from "react-image-crop";
import loading from "../../../assets/loading.gif";

const img1 = window.location.origin + "/assets/images/IMG3.png";

function Image_keywords(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isPopupShow, setIsPopupShow] = useState(false);
  const [artistImage, setArtistImage] = useState(false);
  const [artistkeyword, setArtistKeyword] = useState(null);
  const [temp, setTemp] = useState(null);
  const [maximumLimit, setMaximumLimit] = useState(0);

  const [keywordList, setKeywordList] = useState(null);
  const [keyword, setKeyword] = useState(null);
  const [keywordKids, setKeywordKids] = useState(null);
  const [keywordGraphicNovel, setKeywordGraphicNovel] = useState(null);
  const [keywordMedical, setKeywordMedical] = useState(null);

  const getBase64FromUrl = async (dataurl) => {
    try {
      if (dataurl) {
        let res = await getImageBaseURL({ url: dataurl });
        setTemp("data:image/jpeg;base64," + res.data);
      }
    } catch (error) {
      if (maximumLimit < 10) {
        setMaximumLimit(maximumLimit + 1);
      }
    }
  };

  const graphicNovelIds = [
    {
      name:"Cover",
      shannon:"66a116d289226cd86b4dcf29",
      kidShannon:"66a1172689226cd86b4dcf3f",
      graphical: "6560d8a657800f963330facf"
    },
    {
      name:"Sequential",
      shannon:"66a116e189226cd86b4dcf2c",
      kidShannon:"66a1172f89226cd86b4dcf42",
      graphical: "6560d8a657800f963330fad0"
    },
    {
      name:"Inking",
      shannon:"66a116ec89226cd86b4dcf2f",
      kidShannon:"66a1173889226cd86b4dcf45",
      graphical: "6560d8a657800f963330fad1"
    },
    {
      name:"Coloring",
      shannon:"66a116fc89226cd86b4dcf39",
      kidShannon:"66a1174389226cd86b4dcf48",
      graphical: "6560d8a657800f963330fad2"
    },
    {
      name:"Lettering",
      shannon:"66a1170989226cd86b4dcf3c",
      kidShannon:"66a1174c89226cd86b4dcf4b",
      graphical: "6560d8a657800f963330fad3"
    }
  ];

  useEffect(() => {
    try {
      getBase64FromUrl(props.images.originalPath);
      setArtistImage({
        imgPath: props.images.originalPath,
        title: props.images.title,
        _id: props.artistId._id,
      });

      if (keywordList == null) {
        let data = {};
        getCategory(data).then((res) => {
          setKeywordList(res);
        });
      }
      if (keywordList) {
        let keywordTemp = [];
        let keywordKidsTemp = [];
        let keywordGraphicNovelTemp = [];
        let keywordMedicalTemp = [];

        keywordList.map((val, ind) => {
          if (val.type === 1) {
            props.images.keywordID.map((val1, ind1) => {
              if (val1.type === 1) {
                if (val._id === val1._id) {
                  val.checked = true;
                }
              }
            });
            keywordTemp.push(val);
          } else if (val.type === 3) {
            props.images.keywordID.map((val1, ind1) => {
              if (val1.type === 3) {
                if (val._id === val1._id) {
                  val.checked = true;
                }
              }
            });
            keywordGraphicNovelTemp.push(val);
          } else if (val.type === 4) {
            props.images.keywordID.map((val1, ind1) => {
              if (val1.type === 4) {
                if (val._id === val1._id) {
                  val.checked = true;
                }
              }
            });
            keywordMedicalTemp.push(val);
          } else {
            props.images.keywordID.map((val1, ind1) => {
              if (val1.type === 2) {
                if (val._id === val1._id) {
                  val.checked = true;
                }
              }
            });
            keywordKidsTemp.push(val);
          }
          // Add a return statement if necessary
          return val; // or return something else if needed
        });

        setArtistKeyword(props.images.keywordID);
        setKeyword(keywordTemp);
        setKeywordKids(keywordKidsTemp);
        setKeywordGraphicNovel(keywordGraphicNovelTemp);
        setKeywordMedical(keywordMedicalTemp);
      }
    } catch (e) {
      history.push({
        pathname: "/admin/artists",
        state: { Nauman: 1 },
      });
    }
  }, [keywordList, maximumLimit]);

  const automaticCheckGraphic = (id,checked,graphicNovel)=>{
    
    if(graphicNovel)return;

    let idGraphic;
    
    for(let i=0;i<graphicNovelIds.length;i++){
      idGraphic = graphicNovelIds[i].shannon === id ? graphicNovelIds[i].graphical : undefined;
      if(idGraphic)
      break;
    }
    
    let keywordGraphicNovelTemp = [...keywordGraphicNovel];

      keywordGraphicNovelTemp.map((val1, ind) => {
        if (idGraphic == val1._id) {
          keywordGraphicNovelTemp[ind].checked = !checked;
        }
      });

      setKeywordGraphicNovel(keywordGraphicNovelTemp);
  }

  const keywordSetter = (
    val,
    checked = false,
    kids = false,
    graphicNovel = false,
    medical = false
  ) => {
    
    automaticCheckGraphic(val,checked,graphicNovel);

    if (kids) {
      let keywordKidsTemp = [...keywordKids];

      keywordKidsTemp.map((val1, ind) => {
        if (val == val1._id) {
          keywordKidsTemp[ind].checked = !checked;
        }
      });

      setKeywordKids(keywordKidsTemp);
    } else if (graphicNovel) {
      let keywordGraphicNovelTemp = [...keywordGraphicNovel];

      keywordGraphicNovelTemp.map((val1, ind) => {
        if (val == val1._id) {
          keywordGraphicNovelTemp[ind].checked = !checked;
        }
      });

      setKeywordGraphicNovel(keywordGraphicNovelTemp);
    } else if (medical) {
      let keywordMedicalTemp = [...keywordMedical];

      keywordMedicalTemp.map((val1, ind) => {
        if (val == val1._id) {
          keywordMedicalTemp[ind].checked = !checked;
        }
      });

      setKeywordMedical(keywordMedicalTemp);
    } else {
      let keywordTemp = [...keyword];

      keywordTemp.map((val1, ind) => {
        if (val == val1._id) {
          keywordTemp[ind].checked = !checked;
        }
      });

      setKeyword(keywordTemp);
    }
  };

  const onSubmitHandler = () => {
    let keywordList = [];

    let keywordKidsTemp = [];
    keywordKidsTemp = keywordKids.filter((val) => val.checked == true);

    let keywordTemp = [];
    keywordTemp = keyword.filter((val) => val.checked == true);

    let keywordGraphicNovelTemp = [];
    keywordGraphicNovelTemp = keywordGraphicNovel.filter(
      (val) => val.checked == true
    );
    let keywordMedicalTemp = [];
    keywordMedicalTemp = keywordMedical.filter((val) => val.checked == true);
    keywordList = keywordKidsTemp.concat(
      keywordTemp,
      keywordGraphicNovelTemp,
      keywordMedicalTemp
    );

    let artistImageTemp = {
      ...artistImage,
      keyword: keywordList,
      mainId: props.images._id,
    };
    let keywordListTemp = [];
    artistImageTemp.keyword.map((val) => {
      keywordListTemp.push(val._id);
    });

    const imageCreate = new FormData();
    imageCreate.append("k_id", keywordListTemp);
    imageCreate.append("mainId", props.images._id);
    imageCreate.append("adminPortfolio", true);
    imageCreate.append("_id", props.artistId._id);

    let data = {
      k_id: keywordListTemp,
      mainId: props.images._id,
      adminPortfolio: true,
      _id: props.artistId._id,
    };

    setIsPopupShow(true);
    changeArtistImageKeywords(data).then((res) => {
      if (res == "successfully updated") {
        dispatch(updateOpen(true));
        dispatch(updateMessage(res));
      } else {
        dispatch(updateOpen(true));
        dispatch(updateMessage("Error Occured"));
      }
      history.push("/admin/artists/" + props.images._id);
      setIsPopupShow(false);
    });
  };

  return (
    <>
      <div className=" mx-5 imageUploader">
        <div className="px-5 row m-0">
          <button className="btn1 mt-3 mb-5" onClick={() => history.goBack()}>
            CANCEL
          </button>
        </div>
        <div className="px-5 row m-0">
          <div
            className="col-xl-6 col-lg-6 my-5"
            style={{ position: "sticky", top: "2rem" }}
          >
            {artistImage !== null ? (
              <img
                alt="Crop me"
                src={temp}
                loading="lazy"
                role="presentation"
                decoding="async"
                fetchpriority={"high"}
                style={{ position: "sticky", top: "2rem" }}
              />
            ) : null}
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="row m-0">
              <h4 className="col-12 mb-5">
                SHANNONASSOCIATES.COM KEYWORD LISTING
              </h4>
              {keyword !== null &&
                keyword.map((val, ind) => (
                  <div
                    className="col-xl-4 col-lg-3 col-sm-12 col-md-6"
                    key={ind}
                  >
                    <label className="checkBox">
                      {val.keyword}
                      <input
                        type="checkbox"
                        value="checking 1"
                        defaultChecked={val.checked}
                        onClick={() => keywordSetter(val._id, val.checked)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                ))}
            </div>
            <div className="row mt-5">
              <h4 className="col-12 mb-5">KIDSHANNON.COM KEYWORD LISTING</h4>
              {keywordKids !== null &&
                keywordKids.map((val, ind) => (
                  <div
                    className="col-xl-4 col-lg-3 col-sm-12 col-md-6"
                    key={ind}
                  >
                    <label className="checkBox">
                      {val.keyword}

                      <input
                        type="checkbox"
                        value="checking 1"
                        defaultChecked={val.checked}
                        onClick={() =>
                          keywordSetter(val._id, val.checked, true)
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                ))}
            </div>
            <div className="row mt-5">
              <h4 className="col-12 mb-5">GRAPHIC NOVEL KEYWORD LISTING</h4>
              {keywordGraphicNovel !== null &&
                keywordGraphicNovel.map((val, ind) => (
                  <div
                    className="col-xl-4 col-lg-3 col-sm-12 col-md-6"
                    key={ind}
                  >
                    <label className="checkBox">
                      {val.keyword}

                      <input
                        type="checkbox"
                        value="checking 1"
                        defaultChecked={val.checked}
                        onClick={() =>
                          keywordSetter(val._id, val.checked, false, true)
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                ))}
            </div>
            <div className="row mt-5">
              <h4 className="col-12 mb-5">Medical</h4>
              {keywordMedical !== null &&
                keywordMedical.map((val, ind) => (
                  <div
                    className="col-xl-4 col-lg-3 col-sm-12 col-md-6"
                    key={ind}
                  >
                    <label className="checkBox">
                      {val.keyword}

                      <input
                        type="checkbox"
                        value="checking 1"
                        defaultChecked={val.checked}
                        onClick={() =>
                          keywordSetter(
                            val._id,
                            val.checked,
                            false,
                            false,
                            true
                          )
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                ))}
            </div>
            <div className="col-12 d-flex justify-content-end py-5">
              {isPopupShow ? (
                <img alt="loading" src={loading} style={{ marginTop: -20 }} />
              ) : (
                <button
                  className="btn1 dark px-4 align-self-bottom"
                  style={{ marginTop: -20 }}
                  onClick={() => onSubmitHandler()}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Image_keywords;
