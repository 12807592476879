import { useEffect, useState } from "react";
import {
  getLoggedInAgent,
  updateAgentData,
} from "../../../AxiosFunctions/Axiosfunctionality";
import loadingImg from "../../../assets/loading.gif";
import { useDispatch } from "react-redux";
import { updateMessage, updateOpen } from "../../../redux/message";
import { AgentDataAPI } from "../../../redux/agentSlice";
import { useHistory } from "react-router-dom";

const defaultFormFields = {
  firstName: "",
  lastName: "",
  // occupation: "",
  // company: "",
  email: "",
  password: "",
};

const AgentTabEdit = ({ agentDetails, haveEditAccess }) => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [loading, setLoading] = useState(true);
  const [loggedInAgentDetails, setLoggedInAgentDetails] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { firstname, lastname, occupation, company, email, password } =
    formFields;
  useEffect(() => {
    if (loggedInAgentDetails) {
      setFormFields({
        firstname: loggedInAgentDetails.firstname,
        lastname: loggedInAgentDetails.lastname,
        // occupation: loggedInAgentDetails.occupation,
        // company: loggedInAgentDetails.company,
        email: loggedInAgentDetails.email,
        password: loggedInAgentDetails.raw_password,
      });
    }
  }, [loggedInAgentDetails]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateAgentData({
        _id: agentDetails._id,
        firstname: firstname,
        lastname: lastname,
        // occupation: occupation,
        // company: company,
        status: 1,
        email: email,
        raw_password: password,
      });
      dispatch(updateOpen(true));
      dispatch(updateMessage("Successfully Updated"));
      dispatch(AgentDataAPI({ _id: agentDetails._id }));
      history.go(0); // Reloads the current page to reflect updated data
    } catch (error) {
      console.error("Failed to update agent data:", error);
      dispatch(updateOpen(true));
      dispatch(updateMessage("Update failed. Please try again."));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the artist details
        const responseAgent = await getLoggedInAgent({ _id: agentDetails._id });
        if (responseAgent === "NO AGENT FOUND" || responseAgent === "ERROR") {
          setLoggedInAgentDetails(null);
        } else {
          setLoggedInAgentDetails(responseAgent);
        }
      } catch (error) {
        setLoggedInAgentDetails(null);

        console.error("Error fetching artist details:", error);
      } finally {
        setLoading(false);
      }
    };
    if (agentDetails._id) {
      fetchData();
    }
  }, [agentDetails]);

  if (loading || !haveEditAccess) {
    return (
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        <img
          className="mb-3"
          alt="loading"
          src={loadingImg}
          style={{ width: "50px" }}
        />
      </div>
    );
  }
  return (
    <div className="mainEditContainer">
      <h1>Edit Profile</h1>
      <form className="editAgentForm" onSubmit={handleSubmit}>
        <label className="contactformlabel">
          {" "}
          First Name <span className="required">*</span>
        </label>
        <label className="contactformlabel">
          {" "}
          Last Name <span className="required">*</span>
        </label>
        <input
          type="text"
          className="text-field text_h w-100 w-input"
          value={firstname}
          maxLength="256"
          name="firstname"
          required
          onChange={handleChange}
        />
        <input
          type="text"
          className="text-field text_h w-100 w-input"
          value={lastname}
          maxLength="256"
          name="lastname"
          required
          onChange={handleChange}
        />
        {/* <label className="contactformlabel mt-3"> Occupation</label>
        <label className="contactformlabel mt-3"> Company</label>
        <input
          type="text"
          className="text-field text_h w-100 w-input"
          value={occupation}
          maxLength="256"
          name="occupation"
          required
          onChange={handleChange}
        />
        <input
          type="text"
          className="text-field text_h w-100 w-input"
          value={company}
          maxLength="256"
          name="company"
          required
          onChange={handleChange}
        /> */}
        <label className="contactformlabel mt-3">
          {" "}
          Email <span className="required">*</span>
        </label>
        <label className="contactformlabel mt-3">
          {" "}
          Password <span className="required">*</span>
        </label>
        <input
          type="text"
          className="text-field text_h w-100 w-input"
          value={email}
          maxLength="256"
          name="email"
          disabled
          required
          onChange={handleChange}
        />
        <input
          type="text"
          className="text-field text_h w-100 w-input"
          value={password}
          maxLength="256"
          name="password"
          required
          onChange={handleChange}
        />
        <button type="submit" class="mx-1 myBtn active sm mt-3 mb-3">
          SAVE
        </button>
      </form>
    </div>
  );
};

export default AgentTabEdit;
