import React from "react";
import Table, {
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from "../../../components/table/table";
import loading from "../../../assets/loading.gif";
function ClientCreationReq(props) {
  return (
    <div className="col">
      <Table height="calc(100vh - 350px)">
        <THead>
          <Th minWidth="120">Name</Th>
          <Th minWidth="120">Company</Th>
          <Th>Email</Th>

          <Th width="110"></Th>
        </THead>
        <TBody>
          {props.clientUsers.map((item, key) => (
            <>
              {item.status === 0 ? (
                <Tr key={key}>
                  <Td>{item.firstname + " " + item.lastname}</Td>
                  <Td>{item.company}</Td>
                  <Td>{item.email}</Td>

                  <Td className="d-flex">
                    <button
                      className="mx-1 myBtn"
                      type="text"
                      onClick={(e) => {
                        props.deleteClientUser(e, item._id);
                      }}
                    >
                      DELETE
                    </button>
                    {props.loading ? (
                      <img
                        className="mt-1"
                        alt="loading"
                        src={loading}
                        style={{ width: "30px" }}
                      />
                    ) : (
                      <button
                        className="mx-1 myBtn active"
                        type="text"
                        onClick={(e) => {
                          props.approveClientUser(e, item._id);
                        }}
                      >
                        {item.status === 1 ? "APPROVE" : "APPROVE"}
                      </button>
                    )}
                  </Td>
                </Tr>
              ) : (
                ""
              )}
            </>
          ))}
        </TBody>
      </Table>
    </div>
  );
}

export default ClientCreationReq;
