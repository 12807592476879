import { useEffect, useState } from "react";
import AddImage from "../../assets/svgs/addImage.svg";

const PropertiesArtist = ({ artistDetails, history, setCurrentProperty }) => {
  const handleProperty = (property) => {
    setCurrentProperty(property);
    history.push("/artist/properties_upload");
  };

  useEffect(() => {
    setCurrentProperty(null);
  }, []);
  return (
    <div className="px-1 px-md-5 artist mb-5">
      <h4> PROPERTIES </h4>
      <p>
        A Property is an original story or idea shown in sketches or "dummy
        book" form that we will try to sell to a Publisher or investor. Please
        only upload images and stories that you own copryright to, or that are
        in the public domain. Remember, this is going to be seen by editors and
        art directors who will need to get approve by a discerning acquisitions
        team (sales / marketing / editors / art directories / publishers) before
        the story can be acquire or an offer made. This is normally a long
        process, but the more work you put into your story, the better it will
        show as a workable and viable product.
      </p>
      <div className="_4cols-v2">
        <label
          className="artistcardAdmin w-inline-block addImageBtn"
          onClick={() => history.push("/artist/properties_upload")}
        >
          <img alt="" src={AddImage} className="addImage" />
        </label>
        {artistDetails?.properties
          ? [...artistDetails.properties].reverse().map((property, ind) => (
              <div
                className="artistcardAdmin w-inline-block"
                style={{ cursor: "pointer" }}
                key={property.id}
                onClick={() => handleProperty(property)}
              >
                <img
                  src={property.propertyMainImage}
                  alt={property.titleProperty}
                  className="image"
                />
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default PropertiesArtist;
