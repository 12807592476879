import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Mentorship = (props) => {
  const [videoModal, setVideoModal] = useState(false);
  const [windowSize] = useState(getWindowSize());
  const history = useHistory();

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  return (
    <div className="_2cols" style={{ clear: "both" }}>
      {props.children}
      <div>
        <div>
          {JSON.parse(localStorage.getItem("routePaths")) ? (
            <div
              className={"d-flex"}
              style={{ marginBottom: "10px", width: "98.4%" }}
            >
              {JSON.parse(localStorage.getItem("routePaths")).map(
                (obj, ind) => (
                  <p
                    key={ind}
                    className={
                      JSON.parse(localStorage.getItem("routePaths")).length ===
                      ind + 1
                        ? "breadCrumbs"
                        : "breadCrumbsActive"
                    }
                    onClick={
                      JSON.parse(localStorage.getItem("routePaths")).length ===
                      ind + 1
                        ? () => {}
                        : () => history.push(obj.link)
                    }
                    style={ind === 0 ? {} : { marginLeft: "5px" }}
                  >
                    {obj.val}{" "}
                    {JSON.parse(localStorage.getItem("routePaths")).length ===
                    ind + 1
                      ? null
                      : ">"}
                  </p>
                )
              )}
            </div>
          ) : null}
        </div>
        <h2 className="newsh2 h">MENTORSHIP</h2>
        <div
          id="w-node-a284be2a-4b91-3177-03eb-6614b24879c1-4bf2d022"
          className=" heightIssue"
        >
          <img
            style={{ width: "100%", cursor: "pointer" }}
            src="/assets/images/MentorshipImage.png"
            onClick={() => setVideoModal(true)}
          />
        </div>
      </div>
      <div>
        {videoModal && (
          <div className="modalTutorial">
            <div className="containerModal">
              <div className="modalCloseBtn">
                <button onClick={() => setVideoModal(false)}>×</button>
              </div>
              <div>
                <h2 className="newsh2 h" style={{ color: "#fa8e37" }}>
                  Shannon Associates Art Mentoring Program
                </h2>
                <p
                  style={{
                    fontSize: "1vw",
                    lineHeight: "1.4",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  Please send email to : lori@shannonassociates.com{" "}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Mentorship;
