import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../AxiosFunctions/Axiosfunctionality";

export const ClientDataAPI = createAsyncThunk(
  "client/ClientDataAPI",
  async (payload) => {
    return axios
      .post(BASE_URL + "clientUser/getSingleClient", payload)
      .then((response) => response.data);
  }
);

const initialState = {
  clientData: {},
  loading: false,
};

const ClientSlice = createSlice({
  name: "ClientData",
  initialState,
  reducers: {
    logoutClient: (state) => {
      // Reset the state to initial state or clear specific fields
      state.clientData = {};
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ClientDataAPI.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(ClientDataAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.clientData = action.payload;
    });
    builder.addCase(ClientDataAPI.rejected, (state, action) => {
      state.loading = false;
      state.clientData = action.payload;
    });
  },
});
export const { logoutClient } = ClientSlice.actions;
export default ClientSlice.reducer;
