import React, { useState, useEffect } from "react";
import {
  addClientFavoriteArtist,
  removeClientFavoriteArtist,
} from "../../../AxiosFunctions/Axiosfunctionality";
import { useDispatch } from "react-redux";
import { updateMessage, updateOpen } from "../../../redux/message";

const ClientFavoriteButton = ({ clientData, artistID, windowSize }) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Check if the artistID is in the client's favorites
    setIsFavorite(clientData?.favoriteArtists?.includes(artistID));
  }, [clientData, artistID]);

  const toggleFavorite = async () => {
    if (isFavorite) {
      // Call API to remove from favorites
      try {
        await removeClientFavoriteArtist({
          _id: clientData._id,
          artistId: artistID,
        });

        dispatch(updateOpen(true));
        dispatch(updateMessage("Removed from favorites"));
        setIsFavorite(false);
      } catch (error) {
        console.error("Error removing from favorites:", error);
      }
    } else {
      // Call API to add to favorites
      try {
        await addClientFavoriteArtist({
          _id: clientData._id,
          artistId: artistID,
        });
        dispatch(updateOpen(true));
        dispatch(updateMessage("Added to favorites"));
        setIsFavorite(true);
      } catch (error) {
        console.error("Error adding to favorites:", error);
      }
    }
  };
  const buttonStyle = {
    width: "100%",
    marginTop: "10px",
    backgroundColor: isFavorite ? "#bc6127" : undefined, // Only change when favorite
    color: isFavorite ? "#ffffff" : undefined, // Only change text color when favorite
  };
  if (!clientData || !clientData._id) {
    return null;
  }
  return (
    <div>
      <button
        className={`${
          windowSize?.innerWidth < 479
            ? "allArtistsButtons mb-4"
            : "talentbutton"
        }   `}
        style={buttonStyle} // Ensures full width and margin from the above links
        onClick={toggleFavorite}
      >
        {isFavorite ? "REMOVE FROM FAVORITES" : "ADD TO MY FAVORITE ARTISTS"}
      </button>
    </div>
  );
};

export default ClientFavoriteButton;
