import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getBipocBlack } from "../../AxiosFunctions/Axiosfunctionality";
import { artistDivision } from "../../redux/artistImageDivisionDataSlice";

function Divisions(props) {
  const history = useHistory();
  const [, setTempArtist] = useState([]);
  const [, setFilterCond] = useState(true);
  const [filterHighlighted, setFilterHighlighted] = useState(null);
  const [windowSize] = useState(getWindowSize());
  const [blackArtist, setBlackArtist] = useState(null);
  const divisionImages = [
    "DivisionsIllustration.png",
    "DivisionsGraphicNovel.png",
    "DivisionsPhotography.png",
    "DivisionsMedical.png",
    "DivisionsMotion.jpg",
  ];
  const dispatch = useDispatch();
  const { artistImageDivisionDataSlice } = useSelector((state) => state);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const getPath = (division) => {
    switch (division) {
      case "Illustration":
        return "/illustration-artists";
      case "3D Rendering":
        return "/motion";
      case "Photography":
        return "/photography";
      case "Medical":
        return "/medical";
      case "Graphic Novel":
        return "/graphicNovel";
      case "Bipoc":
        return "/bipoc";
      default:
        return "";
    }
  };

  const filterChange = (filter) => {
    if (filter === "A-Z") {
      let temp = [];
      setFilterCond(false);
      artistImageDivisionDataSlice.artistKeywordImages.map((val, ind) => {
        let tempImage = [...val.ImageData];
        tempImage = tempImage.sort((a, b) => {
          if (
            a.artistId.lastname
              .normalize()
              .localeCompare(b.artistId.lastname.normalize()) === 0
          ) {
            return a.artistId.firstname
              .normalize()
              .localeCompare(b.artistId.firstname.normalize());
          } else {
            return a.artistId.lastname
              .normalize()
              .localeCompare(b.artistId.lastname.normalize());
          }
        });
        temp.push({ ...val, ImageData: tempImage });
      });
      setFilterHighlighted(2);
      setTempArtist(temp);
    } else {
      setFilterHighlighted(1);
      setFilterCond(true);
    }
  };

  const updateTempArtist = (e) => {
    if (artistImageDivisionDataSlice.artistKeywordImages.length) {
      const searchvalue = e.toLowerCase();
      let temp = [];

      artistImageDivisionDataSlice.artistKeywordImages.map((val, ind) => {
        let tempImage = val.ImageData.filter(function (element) {
          let checker = false;
          if (
            element.artistId.firstname.toLowerCase().includes(searchvalue) ||
            element.artistId.lastname.toLowerCase().includes(searchvalue)
          ) {
            checker = true;
          }
          return checker;
        });
        temp.push({ ...val, ImageData: tempImage });
      });
      setFilterHighlighted(null);
      setTempArtist(temp);
    }
  };

  useEffect(() => {
    updateTempArtist(props.searchArtist);
  }, [artistImageDivisionDataSlice, props.searchArtist]);

  useEffect(() => {
    dispatch(artistDivision({}));

    getBipocBlack().then((res) => {
      let orderedArtist = res?.sort((a, b) => {
        if (
          a.artistData.lastname
            .normalize()
            .localeCompare(b.artistData.lastname.normalize()) === 0
        ) {
          return a.artistData.firstname
            .normalize()
            .localeCompare(b.artistData.firstname.normalize());
        } else {
          return a.artistData.lastname
            .normalize()
            .localeCompare(b.artistData.lastname.normalize());
        }
      });
      setBlackArtist({
        id: orderedArtist[0].artistData._id,
        keyword: "Bipoc",
        type: 1,
        ImageData: orderedArtist[0].ImageData,
      });
    });
  }, [dispatch]);

  return (
    <>
      <div class="sortingcont right pt-0  me-0 ">
        <div className="d-flex  ">
          <button
            class={
              filterHighlighted === 1
                ? "filter-button sort-active w-inline-block  mt-0"
                : filterHighlighted === 2
                ? "filter-button w-inline-block  mt-0"
                : "filter-button sort-active w-inline-block  mt-0"
            }
            style={{ marginLeft: "0px" }}
            onClick={() => filterChange("Default")}
          >
            <div>FEATURED</div>
          </button>
          <button
            class={
              filterHighlighted === 2
                ? "filter-button sort-active mt-0 me-0"
                : "filter-button mt-0 me-0"
            }
            onClick={() => filterChange("A-Z")}
          >
            <div>ALPHABETICAL A-Z</div>
          </button>
        </div>
      </div>
      <div className="_2cols2_">
        {props.children}
        <div
          id="w-node-_6f42e407-456f-5b2f-82e4-417072db3669-84f2d081"
          className="divisionscolumn"
        >
          <div>
            {JSON.parse(localStorage.getItem("routePaths")) ? (
              <div
                className={"d-flex"}
                style={{ marginBottom: "10px", width: "98.4%" }}
              >
                {JSON.parse(localStorage.getItem("routePaths")).map(
                  (obj, ind) => (
                    <p
                      className={
                        JSON.parse(localStorage.getItem("routePaths"))
                          .length ===
                        ind + 1
                          ? "breadCrumbs"
                          : "breadCrumbsActive"
                      }
                      onClick={
                        JSON.parse(localStorage.getItem("routePaths"))
                          .length ===
                        ind + 1
                          ? () => {}
                          : () => history.push(obj.link)
                      }
                      style={ind === 0 ? {} : { marginLeft: "5px" }}
                    >
                      {obj.val}{" "}
                      {JSON.parse(localStorage.getItem("routePaths")).length ===
                      ind + 1
                        ? null
                        : ">"}
                    </p>
                  )
                )}
              </div>
            ) : null}
          </div>
          <div className="divisionCont">
            {artistImageDivisionDataSlice.artistKeywordImages.map(
              (item, key) => {
                if (key === 0) {
                  return blackArtist ? (
                    <>
                      <Link
                        id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                        data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                        to={getPath(item.keyword)}
                        className="divisionCard"
                      >
                        <img
                          src={"/assets/images/" + divisionImages[key]}
                          loading="lazy"
                          alt=""
                          className="image"
                        />
                        <div className="divisonNameDivCont">
                          <div className="divisionNameText -v3">
                            {item.keyword === "3D Rendering"
                              ? "Motion"
                              : item.keyword}
                          </div>
                        </div>
                      </Link>
                      <Link
                        id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                        data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                        to={getPath(blackArtist.keyword)}
                        className="divisionCard"
                      >
                        <img
                          src="/assets/images/DivisionsBipoc.png"
                          loading="lazy"
                          alt=""
                          className="image"
                        />
                        <div className="divisonNameDivCont">
                          <div className="divisionNameText -v3">
                            {blackArtist.keyword === "3D Rendering"
                              ? "Motion"
                              : blackArtist.keyword}
                          </div>
                        </div>
                      </Link>
                    </>
                  ) : null;
                } else if (key === 1) {
                  return (
                    <>
                      <Link
                        id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                        data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                        to={getPath(item.keyword)}
                        className="divisionCard"
                      >
                        <img
                          src={"/assets/images/" + divisionImages[key]}
                          loading="lazy"
                          alt=""
                          className="image"
                        />
                        <div className="divisonNameDivCont">
                          <div className="divisionNameText -v3">
                            {item.keyword === "3D Rendering"
                              ? "Motion"
                              : item.keyword}
                          </div>
                        </div>
                      </Link>
                      <a
                        id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                        data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                        href="https://kidshannon.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="divisionCard"
                      >
                        <img
                          src="/assets/images/DivisionsKidShannon.png"
                          loading="lazy"
                          alt=""
                          className="image"
                        />
                        <div className="divisonNameDivCont">
                          <div className="divisionNameText -v3">KIDSHANNON</div>
                        </div>
                      </a>
                    </>
                  );
                } else {
                  return (
                    <Link
                      id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                      data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                      to={getPath(item.keyword)}
                      className="divisionCard"
                    >
                      <img
                        src={"/assets/images/" + divisionImages[key]}
                        loading="lazy"
                        alt=""
                        className="image"
                      />
                      <div className="divisonNameDivCont">
                        <div className="divisionNameText -v3">
                          {item.keyword === "3D Rendering"
                            ? "Motion"
                            : item.keyword}
                        </div>
                      </div>
                    </Link>
                  );
                }
              }
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Divisions;
