import AddImage from "../../assets/svgs/addImage.svg";
const PersonalWorks = ({history,artistDetails,setCurrentPersonalWork}) => {

  const handlePW = (personalWork) => {
    setCurrentPersonalWork(personalWork);
    history.push("/artist/personal_work_upload");
  };
  
  const handleNwePW = ()=>{
    setCurrentPersonalWork(null);
    history.push("/artist/personal_work_upload");
  }

  return (
    <div className="px-1 px-md-5 artist mb-5">
      <h4> PERSONAL WORKS </h4>
      <p>
      Consider uploading images to this section that shows your range as an artist and creator.<br/>
      These selections may be quite different from what is currently being shown in your full portfolio.<br/>
      Think of visuals you’ve done that inspire you, great sketchbook pages, a new style you are experimenting with. <br/>
      Clients have told us they love to search through social media and sketchbooks and see how an artist thinks.<br/>
      These could be used as “clusters” of ideas that you can label and show a different side of your creativity.<br/><br/>
      Keep in mind that all things presented should still maintain a level of professional quality.
      </p>
      <div className="_4cols-v2">
        <label
          className="artistcardAdmin w-inline-block addImageBtn"
          onClick={handleNwePW}
        >
          <img alt="" src={AddImage} className="addImage" />
        </label>
        {artistDetails?.personalWorks
          ? [...artistDetails.personalWorks]
              .reverse()
              .map((personalWork, ind) => (
                <div
                  className="artistcardAdmin w-inline-block"
                  style={{ cursor: "pointer" }}
                  key={personalWork.personalWorkId}
                  onClick={() => handlePW(personalWork)}
                >
                  <img
                    src={personalWork.personalWorkMainImage}
                    alt={personalWork.personalWorkTitle}
                    className="image"
                  />
                </div>
              ))
          : null}
      </div>
    </div>
  )
}

export default PersonalWorks