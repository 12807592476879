import React, { useState } from "react";

import "./admin.css";
import ImagesForReview from "./dashboardPages/imagesForReview";
import AddNewArtist from "./dashboardPages/addNewArtist";
import AccountCreationReq from "./dashboardPages/accountCreationReq";
import Input from "../../components/input/input";
import loading from "../../assets/loading_trasnparent.gif";

import ArtistUpdates from "./dashboardPages/artistUpdates";
import ClientCreationReq from "./dashboardPages/clientCreationReq";
import AgentCreationReq from "./dashboardPages/agentCreationReq";
import AddNewAgent from "./dashboardPages/addNewAgent";

function Dashboard(props) {
  const [search, setSearch] = useState("");

  return (
    <div className="px-xl-5 mx-xl-5">
      <div className="px-lg-3 py-4 mt-3 d-flex justify-content-between">
        {/* <div className='mx-lg-5 px-lg-3 py-4 mt-3 ml-5 d-flex justify-content-between'> */}
        <h4>
          {props.formNo === 2
            ? "IMAGES FOR REVIEW"
            : props.formNo === 3
            ? "ADD A NEW ARTIST"
            : props.formNo === 4
            ? "ACCOUNT CREATION REQUESTS"
            : props.formNo === 5
            ? "ARTIST PROFILE UPDATES"
            : props.formNo === 6
            ? "CLIENT CREATION REQUESTS"
            : props.formNo === 7
            ? "ADD MYSHANNON USER"
            : "DASHBOARD"}
        </h4>
        {props.formNo === 2 || props.formNo === 4 ? (
          <Input
            type="search"
            label="Search"
            value={search}
            onChange={(e) => console.log(e.target.value)}
            onClick={(e) => console.log(e)}
          />
        ) : null}
      </div>
      <div
        className={
          "row" +
          (props.formNo === 0 || props.formNo === 3 || props.formNo === 5
            ? " p-lg-3"
            : "")
        }
      >
        {/* <div className={'row'+(props.formNo === 0 || props.formNo === 3?" mx-lg-4 p-lg-5":"")}> */}
        {props.formNo === 2 ? (
          <ImagesForReview
            holder={props.holder}
            artistImages={props.artistImages}
            populateArtistImages={props.populateArtistImages}
            populateArtistUsers={props.populateArtistUsers}
          />
        ) : props.formNo === 3 ? (
          <AddNewArtist
            holder={props.holder}
            addArtistUser={props.addArtistUser}
          />
        ) : props.formNo === 4 ? (
          <AccountCreationReq
            artistUsers={props.artistUsers}
            deleteArtistUser={props.deleteArtistUser}
            approveArtistUser={props.approveArtistUser}
            loading={props.loading}
          />
        ) : props.formNo === 5 ? (
          <ArtistUpdates
            artistUpdates={props.artistUpdates}
            deleteArtistUpdate={props.deleteArtistUpdate}
            approveArtistUpdate={props.approveArtistUpdate}
            loading={props.loading}
            populateArtistUpdates={props.populateArtistUpdates}
          />
        ) : props.formNo === 6 ? (
          <ClientCreationReq
            clientUsers={props.clientUsers}
            deleteClientUser={props.deleteClientUser}
            approveClientUser={props.approveClientUser}
            loading={props.loading}
          />
        ) : props.formNo === 7 ? (
          // <AgentCreationReq
          //   agentUsers={props.agentUsers}
          //   deleteAgentUser={props.deleteAgentUser}
          //   approveAgentUser={props.approveAgentUser}
          //   loading={props.loading}
          // />
          <AddNewAgent
            holder={props.holder}
            addAgentUser={props.addAgentUser}
          />
        ) : (
          <>
            <div className="col-md-6 mb-3">
              <div
                onClick={() => props.history.push("/admin/contact")}
                className="dash_card color1 p-5"
              >
                <h5>
                  CONTACT <br />
                  FORM <br />
                  SUBMISSIONS
                </h5>
                <h1>{props.contactCount}</h1>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div
                onClick={
                  props.reviewImagesCount !== "loading"
                    ? () => props.setFormNo(2)
                    : null
                }
                className="dash_card color2 p-5"
              >
                <h5>
                  IMAGES FOR <br />
                  REVIEW
                </h5>
                {props.reviewImagesCount == "loading" ? (
                  <img alt="loading" src={loading} style={{ width: "50px" }} />
                ) : (
                  <h1>{props.reviewImagesCount}</h1>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div
                onClick={() => props.setFormNo(3)}
                className="dash_card color3 p-5 justify-content-center"
              >
                <h5>ADD A NEW ARTIST.</h5>
              </div>
            </div>
            {/* <div className="col-md-6 mb-3">
              <div
                onClick={() => props.setFormNo(4)}
                className="dash_card color4 p-5"
              >
                <h5>
                  ACCOUNT <br />
                  CREATION <br />
                  REQUESTS
                </h5>
                <h1>{props.reviewArtistCount}</h1>
              </div>
            </div> */}
            <div className="col-md-6 mb-3">
              <div
                onClick={() => props.setFormNo(5)}
                className="dash_card color4 p-5"
              >
                <h5>
                  ARTIST <br />
                  PROFILE <br />
                  UPDATES
                </h5>
                <h1>{props.artistUpdates?.length}</h1>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div
                onClick={() => props.setFormNo(6)}
                className="dash_card color4 p-5"
              >
                <h5>
                  PROPERTIES <br />
                  ACCESS <br />
                  REQUESTS
                </h5>
                <h1>{props.reviewClientCount}</h1>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div
                onClick={() => props.setFormNo(7)}
                className="dash_card color4 p-5"
              >
                <h5>ADD A NEW MYSHANNON USER</h5>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
